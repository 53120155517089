import { ValidationErrors, getOtherErrors, validateEmail } from 'common/utils/validation';
import { AppLayout } from 'components/AppLayout/AppLayout';
import { useMessage } from 'components/Message/MessageProvider';
import { Field } from 'modules/form/field/field';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Form } from '../../../components/Form/Form';
import { Email, Name, Username } from '../../../components/Input';
import { Language } from '../../../components/Input/SelectFields';
import { Api } from '../../../modules/api/api';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { UserListPageCrumb } from '../UserListPage/UserListPage';

export const UserCreatePageCrumb: Breadcrumb = {
  page: 'Administration.UserCreatePage',
  icon: 'user-plus',
  location: '/admin/users/new',
};

export const UserCreatePage: React.FC = (): ReactElement => {
  const message = useMessage();
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [t] = useTranslation();
  const [preferredLanguage, setPreferredLanguage] = useState('en');
  const [error, setError] = useState<ValidationErrors | undefined>();
  const [active] = useState(true);
  const history = useHistory();

  function userConflictErrorHandler(errorMessage: string): void {
    if (errorMessage === 'A user with the provided email address already exists') {
      return setError({
        errors: [{ field: 'email', codes: ['Unique.email'] }, ...(error?.errors || [])],
      });
    }
    if (errorMessage === 'A user with the provided username already exists') {
      return setError({
        errors: [{ field: 'username', codes: ['Unique.username'], ...(error?.errors || []) }],
      });
    }
  }

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, UserListPageCrumb, UserCreatePageCrumb]}>
      <Box i18nKey={UserCreatePageCrumb.page}>
        <Form
          i18nKey="Administration.UserCreatePage"
          cancelTo="/admin/users"
          disabled={
            Field.isEmpty(username) ||
            Field.isEmpty(name) ||
            Field.isEmpty(email) ||
            Field.isEmpty(preferredLanguage) ||
            Boolean(error)
          }
          error={error}
          onSubmit={async (): Promise<void> => {
            try {
              setError(undefined);
              await Api.locHub.users.create({ active, email, name, username, preferredLanguage });
              history.push('/admin/users');
            } catch (e) {
              if (e.code === 409) {
                userConflictErrorHandler(e.body.message);
              } else {
                message.error(t('errors.unknown'));
              }
            }
          }}
        >
          <Username
            i18nKey="Administration.UserCreatePage.usernameField"
            value={username}
            onChange={setUsername}
            validate={(event: ChangeEvent<HTMLInputElement>): void => {
              if (!/^[-_.a-z0-9]+$/.test(event.target.value)) {
                setError({
                  errors: [{ field: 'username', codes: ['Invalid.username'] }, ...(error?.errors || [])],
                });
              } else {
                setError(getOtherErrors('username', error));
              }
            }}
            errors={error}
            required
          />
          <Name
            i18nKey="Administration.UserCreatePage.nameField"
            value={name}
            onChange={setName}
            validate={(): void => {
              setError(getOtherErrors('name', error));
            }}
            errors={error}
            required
          />
          <Email
            i18nKey="Administration.UserCreatePage.emailField"
            value={email}
            onChange={setEmail}
            validate={(event: ChangeEvent<HTMLInputElement>): void => {
              validateEmail(event, error, setError);
            }}
            errors={error}
            required
          />
          <Language
            i18nKey="Administration.UserCreatePage.languageField"
            value={preferredLanguage}
            onChange={setPreferredLanguage}
            errors={error}
          />
        </Form>
      </Box>
    </AppLayout>
  );
};
