import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const SourceLanguageFilterSelectI18n = {
  configuration: { namespace: I18nNamespace.SOURCE_LANGUAGE_FILTER_SELECT, labelKey: 'label' },
  translations: {
    label: dictionary.sourceLanguagesFilter,
    placeholder: dictionary.sourceLanguagesFilter,
    noOptions: dictionary.noOptions,
    filterPlaceholder: dictionary.filter,
    selectAll: dictionary.selectAll,
    deselectAll: dictionary.deselectAll,
    save: dictionary.save,
  },
};
