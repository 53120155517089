import { ValidationErrors, validateLanguageCode } from 'common/utils/validation';
import { LanguageCodeConflict } from 'modules/api/locHub/languages/error/conflict/languageCode/languageCode';
import { Field } from 'modules/form/field/field';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useHistory } from 'react-router';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Form } from '../../../components/Form/Form';
import { Code, Description, Name } from '../../../components/Input';
import { Api } from '../../../modules/api/api';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { LanguageListPageCrumb } from '../LanguageListPage/LanguageListPage';

export const LanguageCreatePageCrumb: Breadcrumb = {
  page: 'Administration.LanguageCreatePage',
  icon: 'plus',
  location: '/admin/languages/new',
};

export const LanguageCreatePage: React.FC = (): ReactElement => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState<ValidationErrors | undefined>();
  const [code, setCode] = useState('');
  const history = useHistory();

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, LanguageListPageCrumb, LanguageCreatePageCrumb]}>
      <Box i18nKey={LanguageCreatePageCrumb.page}>
        <Form
          i18nKey="Administration.LanguageCreatePage"
          cancelTo="/admin/languages"
          disabled={Field.isEmpty(name) || Field.isEmpty(code) || Boolean(error)}
          error={error}
          onSubmit={async (): Promise<void> => {
            try {
              setError(undefined);
              await Api.locHub.languages.create({
                name,
                description,
                code,
              });
              history.push('/admin/languages');
            } catch (e) {
              if (e instanceof LanguageCodeConflict) {
                setError({
                  errors: [{ field: 'code', codes: ['Unique.languageCode'] }],
                });
              } else {
                setError(e);
              }
            }
          }}
        >
          <Name
            value={name}
            onChange={setName}
            errors={error}
            i18nKey="Administration.LanguageCreatePage.nameField"
            required
          />
          <Description
            value={description}
            onChange={setDescription}
            i18nKey="Administration.LanguageCreatePage.descriptionField"
          />
          <Code
            value={code}
            onChange={setCode}
            validate={(event: ChangeEvent<HTMLInputElement>): void => {
              validateLanguageCode(event, error, setError);
            }}
            errors={error}
            i18nKey="Administration.LanguageCreatePage.codeField"
            required
          />
        </Form>
      </Box>
    </AppLayout>
  );
};
