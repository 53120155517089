import React, { Dispatch, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../../../common/interfaces/common';
import { Translatable } from '../../../common/language';
import { classNames } from '../../../common/utils/classNames';
import { HtmlProps } from '../../../common/utils/HTMLProps';
import { Pagination } from '../../../components/Pagination/Pagination';

export interface Props extends Translatable, HtmlProps {
  columns: string[];
  pageInfo: Page<unknown>;
  setPage: Dispatch<number>;
}

export const DataTable: React.FC<Props> = ({
  i18nKey,
  className,
  id,
  children,
  columns,
  pageInfo,
  setPage,
}): ReactElement => {
  const [t] = useTranslation();

  return (
    <>
      <div className="is-clearfix" />
      <div className={classNames(className, 'table-container')} id={id}>
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={column === '' ? index : column}>{t([`${i18nKey}.${column}`, column])}</th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
        {pageInfo.page && pageInfo.totalElements > pageInfo.pageSize && (
          <Pagination
            onChangePage={setPage}
            currentPage={pageInfo.page}
            lastPage={pageInfo ? Math.ceil(pageInfo.totalElements / pageInfo.pageSize) : 0}
          />
        )}
      </div>
    </>
  );
};
