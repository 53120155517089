import { XillioProvider } from 'common/interfaces/xillio';

export enum XillioProviderType {
  ACQUIA = 'Acquia',
  AEM = 'Aem',
  BITBUCKET = 'Bitbucket',
  CONTENTFUL = 'Contentful',
  DROPBOX = 'Dropbox',
  DRUPAL = 'Drupal',
  GITLAB = 'Gitlab',
  GOOGLE_DRIVE = 'Google-Drive',
  SITECORE = 'Sitecore',
  SHAREPOINT = 'SharePoint',
}

export class XillioComplimentaryPartnerProviders {
  private static complimentaryPartner = new Map<XillioProviderType, XillioProvider>([
    [
      XillioProviderType.ACQUIA,
      {
        configurationType: XillioProviderType.ACQUIA,
      },
    ],
    [
      XillioProviderType.AEM,
      {
        configurationType: XillioProviderType.AEM,
      },
    ],
    [
      XillioProviderType.BITBUCKET,
      {
        configurationType: XillioProviderType.BITBUCKET,
      },
    ],
    [
      XillioProviderType.CONTENTFUL,
      {
        configurationType: XillioProviderType.CONTENTFUL,
      },
    ],
    [
      XillioProviderType.DROPBOX,
      {
        configurationType: XillioProviderType.DROPBOX,
      },
    ],
    [
      XillioProviderType.DRUPAL,
      {
        configurationType: XillioProviderType.DRUPAL,
      },
    ],
    [
      XillioProviderType.GITLAB,
      {
        configurationType: XillioProviderType.GITLAB,
      },
    ],
    [
      XillioProviderType.GOOGLE_DRIVE,
      {
        configurationType: XillioProviderType.GOOGLE_DRIVE,
      },
    ],
    [
      XillioProviderType.SHAREPOINT,
      {
        configurationType: XillioProviderType.SHAREPOINT,
      },
    ],
    [
      XillioProviderType.SITECORE,
      {
        configurationType: XillioProviderType.SITECORE,
      },
    ],
  ]);

  public static getAll(): XillioProvider[] {
    return Array.from(XillioComplimentaryPartnerProviders.complimentaryPartner.values());
  }
}
