import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { QueryBuilder } from '../entity/query/builder/builder';
import { EntityRelation } from '../entity/relation/relation';
import { LocHub } from '../lochub';
import { TaskId } from '../tasks/task/id/id';
import { DeliverableDto, NewDeliverable } from './deliverable/deliverable';
import { DeliverableId } from './deliverable/id/id';

export class Deliverables {
  public static readonly path: string = '/api/internal/deliverables';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Deliverables.path, parameters);
  }

  private static getDetailUrl(id: DeliverableId): URL {
    return Api.getUrl(`${Deliverables.path}/${id}`);
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<DeliverableDto>> {
    return LocHub.getJson(Deliverables.getBaseUrl({ page, ...options }));
  }

  public static async getAll(): Promise<DeliverableDto[]> {
    return LocHub.getAll(Deliverables.path);
  }

  public static async getAllByTask(taskId: TaskId): Promise<DeliverableDto[]> {
    return Api.locHub.getAll(Deliverables.path, {
      query: new QueryBuilder().addRelation(EntityRelation.TASK, taskId).build(),
    });
  }

  public static async getDto(deliverableId: DeliverableId): Promise<DeliverableDto> {
    return LocHub.getJson(Deliverables.getDetailUrl(deliverableId));
  }

  public static async create(deliverable: NewDeliverable): Promise<DeliverableDto> {
    const result = await LocHub.postJson<NewDeliverable, DeliverableDto>(Deliverables.getBaseUrl(), deliverable);
    queryCache.invalidateQueries(Deliverables.path);
    return result;
  }

  public static async update(
    deliverableId: DeliverableId,
    deliverable: Partial<DeliverableDto>,
  ): Promise<DeliverableDto> {
    const result = await LocHub.patchJson(Deliverables.getDetailUrl(deliverableId), deliverable);
    queryCache.invalidateQueries(Deliverables.path);
    return result;
  }

  public static async deliverMany(deliverableIds: DeliverableId[]): Promise<{ errors: Record<string, unknown> }> {
    const result = await LocHub.postJson<{ ids: DeliverableId[] }, { errors: Record<string, unknown> }>(
      Api.getUrl(`api/internal/deliverables/bulk/sendToCMS`),
      {
        ids: deliverableIds,
      },
    );
    queryCache.invalidateQueries(Deliverables.path);
    return result;
  }

  public static async delete(deliverable: DeliverableId): Promise<void> {
    await LocHub.delete(Deliverables.getDetailUrl(deliverable));
    queryCache.invalidateQueries(Deliverables.path);
  }
}
