import React, { ReactElement } from 'react';

import { Option, OptionActions } from './Option/Option';
import { SelectOption, matchesFilter } from './Option/optionUtils';

export type OptionsActions = OptionActions;

export interface OptionsTexts {
  noOptions: string;
}

interface Props {
  readonly texts: OptionsTexts;
  readonly options: Map<string, SelectOption>;
  readonly selected: Set<string>;
  readonly filterBy: string;
  readonly actions: OptionActions;
}

interface SelectableOption extends SelectOption {
  readonly selected: boolean;
}

export const Options: React.FC<Props> = ({ texts, options, selected, filterBy, actions }): ReactElement => {
  const visibleOptions: SelectableOption[] = [];
  for (const option of options.values()) {
    const match: boolean = matchesFilter(option.label, filterBy);
    if (!match) {
      continue;
    }
    const isSelected: boolean = selected.has(option.id);
    visibleOptions.push({
      ...option,
      selected: isSelected,
    });
  }
  return (
    <React.Fragment>
      {visibleOptions.length ? (
        visibleOptions.map((option: SelectableOption) => {
          return (
            <Option key={option.id} id={option.id} label={option.label} selected={option.selected} actions={actions} />
          );
        })
      ) : (
        <p key="no-options" className="dropdown-item">
          {texts.noOptions}
        </p>
      )}
    </React.Fragment>
  );
};
