import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumbs';
import { Columns } from 'components/Columns/Columns';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AutomationListPageCrumb } from '../AutomationListPage/AutomationListPage';
import { ConnectorsPageCrumb } from '../ConnectorsPage/ConnectorsPage';
import { LanguageListPageCrumb } from '../LanguageListPage/LanguageListPage';
import { ProvidersPageCrumb } from '../ProvidersPage/ProvidersPage';
import { UserListPageCrumb } from '../UserListPage/UserListPage';

export const AdminPageCrumb: Breadcrumb = {
  page: 'Administration.AdminPage',
  icon: 'cogs',
  location: '/admin',
};

export interface Props {
  id?: string;
}

export const AdminTile: React.FC<Props & { page: Breadcrumb }> = ({ page, id }): ReactElement => {
  const [t] = useTranslation();
  return (
    <Link to={page.location} className="tile is-child box has-text-centered admin-section-card" id={id}>
      <i className={`fas fa-4x fa-${page.icon}`} />
      <h3 className="title is-4 is-spaced">{t(`${page.page}.title`)}</h3>
      <p className="subtitle is-6 has-text-weight-semibold has-text-grey">{t(`${page.page}.description`)}</p>
    </Link>
  );
};

export const AdminPage: React.FC = (): ReactElement => (
  <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb]}>
    <Columns columnCount={2}>
      <AdminTile page={UserListPageCrumb} id="user-button" />
      <AdminTile page={LanguageListPageCrumb} id="language-button" />
      <AdminTile page={ConnectorsPageCrumb} id="connectors-button" />
      <AdminTile page={AutomationListPageCrumb} id="automation-button" />
      <AdminTile page={ProvidersPageCrumb} id="providers-button" />
    </Columns>
  </AppLayout>
);
