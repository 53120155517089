import { classNames } from 'common/utils/classNames';
import { Colors } from 'modules/ui/colors/colors';
import React, { ReactElement } from 'react';

interface Props {
  color: Colors;
}

export const CustomStatus: React.FC<Props> = ({ color, children }): ReactElement => {
  return (
    <React.Fragment>
      <i className={classNames('fa fa-circle', `has-text-${color}`)} style={{ marginRight: '8px' }} />
      {children}
    </React.Fragment>
  );
};
