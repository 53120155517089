import { AppLayout } from 'components/AppLayout/AppLayout';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumbs';
import { Columns } from 'components/Columns/Columns';
import { ConnectorIcon } from 'components/FileBrowser/ConnectorIcon';
import { AdminTabs } from 'components/Tabs/Tabs';
import { Api } from 'modules/api/api';
import { HomePageCrumb } from 'pages/Overview/HomePage/HomePage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminPageCrumb } from '../AdminPage/AdminPage';

export const ConnectorsPageCrumb: Breadcrumb = {
  page: 'Administration.ConnectorsPage',
  icon: 'link',
  location: '/admin/connectors',
};

export const ConnectorsPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const location = document.location;
  const connectors = Api.locHub.xillioApi.connectors.getAll();
  let dashboardUrl = '/xillio/admin/dashboard';
  if (process.env.NODE_ENV === 'development') {
    dashboardUrl = `${location.protocol}//${location.hostname}${dashboardUrl}`;
  }

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, ConnectorsPageCrumb]}>
      <AdminTabs>
        <div className="actions">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={dashboardUrl}
            className="button is-success is-pulled-right"
            id="create-configuration-button"
          >
            <span className="icon">
              <i className="fas fa-plus" />
            </span>
            <span>{t('Administration.ConnectorsPage.createButton')}</span>
          </a>
        </div>
        <div className="connectors">
          <Columns columnCount={4}>
            {(connectors || []).map(connector => (
              <a
                className="connector"
                key={connector.configurationType}
                rel="noopener noreferrer"
                href={'/xillio/docs/#connector-' + connector.configurationType.toLowerCase()}
                target="_blank"
              >
                <ConnectorIcon configuration={connector} />
                <span>{connector.configurationType}</span>
              </a>
            ))}
          </Columns>
        </div>
      </AdminTabs>
    </AppLayout>
  );
};
