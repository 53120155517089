import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import { ProjectDto } from 'modules/api/locHub/projects/project/project';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Future } from '../../common/utils/Future';
import { ProjectCreatePageCrumb } from '../../pages/Overview/ProjectCreatePage/ProjectCreatePage';
import { Field } from './Field';
import { SelectField, Props as SelectFieldProps } from './SelectField';

type Props<V> = Omit<SelectFieldProps<V>, 'name' | 'icon' | 'options'>;

export const Language: React.FC<Props<string>> = (props): ReactElement => (
  <SelectField name="language" leftIcon="fa-globe" options={['en', 'nl']} {...props} />
);

export const ProjectSelect: React.FC<{
  options: ProjectDto[];
  value?: ProjectId;
  errors?: unknown;
  onChange: (value: ProjectId) => Future<void>;
  i18nKey: string;
}> = ({ options, value, onChange, i18nKey, errors }): ReactElement => {
  const [t] = useTranslation();

  if (options.length === 0) {
    return (
      <Field i18nKey={i18nKey} name="projectId" errors={errors}>
        <Link to={ProjectCreatePageCrumb.location} className="button">
          <span className="icon">
            <i className="fas fa-list-alt" />
          </span>
          <span>{t(`${ProjectCreatePageCrumb.page}.title`)}</span>
        </Link>
      </Field>
    );
  }
  return (
    <SelectField<string>
      options={options.map(p => ({ value: p.id, label: p.name }))}
      onChange={onChange}
      errors={errors}
      value={options.filter(p => p.id === value).length > 0 ? value : undefined}
      name="projectId"
      leftIcon="fa-list-alt"
      i18nKey={i18nKey}
      required
    />
  );
};
