/*
    Colors available: https://bulma.io/documentation/overview/colors/
*/
export enum Colors {
  WHITE = 'white',
  BLACK = 'black',
  LIGHT = 'light',
  DARK = 'dark',
  PRIMARY = 'primary',
  LINK = 'link',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}
