import React, { ReactElement } from 'react';

import { TextField, Props as TextFieldProps } from './TextField';

type Props = Omit<TextFieldProps, 'name' | 'icon' | 'type'> & { name?: string };

export const Name: React.FC<Props> = (props): ReactElement => (
  <TextField name="name" leftIcon="fa-tag" maxLength={256} {...props} />
);

export const Username: React.FC<Props> = (props): ReactElement => {
  return <TextField name="username" leftIcon="fa-user" maxLength={256} {...props} />;
};

export const Description: React.FC<Props> = (props): ReactElement => (
  <TextField name="description" leftIcon="fa-comment" maxLength={4096} {...props} />
);

export const Email: React.FC<Props> = (props): ReactElement => (
  <TextField name="email" type="email" leftIcon="fa-envelope" maxLength={256} {...props} />
);

export const Password: React.FC<Props> = (props): ReactElement => (
  <TextField name="password" type="password" leftIcon="fa-lock" {...props} />
);

export const Code: React.FC<Props> = (props): ReactElement => <TextField name="code" leftIcon="fa-globe" {...props} />;
