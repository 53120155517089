import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, usePaginatedLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { Projects } from 'modules/api/locHub/projects/projects';

import { AutomationDtoWithProject, Automations } from '../../automations';

export const usePaginatedAutomationsWithProject = (
  page: number | undefined,
  options: Pagination = {},
  configuration: QueryConfiguration<Page<AutomationDtoWithProject> | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Page<AutomationDtoWithProject>> => {
  configuration.query.enabled = page;
  return usePaginatedLocHubQuery(
    [Automations.path, { page, options, with: Projects.path }],
    async () => {
      if (!page) {
        throw new Error(`Unexpected missing page`);
      }
      return Api.locHub.automations.getManyWithProject(page, options);
    },
    configuration,
  );
};
