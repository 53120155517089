import { useApiError } from 'common/hooks/useApiError';
import { Api } from 'modules/api/api';
import { Job } from 'modules/api/locHub/jobs/job/job';
import { useJobDtoWithProject } from 'modules/api/locHub/jobs/query/detail/detail';
import { usePaginatedJobTasks } from 'modules/api/locHub/tasks/query/paginatedListByJob/paginatedListByJob';
import { TaskError } from 'modules/api/locHub/tasks/task/error/error';
import { TaskId } from 'modules/api/locHub/tasks/task/id/id';
import { TaskStatus } from 'modules/api/locHub/tasks/task/status/status';
import { Task } from 'modules/api/locHub/tasks/task/task';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { queryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Page } from '../../../common/interfaces/common';
import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { CreateButton } from '../../../components/Button/Buttons';
import { HomePageCrumb } from '../HomePage/HomePage';
import { JobsListPageCrumb } from '../JobsListPage/JobsListPage';
import { Actions } from './Actions/Actions';
import { DataTable } from './DataTable';
import { Metadata } from './Metadata/Metadata';
import { TaskDetailRow } from './TaskDetailRow';
import { distinct, getSelectionRange } from './utillities';

export const JobInfoPageCrumb: Breadcrumb = {
  page: 'Overview.JobInfoPage',
  icon: 'info',
  location: '/jobs',
};

export const JobInfoPage: React.FC = (): ReactElement => {
  const apiError = useApiError();
  const history = useHistory();
  const [t] = useTranslation();
  const jobId = useParams<{ jobId: string }>().jobId;
  const jobDto = useJobDtoWithProject(jobId, {
    error: {
      handler: (error: Error): void => {
        history.push('/jobs');
        apiError.locHub.handle(
          error,
          t('Overview.JobInfoPage.errorNotFound', {
            jobId: jobId,
          }),
        );
      },
    },
    query: {},
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [lastSelection, setLastSelection] = useState<TaskId | null>(null);
  const [selection, setSelection] = useState<TaskId[]>([]);
  const [tries, setTries] = useState<number>(5);
  const [taskStatuses, setTaskStatuses] = useState<Record<TaskId, TaskStatus>>({});
  const [taskErrors, setTaskErrors] = useState<Record<TaskId, TaskError>>({});

  const pageInfo: Page<Task[]> = {
    content: [],
    page: currentPage,
    pageSize: 50,
    totalElements: 0,
  };
  const jobTasks = usePaginatedJobTasks(jobId, pageInfo.page, {
    limit: pageInfo.pageSize,
    sort: 'updatedAt',
    direction: 'DESC',
  });

  const jobTasksData = jobTasks.success ? jobTasks.data : undefined;

  useEffect(() => {
    if (jobTasksData) {
      const statuses = { ...taskStatuses };
      for (const item of jobTasksData.content) {
        if (statuses[item.id] === item.status) {
          delete statuses[item.id];
        }
      }
      if (Object.keys(taskStatuses).length !== Object.keys(statuses).length) {
        setTaskStatuses(statuses);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobTasksData]);

  if (!jobDto.success || !jobTasks.success) {
    return <LoadingPage />;
  }

  pageInfo.totalElements = jobTasks.data.totalElements;
  const incompleteTasks = jobTasks.data.content.filter(task => task.isDownloadingAssets());
  if (incompleteTasks.length > 0 && tries > 0) {
    setTimeout(() => {
      queryCache.invalidateQueries(Api.locHub.tasks.path);
      setTries(old => old - 1);
    }, 1000);
  }

  /* State modifiers */
  const addToSelection = (ids: TaskId[]): void => {
    setSelection(old => [...old, ...ids].filter(distinct));
  };
  const removeFromSelection = (ids: TaskId[]): void => {
    setSelection(old => old.filter(o => !ids.includes(o)));
  };

  /* Actions */
  const toggleSelection = (id: TaskId, withShift: boolean): void => {
    if (withShift) {
      const taskIds = getSelectionRange(jobTasks.data.content, id, lastSelection);
      if (selection.includes(id)) {
        removeFromSelection(taskIds);
      } else {
        addToSelection(taskIds);
      }
    } else {
      if (selection.includes(id)) {
        removeFromSelection([id]);
      } else {
        addToSelection([id]);
      }
    }
    setLastSelection(id);
  };

  const job = new Job(jobDto.data);
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, JobsListPageCrumb, JobInfoPageCrumb]} className="jobs-info">
      <Metadata i18nKey={JobInfoPageCrumb.page} job={jobDto.data} />
      <Box
        i18nKey="Overview.JobsInfoPageTaskTable"
        action={
          job.mayCreateTasks() ? (
            <CreateButton
              id={'create-task-button'}
              i18nKey="Overview.JobsInfoPageTaskTable.createButton"
              to={`/tasks/new/${jobId}`}
            />
          ) : (
            undefined
          )
        }
      >
        {
          <Actions
            i18nKey="Overview.JobsInfoPageTaskTable"
            job={job}
            tasks={jobTasks.data.content}
            selection={selection}
            actions={{
              setTaskStatuses: (taskIds: TaskId[], status: TaskStatus): void => {
                const statuses = { ...taskStatuses };
                for (const taskId of taskIds) {
                  statuses[taskId] = status;
                }
                setTaskStatuses(statuses);
              },
              setTaskError: (errors: Record<TaskId, TaskError>): void => {
                setTaskErrors({ ...taskErrors, ...errors });
              },
              setSelection,
            }}
          />
        }
        <DataTable
          i18nKey="Overview.JobsInfoPageTaskTable"
          columns={['name', 'targetLanguage', 'createdAt', 'updatedAt', 'phase', '']}
          pageInfo={pageInfo}
          setPage={setCurrentPage}
          allSelected={selection.length === jobTasks.data.content.length}
          selectAll={(check): void => setSelection(check ? jobTasks.data.content.map(task => task.id) : [])}
        >
          {jobTasks.data.content.map(task => (
            <TaskDetailRow
              i18nKey="Overview.JobsInfoPageTaskTable"
              key={task.id}
              job={job}
              taskStatus={taskStatuses[task.id] ?? task.status}
              taskError={taskErrors[task.id] ?? task.error}
              taskCreatedAt={task.createdAt}
              taskId={task.id}
              taskName={task.name}
              taskTargetLanguage={task.targetLanguage}
              taskUpdatedAt={task.updatedAt}
              taskHasDownloadedAssets={task.hasDownloadedAssets()}
              taskIsDownloadingAssets={task.isDownloadingAssets()}
              taskIsReadyToBeConfirmed={task.isReadyToBeConfirmed()}
              taskIsTranslated={task.isTranslated()}
              projectId={jobDto.data.projectId}
              selected={selection.includes(task.id)}
              actions={{
                toggleSelected: toggleSelection,
                setTaskStatus: (taskId: TaskId, status: TaskStatus): void => {
                  const statuses = { ...taskStatuses };
                  statuses[taskId] = status;
                  setTaskStatuses(statuses);
                },
                setTaskError: (taskId: TaskId, error: TaskError): void => {
                  const errors = { ...taskErrors };
                  errors[taskId] = error;
                  setTaskErrors(errors);
                },
              }}
            />
          ))}
        </DataTable>
      </Box>
    </AppLayout>
  );
};
