import { DependencyList, Dispatch, useEffect, useMemo, useState } from 'react';

/**
 * Use this hook to pull in data using an async process. The value will initialize as 'undefined' and be populated as
 * soon as the data is fetched.
 *
 * @param action the fetching action to pull in the data
 * @param deps the dependencies of the action
 * @param clear whetere or not to clear the data before
 */
export function useAsyncState<T>(
  action: () => Promise<T>,
  deps: DependencyList = [],
  clear = false,
): [T | undefined, Dispatch<T>] {
  const [value, setValue] = useState<T>();

  const fetchData = useMemo(action, deps);

  useEffect(() => {
    if (clear) {
      setValue(undefined);
    }
    let mounted = true;
    fetchData.then(newValue => {
      if (mounted) {
        setValue(newValue);
      }
    });
    return (): void => {
      mounted = false;
    };
  }, [clear, fetchData, setValue]);

  return [value, setValue];
}
