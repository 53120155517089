import React, { ReactElement } from 'react';

export interface ChipActions {
  click?: (id: string) => void;
  delete?: (id: string) => void;
}

export interface Props {
  id: string;
  label: string;
  actions: ChipActions;
}

function getDeleteButton(id: string, click: (id: string) => void): JSX.Element {
  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={(): void => {
        click(id);
      }}
      className="tag is-delete"
    ></span>
  );
}

function getClickableChip(id: string, label: string, click: (id: string) => void): JSX.Element {
  return (
    <span
      className="tag"
      onClick={(): void => {
        click(id);
      }}
    >
      {label}
    </span>
  );
}

function getDefaultChip(label: string): JSX.Element {
  return <span className="tag">{label}</span>;
}

export const Chip: React.FC<Props> = ({ id, label, actions }): ReactElement => {
  return (
    <div className="control">
      <div className="tags has-addons">
        {actions.click ? getClickableChip(id, label, actions.click) : getDefaultChip(label)}
        {actions.delete ? getDeleteButton(id, actions.delete) : ''}
      </div>
    </div>
  );
};
