import { AppLayout } from 'components/AppLayout/AppLayout';
import { Box } from 'components/Box/Box';
import { useMessage } from 'components/Message/MessageProvider';
import { HttpError } from 'modules/api/error/error';
import { useJobDto } from 'modules/api/locHub/jobs/query/detail/detail';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement } from 'react';
import { useBoolean } from 'react-hanger';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { JobEditPageI18n } from './I18n';
import { JobArchivalButton } from './JobArchivalButton';
import { JobDeleteButton } from './JobDeleteButton';
import { JobEditForm } from './JobEditForm';
import { jobEditPage } from './Page';

export const JobEditPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();
  const history = useHistory();
  const reload = useBoolean(false);
  const jobId = useParams<{ jobId: string }>().jobId;
  const job = useJobDto(jobId, {
    error: {
      handler: (error: Error): void => {
        if (error instanceof HttpError && error.code === 404) {
          message.error(t(`${namespace}.jobNotFound`, { jobId: jobId }));
          history.push('/jobs');
        }
      },
    },
    query: {},
  });
  const namespace = JobEditPageI18n.configuration.namespace;
  /* Actions */
  const archived = (): void => {
    reload.toggle();
  };
  const deleted = (): void => {
    history.push('/jobs');
  };
  /* Render */
  if (!job.success) {
    return <LoadingPage />;
  }
  return (
    <AppLayout breadcrumbs={jobEditPage.breadcrumbs}>
      <Box i18nKey={jobEditPage.i18n.namespace}>
        {job.data.archived ? (
          <div className="notification is-info">
            <i className="fas fa-info-circle" style={{ marginRight: '8px' }} />
            {t(`${namespace}.archivedJobNotEditable`)}
          </div>
        ) : (
          <JobEditForm jobId={jobId} jobName={job.data.name}></JobEditForm>
        )}
      </Box>
      <Box i18nKey={`${namespace}.dangerZone`}>
        {job.data.archived ? (
          <JobDeleteButton jobId={jobId} actions={{ deleted }} />
        ) : (
          <JobArchivalButton jobId={jobId} actions={{ archived }} />
        )}
      </Box>
    </AppLayout>
  );
};
