import { AppLayout } from 'components/AppLayout/AppLayout';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumbs';
import { Columns } from 'components/Columns/Columns';
import { ProviderIcon } from 'components/FileBrowser/ProviderIcon';
import { AdminTabs } from 'components/Tabs/Tabs';
import { Api } from 'modules/api/api';
import { HomePageCrumb } from 'pages/Overview/HomePage/HomePage';
import React, { ReactElement } from 'react';

import { AdminPageCrumb } from '../AdminPage/AdminPage';

export const ProvidersPageCrumb: Breadcrumb = {
  page: 'Administration.ProvidersPage',
  icon: 'bezier-curve',
  location: '/admin/providers',
};

export const ProvidersPage: React.FC = (): ReactElement => {
  const humanTranslationProviders = Api.locHub.xillioApi.humanTranslation.getAll();
  const catTmsProviders = Api.locHub.xillioApi.catTms.getAll();
  const analyticsProviders = Api.locHub.xillioApi.analytics.getAll();
  const complimentaryPartnerProviders = Api.locHub.xillioApi.complimentaryPartner.getAll();

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, ProvidersPageCrumb]}>
      <AdminTabs>
        <div className="providers">
          <h1>Human Translation</h1>
          <Columns columnCount={4}>
            {(humanTranslationProviders || []).map(humanTranslation => (
              <a
                className="provider"
                key={humanTranslation.configurationType}
                rel="noopener noreferrer"
                href={
                  'https://www.hellotranslate.com/company/' +
                  humanTranslation.configurationType.toLowerCase().replace(/ /g, '-')
                }
                target="_blank"
              >
                <ProviderIcon configuration={humanTranslation} />
                <span>{humanTranslation.configurationType}</span>
              </a>
            ))}
          </Columns>
          <h1>CAT/TMS</h1>
          <Columns columnCount={4}>
            {(catTmsProviders || []).map(catTms => (
              <a
                className="provider"
                key={catTms.configurationType}
                rel="noopener noreferrer"
                href={
                  'https://www.hellotranslate.com/company/' + catTms.configurationType.toLowerCase().replace(/ /g, '-')
                }
                target="_blank"
              >
                <ProviderIcon configuration={catTms} />
                <span>{catTms.configurationType}</span>
              </a>
            ))}
          </Columns>
          <h1>Analytics</h1>
          <Columns columnCount={4}>
            {(analyticsProviders || []).map(analytics => (
              <a
                className="provider"
                key={analytics.configurationType}
                rel="noopener noreferrer"
                href={
                  'https://www.hellotranslate.com/company/' +
                  analytics.configurationType.toLowerCase().replace(/ /g, '-')
                }
                target="_blank"
              >
                <ProviderIcon configuration={analytics} />
                <span>{analytics.configurationType}</span>
              </a>
            ))}
          </Columns>
          <h1>Complimentary Partner Technologies</h1>
          <Columns columnCount={4}>
            {(complimentaryPartnerProviders || []).map(complimentaryPartner => (
              <a
                className="provider"
                key={complimentaryPartner.configurationType}
                rel="noopener noreferrer"
                href={
                  'https://www.hellotranslate.com/company/' +
                  complimentaryPartner.configurationType.toLowerCase().replace(/ /g, '-')
                }
                target="_blank"
              >
                <ProviderIcon configuration={complimentaryPartner} />
                <span>{complimentaryPartner.configurationType}</span>
              </a>
            ))}
          </Columns>
        </div>
      </AdminTabs>
    </AppLayout>
  );
};
