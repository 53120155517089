import { Api } from 'modules/api/api';
import { AutomationCreatePage } from 'pages/Administration/AutomationCreatePage/AutomationCreatePage';
import { AutomationEditPage } from 'pages/Administration/AutomationEditPage/AutomationEditPage';
import { AutomationInfoPage } from 'pages/Administration/AutomationInfoPage/AutomationInfoPage';
import { AutomationListPage } from 'pages/Administration/AutomationListPage/AutomationListPage';
import { ConnectorsPage } from 'pages/Administration/ConnectorsPage/ConnectorsPage';
import { LanguageEditPage } from 'pages/Administration/LanguageEditPage/LanguageEditPage';
import { ProvidersPage } from 'pages/Administration/ProvidersPage/ProvidersPage';
import { jobEditPage } from 'pages/Overview/JobEditPage/Page';
import { TaskInfoPage } from 'pages/Overview/TaskInfoPage/TaskInfoPage';
import React, { ReactElement, useState } from 'react';
import { getI18n } from 'react-i18next';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import {
  AdminPage,
  ChangePasswordPage,
  ForgotPasswordPage,
  HelpPage,
  HomePage,
  JobCreatePage,
  JobInfoPage,
  JobsListPage,
  LoginPage,
  NotFoundPage,
  ProjectCreatePage,
  ProjectEditPage,
  ProjectListPage,
  TaskCreatePage,
  UserCreatePage,
  UserEditPage,
  UserListPage,
} from './pages';
import { LanguageCreatePage } from './pages/Administration/LanguageCreatePage/LanguageCreatePage';
import { LanguageListPage } from './pages/Administration/LanguageListPage/LanguageListPage';

export const App: React.FC = (): ReactElement => {
  const [user, setUser] = useState(Api.getUserDataFromCache);
  const location = useLocation();
  if (!user) {
    // we are not authenticated!
    return (
      <Switch>
        <Route exact path="/login" render={(): ReactElement => <LoginPage onLogin={setUser} />} />
        <Route exact path="/login/change-password/:userId/:token" component={ChangePasswordPage} />
        <Route exact path="/login/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/help" component={HelpPage} />
        <Redirect
          to={{
            pathname: '/login',
            search: `from=${location.pathname}`,
          }}
        />
      </Switch>
    );
  } else {
    // we are authenticated
    getI18n().changeLanguage(user.preferredLanguage);
    return (
      <Switch>
        <Route exact path="/home" component={HomePage} />
        <Route exact path="/jobs" component={JobsListPage} />
        <Route exact path="/jobs/new" component={JobCreatePage} />
        <Route {...jobEditPage.configuration} />
        <Route exact path="/jobs/:jobId" component={JobInfoPage} />
        <Route exact path="/help" component={HelpPage} />
        <Route exact path="/tasks/new/:jobId" component={TaskCreatePage} />
        <Route exact path="/tasks/:taskId" component={TaskInfoPage} />
        <Route exact path="/projects" component={ProjectListPage} />
        <Route exact path="/projects/new" component={ProjectCreatePage} />
        <Route exact path="/projects/:projectId" component={ProjectEditPage} />
        <Route exact path="/admin" component={AdminPage} />
        <Route exact path="/admin/connectors" component={ConnectorsPage} />
        <Route exact path="/admin/providers" component={ProvidersPage} />
        <Route exact path="/admin/users" component={UserListPage} />
        <Route exact path="/admin/users/new" component={UserCreatePage} />
        <Route exact path="/admin/users/:userId" component={UserEditPage} />
        <Route exact path="/admin/languages" component={LanguageListPage} />
        <Route exact path="/admin/languages/new" component={LanguageCreatePage} />
        <Route exact path="/admin/languages/:languageId" component={LanguageEditPage} />
        <Route exact path="/admin/automations" component={AutomationListPage} />
        <Route exact path="/admin/automations/new" component={AutomationCreatePage} />
        <Route exact path="/admin/automations/:automationId" component={AutomationInfoPage} />
        <Route exact path="/admin/automations/:automationId/edit" component={AutomationEditPage} />
        <Redirect path="/login" to="/home" />
        <Redirect exact path="/" to="/home" />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
};
