import { Page } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, usePaginatedLocHubQuery } from 'modules/api/locHub/entity/query/query';

import { UserDto } from '../../user/user';
import { Users } from '../../users';

export const usePaginatedUsers = (
  page: number | undefined,
  configuration: QueryConfiguration<Page<UserDto> | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Page<UserDto>> => {
  configuration.query.enabled = page;
  return usePaginatedLocHubQuery(
    [Users.path, { page }],
    async () => {
      if (!page) {
        throw new Error(`Unexpected missing page`);
      }
      return Api.locHub.users.getMany(page);
    },
    configuration,
  );
};
