import React, { ReactElement } from 'react';

import { Translatable } from '../../../common/language';
import { LoadingButton } from '../../../components/LoadingButton/LoadingButton';

export interface Props extends Translatable {
  loading: boolean;
  disabled: boolean;
  onClick?: () => void;
}

export const SubmitButton: React.FC<Props> = ({ i18nKey, loading, disabled, onClick }): ReactElement => {
  return (
    <LoadingButton
      i18nKey={`${i18nKey}.submit`}
      loading={loading}
      className="button is-pulled-right is-success"
      disabled={disabled}
      onClick={onClick}
    />
  );
};
