import { TestIds } from 'components/Input/Select/testIds';
import React, { ReactElement } from 'react';

export interface OptionActions {
  readonly select: (id: string) => void;
  readonly deselect: (id: string) => void;
}

interface Props {
  readonly id: string;
  readonly label: string;
  readonly selected: boolean;
  readonly actions: OptionActions;
}

export const Option: React.FC<Props> = ({ id, label, selected, actions }): ReactElement => {
  return (
    <a
      href="!#"
      key={id}
      onClick={(event: React.MouseEvent): void => {
        event.preventDefault();
        selected ? actions.deselect(id) : actions.select(id);
      }}
      className="dropdown-item"
      data-value={id}
      data-selected={selected}
      data-testid={selected ? TestIds.SELECTED_DROPDOWN_ITEM : TestIds.DROPDOWN_ITEM}
    >
      {
        <span className="icon">
          <i className={selected ? 'fas fa-check-square' : 'far fa-square'} />
        </span>
      }
      {label}
    </a>
  );
};
