import { SelectOption } from 'components/Input/Select/Dropdown/Options/Option';
import { EntityName } from 'modules/api/locHub/entity/name/name';
import { LanguageDto } from 'modules/api/locHub/languages/language/language';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { TargetLanguagesSelect } from '../../Common/TargetLanguages/TargetLanguages';

interface Props {
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
  invalidOptions: ReadonlySet<IETFLanguageCode>;
  languages: LanguageDto[];
}

export const AutomationTargetLanguagesSelect: React.FC<Props> = ({
  languages,
  invalidOptions,
  control,
  errors,
}): ReactElement => {
  const options = new Map<IETFLanguageCode, SelectOption>();
  for (const language of languages) {
    if (invalidOptions.has(language.code)) {
      continue;
    }
    options.set(language.code, { id: language.code, label: language.name });
  }
  return <TargetLanguagesSelect entity={EntityName.AUTOMATION} options={options} control={control} errors={errors} />;
};
