import { ChangeEvent } from 'react';

export interface ValidationError {
  field: string;
  codes: string[];
}

export interface ValidationErrors {
  errors: ValidationError[];
}

export const getOtherErrors = (
  fieldName: string,
  error: ValidationErrors | undefined,
): ValidationErrors | undefined => {
  let remainingErrors: ValidationErrors | undefined;
  if (error) {
    remainingErrors = {
      errors: error.errors.filter(validationError => {
        return validationError.field !== fieldName;
      }),
    };
  }
  return remainingErrors && remainingErrors.errors.length ? remainingErrors : undefined;
};

export const validateEmail = (
  event: ChangeEvent<HTMLInputElement>,
  error: ValidationErrors | undefined,
  setError: React.Dispatch<React.SetStateAction<ValidationErrors | undefined>>,
): void => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(event.target.value)) {
    setError({
      errors: [{ field: 'email', codes: ['Invalid.email'] }, ...(error?.errors || [])],
    });
  } else {
    setError(getOtherErrors('email', error));
  }
};

export const validateLanguageCode = (
  event: ChangeEvent<HTMLInputElement>,
  error: ValidationErrors | undefined,
  setError: React.Dispatch<React.SetStateAction<ValidationErrors | undefined>>,
): void => {
  // eslint-disable-next-line no-useless-escape
  const ietfRegex = /^((?<grandfathered>(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))|((?<language>([A-Za-z]{2,3}(-(?<extlang>[A-Za-z]{3}(-[A-Za-z]{3}){0,2}))?)|[A-Za-z]{4}|[A-Za-z]{5,8})(-(?<script>[A-Za-z]{4}))?(-(?<region>[A-Za-z]{2}|[0-9]{3}))?(-(?<variant>[A-Za-z0-9]{5,8}|[0-9][A-Za-z0-9]{3}))*(-(?<extension>[0-9A-WY-Za-wy-z](-[A-Za-z0-9]{2,8})+))*(-(?<privateUse>x(-[A-Za-z0-9]{1,8})+))?)|(?<privateUse1>x(-[A-Za-z0-9]{1,8})+))$/;
  if (!ietfRegex.test(event.target.value)) {
    setError({
      errors: [{ field: 'code', codes: ['Invalid.languageCode'] }, ...(error?.errors || [])],
    });
  } else {
    setError(getOtherErrors('code', error));
  }
};
