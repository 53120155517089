import { Button } from 'components/Button/Button';
import { ConfirmButton } from 'components/ConfirmButton/ConfirmButton';
import { Api } from 'modules/api/api';
import { useAutomationDto } from 'modules/api/locHub/automations/query/detail/detail';
import { useLanguages } from 'modules/api/locHub/languages/query/list/list';
import { useProjects } from 'modules/api/locHub/projects/query/list/list';
import { useCmsConfigurations } from 'modules/api/locHub/xillioApi/query/list/list';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { useMessage } from '../../../components/Message/MessageProvider';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { AutomationListPageCrumb } from '../AutomationListPage/AutomationListPage';
import { AutomationEditForm } from './AutomationEditForm';

export const AutomationEditPageCrumb: Breadcrumb = {
  page: 'Administration.AutomationEditPage',
  icon: 'edit',
  location: '/admin/automations/:automationId/edit',
};

export const AutomationEditPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();
  const history = useHistory();
  const automationId = useParams<{ automationId: string }>().automationId;
  const namespace = AutomationEditPageCrumb.page;
  const handleApiError = (_error: Error): void => {
    history.push('/admin/automations');
    message.error(t('Administration.AutomationEditPage.errorNotFound', { automationId: automationId }));
  };
  const automation = useAutomationDto(automationId, { error: { handler: handleApiError }, query: {} });
  const projects = useProjects();
  const languages = useLanguages();
  const cmsConfigurations = useCmsConfigurations();
  if (!automation.success || !projects.success || !languages.success || !cmsConfigurations.success) {
    return <LoadingPage></LoadingPage>;
  }
  if (automation.data.archived) {
    history.push('/admin/automations');
    message.error(t('Administration.AutomationEditPage.archivedEntityMayNotBeEdited', { automationId: automationId }));
  }
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, AutomationListPageCrumb, AutomationEditPageCrumb]}>
      <Box i18nKey={namespace}>
        <AutomationEditForm
          automation={automation.data}
          projects={projects.data}
          languages={languages.data}
          cmsConfigurations={cmsConfigurations.data}
        />
      </Box>
      <Box i18nKey="Administration.AutomationEditPageDangerZone">
        <div className="field is-grouped">
          <p className="control">
            {automation.data.active ? (
              <ConfirmButton
                i18nKey="Administration.AutomationEditPageDangerZone.disableButton"
                className="is-info"
                disabled={false}
                onConfirm={async (): Promise<void> => {
                  await Api.locHub.automations.update(automationId, { active: false });
                }}
              />
            ) : (
              <Button
                i18nKey="Administration.AutomationEditPageDangerZone.enableButton"
                className="is-success"
                onClick={async (): Promise<void> => {
                  await Api.locHub.automations.update(automationId, { active: true });
                }}
              />
            )}
          </p>
          <p className="control">
            <ConfirmButton
              i18nKey="Administration.AutomationEditPageDangerZone.archiveButton"
              className="is-warning"
              disabled={false}
              onConfirm={async (): Promise<void> => {
                await Api.locHub.automations.archive(automationId);
                history.push('/admin/automations');
                message.success(t('Administration.AutomationEditPageDangerZone.archiveButton.success'));
              }}
            />
          </p>
          <p className="control">
            <ConfirmButton
              i18nKey="Administration.AutomationEditPageDangerZone.deleteButton"
              className="is-danger"
              disabled={false}
              onConfirm={async (): Promise<void> => {
                await Api.locHub.automations.delete(automationId);
                history.push('/admin/automations');
                message.success(t('Administration.AutomationEditPageDangerZone.deleteButton.success'));
              }}
            />
          </p>
        </div>
      </Box>
    </AppLayout>
  );
};
