import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const SourceLanguageSelectI18n = {
  configuration: { namespace: I18nNamespace.SOURCE_LANGUAGE_SELECT, labelKey: 'label' },
  translations: {
    label: dictionary.sourceLanguage,
    placeholder: dictionary.selectSourceLanguage,
    noOptions: dictionary.noOptions,
    filterPlaceholder: dictionary.filter,
    clear: dictionary.clear,
    save: dictionary.save,
  },
};
