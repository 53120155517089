import { XillioConnector } from 'common/interfaces/xillio';

export enum XillioConnectorType {
  AEM = 'Aem',
  BITBUCKET = 'Bitbucket',
  BOX = 'Box',
  CONTENTFUL = 'Contentful',
  DROPBOX = 'Dropbox',
  DRUPAL = 'Drupal',
  EGNYTE = 'Egnyte',
  FILENET = 'Filenet',
  GITLAB = 'GitLab',
  GOOGLE_DRIVE = 'GoogleDrive',
  JSON_RPC = 'JsonRpc',
  ONE_DRIVE = 'OneDrive',
  OPENTEXT = 'Opentext',
  SALESFORCE = 'Salesforce',
  SHARE_POINT = 'SharePoint',
  SHARE_POINT_V2 = 'SharePointV2',
  SITECORE = 'Sitecore',
  WORDPRESS = 'WordPress',
  ZENDESK = 'Zendesk',
}

export class XillioConnectors {
  private static connectors = new Map<XillioConnectorType, XillioConnector>([
    [
      XillioConnectorType.AEM,
      {
        configurationType: XillioConnectorType.AEM,
        translationScopeSupport: false,
        description:
          'Adobe Experience Manager brings your content management system (CMS) and digital asset management (DAM) together — in one place.',
      },
    ],
    [
      XillioConnectorType.BITBUCKET,
      {
        configurationType: XillioConnectorType.BITBUCKET,
        translationScopeSupport: false,
        description:
          'Bitbucket is a web-based version control repository hosting service for source code and development projects.',
      },
    ],
    [
      XillioConnectorType.BOX,
      {
        configurationType: XillioConnectorType.BOX,
        translationScopeSupport: false,
        description:
          'Box Drive is the incredibly simple way to stream all of your files — even millions of files — right to your desktop, taking up very little hard drive space.',
      },
    ],
    [
      XillioConnectorType.CONTENTFUL,
      {
        configurationType: XillioConnectorType.CONTENTFUL,
        translationScopeSupport: false,
        description:
          'Contentful is the API-first content management platform to create, manage and publish content on any digital channel.',
      },
    ],
    [
      XillioConnectorType.DROPBOX,
      {
        configurationType: XillioConnectorType.DROPBOX,
        translationScopeSupport: false,
        description:
          'Dropbox is a modern workspace designed to reduce busywork—so you can focus on the things that matter.',
      },
    ],
    [
      XillioConnectorType.DRUPAL,
      {
        configurationType: XillioConnectorType.DRUPAL,
        translationScopeSupport: true,
        description:
          "Drupal is an open source platform for building amazing digital experiences. It's made by a dedicated community. Anyone can use it, and it will always be free.",
      },
    ],
    [
      XillioConnectorType.EGNYTE,
      {
        configurationType: XillioConnectorType.EGNYTE,
        translationScopeSupport: false,
        description: 'Egnyte CONNECT: Secure File Sharing & Collaboration.',
      },
    ],
    [
      XillioConnectorType.FILENET,
      {
        configurationType: XillioConnectorType.FILENET,
        translationScopeSupport: false,
        description:
          'IBM FileNet Content Manager is document management engine with enterprise content, security and storage features plus process management capabilities.',
      },
    ],
    [
      XillioConnectorType.GITLAB,
      {
        configurationType: XillioConnectorType.GITLAB,
        translationScopeSupport: false,
        description:
          'GitLab is a web-based Git-repository manager providing wiki, issue-tracking and CI/CD pipeline features.',
      },
    ],
    [
      XillioConnectorType.GOOGLE_DRIVE,
      {
        configurationType: XillioConnectorType.GOOGLE_DRIVE,
        translationScopeSupport: false,
        description: 'A safe place for all your files.',
      },
    ],
    [
      XillioConnectorType.JSON_RPC,
      {
        configurationType: XillioConnectorType.JSON_RPC,
        translationScopeSupport: false,
        description:
          'JSON-RPC is a stateless, light-weight remote procedure call (RPC) protocol. It is designed to be simple!',
      },
    ],
    [
      XillioConnectorType.ONE_DRIVE,
      {
        configurationType: XillioConnectorType.ONE_DRIVE,
        translationScopeSupport: false,
        description:
          'OneDrive is Microsoft\'s service for hosting files in the "cloud", that\'s available for free to all the owners of a Microsoft account and offers users a simple way to store, sync and share all kinds of files, with other people and devices.',
      },
    ],
    [
      XillioConnectorType.OPENTEXT,
      {
        configurationType: XillioConnectorType.OPENTEXT,
        translationScopeSupport: false,
        description:
          'OpenText Content Server provides the core set of tools you need to capture, process and manage unstructured content across your organization including document management, workflow, search and information retrieval services, all tightly integrated into a platform that is easily customized and extended.',
      },
    ],
    [
      XillioConnectorType.SALESFORCE,
      {
        configurationType: XillioConnectorType.SALESFORCE,
        translationScopeSupport: true,
        description: 'Salesforce Knowledge is part of complex cloud-based CRM solution.',
      },
    ],
    [
      XillioConnectorType.SHARE_POINT,
      {
        configurationType: XillioConnectorType.SHARE_POINT,
        translationScopeSupport: false,
        description: 'SharePoint is web-based, collaborative platform that integrates with Microsoft Office.',
      },
    ],
    [
      XillioConnectorType.SHARE_POINT_V2,
      {
        configurationType: XillioConnectorType.SHARE_POINT_V2,
        translationScopeSupport: false,
        description: 'SharePoint is web-based, collaborative platform that integrates with Microsoft Office.',
      },
    ],
    [
      XillioConnectorType.SITECORE,
      {
        configurationType: XillioConnectorType.SITECORE,
        translationScopeSupport: false,
        description:
          'Sitecore is a CMS with additional marketing functionality that analyses customer data from multiple sources and makes it possible to show custom content.',
      },
    ],
    [
      XillioConnectorType.WORDPRESS,
      {
        configurationType: XillioConnectorType.WORDPRESS,
        translationScopeSupport: true,
        description:
          'WordPress is a free and open-source content management system (CMS). Features include a plugin architecture and a template system.',
      },
    ],
    [
      XillioConnectorType.ZENDESK,
      {
        configurationType: XillioConnectorType.ZENDESK,
        translationScopeSupport: true,
        description: 'Zendesk Guide is a smart knowledge base system for better self-service and empowered agents.',
      },
    ],
  ]);

  public static getAll(): XillioConnector[] {
    const connectors: XillioConnector[] = Array.from(XillioConnectors.connectors.values());
    return connectors.filter(
      connector =>
        connector.configurationType !== XillioConnectorType.JSON_RPC &&
        connector.configurationType !== XillioConnectorType.SHARE_POINT_V2,
    );
  }

  public static supportsTranslationScope(type: XillioConnectorType): boolean {
    if (!XillioConnectors.connectors.has(type)) {
      throw new Error(`Unsupported Connector type: ${type}"`);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return XillioConnectors.connectors.get(type)!.translationScopeSupport;
  }
}
