import { AppLocalization, ResourceTree } from './AppLocalization';

export const pages: AppLocalization = {};

export const errors: ResourceTree = {};

const overrides = {
  pages,
  errors,
};
export default overrides;
