import { EntityName } from 'modules/api/locHub/entity/name/name';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DescriptionInput } from '../../Common/Description/Description';
import { AutomationDescriptionInputI18n } from './I18n';

interface Props {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  value?: string;
}

export const AutomationDescriptionInput: React.FC<Props> = ({ value, register, errors }): ReactElement => {
  const [t] = useTranslation();
  const maxLength = 4096;
  const namespace = AutomationDescriptionInputI18n.configuration.namespace;
  const label = t(`${namespace}.label`);
  const placeholder = t(`${namespace}.placeholder`);
  const validationMaxLengthError = t(`${namespace}.validationMaxLengthError`, { maxLength });
  return (
    <DescriptionInput
      entity={EntityName.AUTOMATION}
      maxLength={maxLength}
      value={value}
      register={register}
      errors={errors}
      texts={{ label, placeholder, validationMaxLengthError }}
    />
  );
};
