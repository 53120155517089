import { LocHubEntityDto } from 'modules/api/locHub/entity/entity';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, useLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { LocHubApi } from 'modules/api/locHub/lochub';
import { Task } from 'modules/api/locHub/tasks/task/task';
import { UUIDv4 } from 'modules/types/uuid/v4/v4';

export const useLocHubListAllByQuery = <Entity extends LocHubEntityDto | Task>(
  api: LocHubApi,
  getAllBy: (id: UUIDv4) => Promise<Entity[]>,
  id: UUIDv4 | undefined,
  configuration: QueryConfiguration<Entity[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Entity[]> => {
  configuration.query.enabled = id;
  return useLocHubQuery<Entity[]>(
    [api.path, { by: { key: getAllBy.name, id } }],
    async () => {
      if (!id) {
        throw new Error(`Unexpected missing id`);
      }
      return getAllBy(id);
    },
    configuration,
  );
};
