import { DeepPartial } from 'react-hook-form';

type ObjectLiteral = Record<string | number | symbol, unknown>;

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: unknown): item is ObjectLiteral {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 * @link https://stackoverflow.com/a/34749873
 */
export function mergeDeep<T = ObjectLiteral>(target: T, ...sources: DeepPartial<T>[]): T {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (!target[key]) Object.assign(target, { [key]: {} });
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return mergeDeep(target, ...sources);
}
