export class HttpError<Body> extends Error {
  public readonly code: number;
  public readonly response: Response;
  public readonly body: Body | null;
  public readonly url: string;

  public constructor(response: Response, body: Body | null) {
    super(`${response.statusText}`);
    Object.setPrototypeOf(this, HttpError.prototype);
    this.code = response.status;
    this.response = response;
    this.body = body;
    this.url = response.url;
  }
}
