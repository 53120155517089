import { XillioProvider } from 'common/interfaces/xillio';

export enum XillioProviderType {
  CONNECTING_CONTENT = 'Connecting-Content',
  MEMOQ = 'MemoQ',
  TRANSLATE_5 = 'Translate-5',
}

export class XillioCatTmsProviders {
  private static catTms = new Map<XillioProviderType, XillioProvider>([
    [
      XillioProviderType.CONNECTING_CONTENT,
      {
        configurationType: XillioProviderType.CONNECTING_CONTENT,
      },
    ],
    [
      XillioProviderType.MEMOQ,
      {
        configurationType: XillioProviderType.MEMOQ,
      },
    ],
    [
      XillioProviderType.TRANSLATE_5,
      {
        configurationType: XillioProviderType.TRANSLATE_5,
      },
    ],
  ]);

  public static getAll(): XillioProvider[] {
    return Array.from(XillioCatTmsProviders.catTms.values());
  }
}
