import { useApiError } from 'common/hooks/useApiError';
import { XillioEntity } from 'common/interfaces/xillio';
import { Columns } from 'components/Columns/Columns';
import { AutomationAcceptTranslation } from 'components/HookForm/Entity/Automation/AcceptTranslation/AcceptTranslation';
import { AutomationDelayInput } from 'components/HookForm/Entity/Automation/Delay/Delay';
import { AutomationDescriptionInput } from 'components/HookForm/Entity/Automation/Description/Description';
import { AutomationMarkForTranslation } from 'components/HookForm/Entity/Automation/MarkForTranslation/MarkForTranslation';
import { AutomationNameInput } from 'components/HookForm/Entity/Automation/Name/Name';
import { AutomationPathsInput } from 'components/HookForm/Entity/Automation/Paths/Paths';
import { AutomationPeriodInput } from 'components/HookForm/Entity/Automation/Period/Period';
import { AutomationRecursiveCrawl } from 'components/HookForm/Entity/Automation/RecursiveCrawl/RecursiveCrawl';
import { AutomationSourceLanguageSelect } from 'components/HookForm/Entity/Automation/SourceLanguage/SourceLanguage';
import { AutomationTargetLanguagesSelect } from 'components/HookForm/Entity/Automation/TargetLanguages/TargetLanguages';
import { CmsSelect } from 'components/HookForm/Entity/Common/Cms/Cms';
import { ProjectSelect } from 'components/HookForm/Entity/Common/Project/Project';
import { CancelInput } from 'components/HookForm/Input/Cancel/Cancel';
import { SubmitInput } from 'components/HookForm/Input/Submit/Submit';
import { useMessage } from 'components/Message/MessageProvider';
import { Api } from 'modules/api/api';
import { useLanguages } from 'modules/api/locHub/languages/query/list/list';
import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import { useProjects } from 'modules/api/locHub/projects/query/list/list';
import { useCmsConfigurations } from 'modules/api/locHub/xillioApi/query/list/list';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { ReactElement, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AutomationCreatePageI18n } from './I18n';

interface Form {
  projectId: ProjectId;
  cmsId: string;
  automationName: string;
  automationDescription: string;
  automationPaths: XillioEntity[];
  automationPeriod: string;
  automationDelay: string;
  automationSourceLanguage: Set<IETFLanguageCode>;
  automationSourceLanguagesFilter: Set<IETFLanguageCode>;
  automationTargetLanguages: Set<IETFLanguageCode>;
  automationAcceptTranslation: boolean;
  automationMarkForTranslation: boolean;
  automationRecursiveCrawl: boolean;
}

export const AutomationCreateForm: React.FC<{}> = (): ReactElement => {
  const { watch, register, control, handleSubmit, reset, errors, formState } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      automationName: '',
      automationDescription: '',
      automationPeriod: '60',
      automationDelay: '0',
      automationSourceLanguage: new Set(),
      automationTargetLanguages: new Set(),
      automationSourceLanguagesFilter: new Set(),
      automationMarkForTranslation: false,
      automationAcceptTranslation: false,
      automationRecursiveCrawl: false,
      automationPaths: [],
    },
  });
  const message = useMessage();
  const history = useHistory();
  const apiError = useApiError();
  const [t] = useTranslation();
  const projects = useProjects();
  const languages = useLanguages();
  const cmsConfigurations = useCmsConfigurations();
  const loading = formState.isSubmitting;
  const projectId = watch('projectId');
  const sourceLanguage = watch('automationSourceLanguage');
  const targetLanguages = watch('automationTargetLanguages');
  const cmsId = watch('cmsId');
  useEffect(() => {
    if (projects.success) {
      const selectedProject = projects.data.find(project => {
        return project.id === projectId;
      });
      if (selectedProject) {
        if (selectedProject.defaultSourceLanguage) {
          control.setValue('automationSourceLanguage', new Set([selectedProject.defaultSourceLanguage]), {
            shouldValidate: true,
          });
          control.setValue('automationSourceLanguagesFilter', new Set([selectedProject.defaultSourceLanguage]), {
            shouldValidate: true,
          });
        }
        if (selectedProject.defaultTargetLanguages.length) {
          control.setValue('automationTargetLanguages', new Set(selectedProject.defaultTargetLanguages), {
            shouldValidate: true,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, cmsId]);
  useEffect(() => {
    if (cmsConfigurations.success) {
      control.setValue('automationPaths', [], {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsId]);
  if (!languages.success || !cmsConfigurations.success || !projects.success) {
    return <></>;
  }
  const cms = cmsConfigurations.data.find(configuration => {
    return configuration.id === cmsId;
  });
  const translationScopeSupport = cms
    ? Api.locHub.xillioApi.connectors.supportsTranslationScope(cms.configurationType)
    : false;
  /* Actions */
  const submitAction: SubmitHandler<Form> = async (data: UnpackNestedValue<Form>): Promise<void> => {
    try {
      await Api.locHub.automations.create({
        active: true,
        projectId: data.projectId,
        engineConfigurationId: data.cmsId,
        name: data.automationName,
        description: data.automationDescription,
        autoAcceptTranslation: data.automationAcceptTranslation,
        autoMarkForTranslation: data.automationMarkForTranslation,
        delay: Number.parseInt(data.automationDelay, 10),
        period: Number.parseInt(data.automationPeriod, 10),
        paths: data.automationPaths.map(item => {
          return item.path;
        }),
        recursiveCrawl: data.automationRecursiveCrawl,
        sourceLanguageFilter: translationScopeSupport
          ? Array.from(data.automationSourceLanguagesFilter)
          : Array.from(data.automationSourceLanguage),
        targetLanguages: Array.from(data.automationTargetLanguages),
      });
      reset(data);
      message.success(t(`${AutomationCreatePageI18n.configuration.namespace}.automationCreateSuccess`));
      history.push('/admin/automations');
    } catch (error) {
      apiError.locHub.handle(error, t(`${AutomationCreatePageI18n.configuration.namespace}.automationCreateFailure`));
    }
  };
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Columns>
        <ProjectSelect control={control} errors={errors} projects={projects.data} />
        <CmsSelect control={control} errors={errors} configurations={cmsConfigurations.data} />
        <AutomationNameInput register={register} errors={errors} />
        <AutomationDescriptionInput register={register} errors={errors} />
        <AutomationPeriodInput register={register} errors={errors} />
        <AutomationDelayInput register={register} errors={errors} />
        <AutomationMarkForTranslation control={control} />
        <AutomationAcceptTranslation control={control} />
        <AutomationPathsInput control={control} cmsConfiguration={cms} />
        <AutomationRecursiveCrawl control={control} />
        <AutomationSourceLanguageSelect
          control={control}
          errors={errors}
          languages={languages.data}
          invalidOptions={!translationScopeSupport ? targetLanguages : new Set()}
          translationScopeSupport={translationScopeSupport}
        />
        <AutomationTargetLanguagesSelect
          control={control}
          errors={errors}
          languages={languages.data}
          invalidOptions={sourceLanguage && !translationScopeSupport ? sourceLanguage : new Set()}
        />
      </Columns>
      <div className={'buttons is-pulled-right'}>
        <SubmitInput loading={loading} disabled={!formState.isValid || !formState.isDirty} />
        <CancelInput to={'/admin/automations'} />
      </div>
    </form>
  );
};
