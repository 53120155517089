import { SelectionTile } from 'components/SelectionTile/SelectionTile';
import { TFunction } from 'i18next';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { HomePageCrumb } from '../HomePage/HomePage';
import { JobInfoPageCrumb } from '../JobInfoPage/JobInfoPage';
import { JobsListPageCrumb } from '../JobsListPage/JobsListPage';
import { CMSWizard } from './CMSWizard/CMSWizard';
import { ExistingAssetWizard } from './ExistingAssetWizard/ExistingAssetWizard';

export interface LanguageInfo<T> {
  data: T;
  sourceLanguage: {
    code: string;
    cms: undefined | string;
    default: string;
  };
  targetLanguageCodes: string[];
  task: {
    status?: 'pending' | 'error' | 'success';
    error?: {
      key: string;
      args?: object;
    };
  };
}

export const TaskCreatePageCrumb: Breadcrumb = {
  page: 'Overview.TaskCreatePage',
  icon: 'plus',
  location: '/tasks/new',
};

export const TaskCreatePage: React.FC = (): ReactElement => {
  const t: TFunction = useTranslation().t;
  const [method, setMethod] = useState<undefined | 'cms' | 'existing'>('cms');
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, JobsListPageCrumb, JobInfoPageCrumb, TaskCreatePageCrumb]}>
      <Box i18nKey={'Overview.TaskCreatePage.selectSourceStep'}>
        <div className="tile is-ancestor">
          <SelectionTile
            icon={'book'}
            type={'Overview.TaskCreatePage.sourceCMS'}
            onClick={(): void => setMethod('cms')}
            selected={method === 'cms'}
          />
          <SelectionTile
            icon={'database'}
            type={'Overview.TaskCreatePage.sourceAsset'}
            onClick={(): void => setMethod('existing')}
            selected={method === 'existing'}
          />
          <SelectionTile icon={'file'} type={'Overview.TaskCreatePage.sourceFiles'} disabled />
        </div>
        <span style={{ fontStyle: 'italic' }}>* {t('Overview.TaskCreatePage.notAvailable')}</span>
      </Box>

      {method === 'cms' ? <CMSWizard /> : method === 'existing' ? <ExistingAssetWizard /> : <></>}

      <div style={{ height: 200 }} />
    </AppLayout>
  );
};
