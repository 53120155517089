import { Page } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, usePaginatedLocHubQuery } from 'modules/api/locHub/entity/query/query';

import { LanguageDto } from '../../language/language';
import { Languages } from '../../languages';

export const usePaginatedLanguages = (
  page: number | undefined,
  configuration: QueryConfiguration<Page<LanguageDto> | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Page<LanguageDto>> => {
  configuration.query.enabled = page;
  return usePaginatedLocHubQuery(
    [Languages.path, { page }],
    async () => {
      if (!page) {
        throw new Error(`Unexpected missing page`);
      }
      return Api.locHub.languages.getMany(page);
    },
    configuration,
  );
};
