import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const AutomationEditPageI18n = {
  configuration: { namespace: I18nNamespace.AUTOMATION_EDIT_PAGE, labelKey: 'label' },
  translations: {
    automationUpdateSuccess: dictionary.automationUpdateSuccess,
    automationUpdateFailure: dictionary.automationUpdateFailure,
  },
};
