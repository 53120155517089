import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageInfo } from '../../../pages/Overview/TaskCreatePage/TaskCreatePage';
import { Table } from '../../Table/Table';

export interface Props<T> {
  i18nKey: string;
  languageInfo: LanguageInfo<T>[];
  render: (info: LanguageInfo<T>, index?: number) => ReactElement;
}

export function AssetOverviewTable<T>({ i18nKey, languageInfo, render }: Props<T>): ReactElement {
  const [t] = useTranslation();

  return (
    <Table>
      <thead className="asset-info header">
        <tr>
          <th>{t(`${i18nKey}.assetName`)}</th>
          <th>{t(`${i18nKey}.sourceLanguage`)}</th>
          <th>{t(`${i18nKey}.targetLanguages`)}</th>
        </tr>
      </thead>
      <tbody>{languageInfo.map((elem, index) => render(elem, index))}</tbody>
    </Table>
  );
}
