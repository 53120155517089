import { SelectOption } from 'components/Input/Select/Dropdown/Options/Option';
import { EntityName } from 'modules/api/locHub/entity/name/name';
import { LanguageDto } from 'modules/api/locHub/languages/language/language';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { SourceLanguageSelect } from '../../Common/SourceLanguage/SourceLanguage';
import { SourceLanguageFilterSelect } from '../../Common/SourceLanguageFilter/SourceLanguageFilter';

interface Props {
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
  languages: LanguageDto[];
  invalidOptions: ReadonlySet<IETFLanguageCode>;
  translationScopeSupport: boolean;
}

export const AutomationSourceLanguageSelect: React.FC<Props> = ({
  languages,
  control,
  errors,
  invalidOptions,
  translationScopeSupport,
}): ReactElement => {
  const options: Map<IETFLanguageCode, SelectOption> = new Map();
  for (const language of languages) {
    if (invalidOptions.has(language.code)) {
      continue;
    }
    options.set(language.code, { id: language.code, label: language.name });
  }
  if (translationScopeSupport) {
    return (
      <SourceLanguageFilterSelect
        entity={EntityName.AUTOMATION}
        options={options}
        control={control}
        required={false}
        errors={errors}
      />
    );
  } else {
    return (
      <SourceLanguageSelect
        entity={EntityName.AUTOMATION}
        options={options}
        control={control}
        required={true}
        errors={errors}
      />
    );
  }
};
