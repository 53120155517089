import { Page } from 'common/interfaces/common';
import { SelectOption } from 'components/Input/Select/Dropdown/Options/Option';
import { MultipleSelect, MultipleSelectActions } from 'components/Input/Select/MultipleSelect/MultipleSelect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAsyncState } from '../../common/hooks/useAsyncState';
import { Api } from '../../modules/api/api';
import { LanguageDto } from '../../modules/api/locHub/languages/language/language';
import { Option, SelectField } from '../Input/SelectField';

export interface Props {
  i18nKey: string;
  source?: string;
  errors?: unknown;
  targets: string[];
  onSourceChange: (source: string) => void;
  onTargetsChange: (targets: string[]) => void;
}

export const LanguageSettings: React.FC<Props> = ({
  i18nKey,
  source,
  targets,
  errors,
  onSourceChange,
  onTargetsChange,
}) => {
  const [t] = useTranslation();
  const [sourceOptionsState, setSourceOptionsState] = useState<Option<string>[]>([]);
  const [targetOptionsState, setTargetOptionsState] = useState<Map<string, SelectOption>>(new Map());
  const [languages] = useAsyncState(async () => {
    const languagesPage: Page<LanguageDto> = await Api.locHub.languages.getMany(1, { limit: 1000 });
    return languagesPage.content;
  });

  /* Effects */
  useEffect(() => {
    if (languages) {
      const targetOptions: Map<string, SelectOption> = new Map();
      for (const language of languages) {
        if (source !== language.code) {
          targetOptions.set(language.code, {
            id: language.code,
            label: language.name ? language.name : language.code,
          });
        }
      }
      setTargetOptionsState(targetOptions);
    }
  }, [source, languages]);

  useEffect(() => {
    if (languages) {
      const selectedTargets: Set<string> = new Set(targets);
      const sourceOptions: Option<string>[] = [];
      for (const language of languages) {
        if (!selectedTargets.has(language.code)) {
          sourceOptions.push({
            value: language.code,
            label: language.name ? language.name : language.code,
          });
        }
      }
      setSourceOptionsState(sourceOptions);
    }
  }, [targets, languages]);

  /* Actions */
  const sourceLanguageUpdated = (id: string): void => {
    onSourceChange(id);
  };
  const targetLanguagesActions: MultipleSelectActions = {
    valuesUpdated: (ids: Set<string>) => {
      onTargetsChange(Array.from(ids));
    },
  };

  return (
    <React.Fragment>
      <div className="language-settings">
        <div className="columns is-desktop">
          <div className="column">
            <SelectField
              i18nKey={`${i18nKey}.sourceLanguageField`}
              options={sourceOptionsState}
              onChange={sourceLanguageUpdated}
              value={source}
              name="source"
              errors={errors}
              required
            />
          </div>
          {/* ${i18nKey}.targetLanguagesField */}
          <div className="column is-multiselect">
            <div>
              <MultipleSelect
                texts={{
                  controls: {
                    deselectAll: t(`${i18nKey}.targetLanguagesField.deselectAll`),
                    selectAll: t(`${i18nKey}.targetLanguagesField.selectAll`),
                    save: t(`${i18nKey}.targetLanguagesField.save`),
                  },
                  filter: {
                    placeholder: t(`${i18nKey}.targetLanguagesField.filter`),
                  },
                  input: {
                    label: t(`${i18nKey}.targetLanguagesField.label`),
                    placeholder: t(`${i18nKey}.targetLanguagesField.label`),
                  },
                  options: {
                    noOptions: t(`${i18nKey}.targetLanguagesField.noOptions`),
                  },
                }}
                icon="fa-globe"
                name="targetLanguages"
                options={targetOptionsState}
                values={new Set(targets)}
                actions={targetLanguagesActions}
                required={true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
