import { useApiError } from 'common/hooks/useApiError';
import { Api } from 'modules/api/api';
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useAsyncState } from '../../common/hooks/useAsyncState';
import { ApiError } from '../../common/interfaces/common';
import { CmsConfiguration, XillioEntity } from '../../common/interfaces/xillio';
import { Translatable } from '../../common/language';
import { classNames } from '../../common/utils/classNames';
import { getEntityName } from '../EntityBrowser/utilities';
import { useMessage } from '../Message/MessageProvider';
import { ConfigurationSelect, getPrettyConfigurationName } from './ConfigurationSelect';
import { FileHeader, FileRow } from './FileRow';
import { EntityID, equal, getEntityId } from './utilities';

export interface Props extends Translatable {
  selection: XillioEntity[];
  setSelection: (value: XillioEntity[]) => void;
  preSelectedConfiguration?: CmsConfiguration;
}

export const FileBrowser: React.FC<Props> = ({
  i18nKey,
  selection,
  setSelection,
  preSelectedConfiguration,
}): ReactElement => {
  const [configurations] = useAsyncState<CmsConfiguration[]>(() => Api.locHub.xillioApi.getConfigurations());
  const [selectedConfiguration, selectConfiguration] = useState<undefined | CmsConfiguration>();

  const pushSelection = (selectedEntities: XillioEntity[]): void => {
    const result = new Map();
    for (const entity of selection) {
      result.set(`${entity.configurationId}${entity.path}`, entity);
    }
    for (const entity of selectedEntities) {
      result.set(`${entity.configurationId}${entity.path}`, entity);
    }
    setSelection(Array.from(result.values()));
  };
  const popSelection = (deletedEntities: XillioEntity[]): void => {
    const result = new Map();
    for (const entity of selection) {
      result.set(`${entity.configurationId}${entity.path}`, entity);
    }
    for (const entity of deletedEntities) {
      result.delete(`${entity.configurationId}${entity.path}`);
    }
    setSelection(Array.from(result.values()));
  };

  useEffect(() => {
    // Always select the first configuration available.
    if (configurations && configurations?.length > 0) {
      if (preSelectedConfiguration) {
        selectConfiguration(preSelectedConfiguration);
      } else {
        selectConfiguration(configurations[0]);
      }
    }
  }, [configurations, preSelectedConfiguration]);

  return (
    <>
      {!preSelectedConfiguration && (
        <ConfigurationSelect
          configurations={configurations || []}
          selected={selectedConfiguration}
          setSelected={selectConfiguration}
        />
      )}
      <FolderView
        i18nKey={i18nKey}
        configuration={selectedConfiguration}
        selection={selection || []}
        pushSelection={pushSelection}
        popSelection={popSelection}
      />
    </>
  );
};

const FolderView: React.FC<Translatable & {
  configuration: undefined | CmsConfiguration;
  selection: EntityID[];
  pushSelection: (id: XillioEntity[]) => void;
  popSelection: (id: XillioEntity[]) => void;
}> = ({ i18nKey, configuration, selection, pushSelection, popSelection }): ReactElement => {
  const message = useMessage();
  const apiError = useApiError();

  const [breadCrumbs, setBreadCrumbs] = useState<XillioEntity[]>([]);
  const [entities, setEntities] = useState<undefined | XillioEntity[]>(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const loadEntities: (parent: undefined | XillioEntity, back?: boolean) => Promise<void> = useCallback(
    async (parent, back) => {
      if (!configuration) {
        // Nothing to do without a configuration
        return;
      }

      setLoading(true);
      try {
        const es = await Api.locHub.xillioApi.entities.get(configuration.id, parent ? parent.path : '/');
        if (es.length !== 0) {
          // We've found some entities, so lets show them
          setEntities(es);
          if (parent && !back) {
            // If we went forward we can append the new parent to the breadcrumbs
            setBreadCrumbs(old => [...old, parent]);
          }
        } else {
          if (parent) {
            // If we don't have entities, but did ask to traverse into a parent,
            // we toggle the selection instead
            const isSelected = !!selection.find(e => equal(e, parent));
            if (isSelected) popSelection([parent]);
            else pushSelection([parent]);
          }
        }
        setLoading(false);
      } catch (error) {
        apiError.locHub.handle(error, error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuration, pushSelection, popSelection, selection],
  );

  useEffect(() => {
    // Do an initial load once created.
    if (entities === undefined && !loading) {
      loadEntities(undefined).catch((e: ApiError) => {
        // The call failed, present it to the user and reset the view.
        message.error(e.message);
        setEntities([]);
        setLoading(false);
      });
    }
  }, [loadEntities, loading, message, entities]);

  useEffect(
    () => {
      // If we change from configurations, we need to get new entities
      setEntities(undefined);
      setBreadCrumbs([]);
      if (loading) {
        loadEntities(undefined).catch((e: ApiError) => {
          // The call failed, present it to the user and reset the view.
          message.error(e.message);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuration],
  );

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.cursor = loading ? 'progress' : 'auto';
  }, [loading]);

  // Are all entities on this page selected?
  const allSelected: boolean = (entities && entities.every(e => !!selection.find(entity => equal(entity, e)))) || false;

  return (
    <>
      {configuration && (
        <>
          <Crumbs
            configuration={configuration}
            breadCrumbs={breadCrumbs}
            setBreadCrumbs={setBreadCrumbs}
            loadEntities={loadEntities}
            isLoading={loading}
          />
          <div className="fb">
            <FileHeader
              i18nKey={i18nKey}
              selected={allSelected}
              toggleSelection={(): void => {
                if (allSelected) popSelection(entities || []);
                else pushSelection(entities || []);
              }}
            />
            {entities &&
              entities.map(entity => {
                const isSelected = !!selection.find(e => equal(e, entity));
                return (
                  <FileRow
                    key={getEntityId(entity)}
                    i18nKey={i18nKey}
                    entity={entity}
                    setParent={(): Promise<void> => {
                      return loadEntities(entity).catch(e => {
                        // The call failed, present it to the user and reset the view.
                        apiError.locHub.handle(e, e.message);
                        setLoading(false);
                      });
                    }}
                    selected={isSelected}
                    toggleSelection={(): void => {
                      if (isSelected) popSelection([entity]);
                      else pushSelection([entity]);
                    }}
                  />
                );
              })}
          </div>
        </>
      )}
    </>
  );
};

const Crumbs: React.FC<{
  configuration: CmsConfiguration;
  breadCrumbs: XillioEntity[];
  setBreadCrumbs: Dispatch<SetStateAction<XillioEntity[]>>;
  loadEntities: (parent: undefined | XillioEntity, back?: boolean) => void;
  isLoading?: boolean;
}> = ({ configuration, breadCrumbs, setBreadCrumbs, loadEntities, isLoading = false }) => (
  <div className="breadcrumbs">
    {[getPrettyConfigurationName(configuration), ...breadCrumbs.map(getEntityName)].map((crumb, index) => (
      <span key={crumb}>
        <span
          className={classNames('breadcrumbs-name', 'is-size-4', index !== breadCrumbs.length && 'is-clickable')}
          onClick={(): void => {
            if (index !== breadCrumbs.length) {
              setBreadCrumbs(old => old.slice(0, index));
              return loadEntities(index === 0 ? undefined : breadCrumbs[index - 1], true);
            }
          }}
        >
          {crumb}
          {isLoading && index === breadCrumbs.length && <i className="fas fa-spinner fa-spin" />}
        </span>
        {index < breadCrumbs.length && <span className="breadcrumbs-divider">&gt;</span>}
      </span>
    ))}
  </div>
);
