import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { useLocHubDtoQuery } from '../../../entity/query/detail/detail';
import { UserId } from '../../user/id/id';
import { UserDto } from '../../user/user';

export const useUserDto = (
  id: UserId | undefined,
  configuration?: QueryConfiguration<UserDto | undefined, unknown>,
): QueryResult<UserDto> => {
  return useLocHubDtoQuery(Api.locHub.users, id, configuration);
};
