import { Page, Pagination } from 'common/interfaces/common';
import { Extended } from 'common/types/extended/extended';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { QueryBuilder } from '../entity/query/builder/builder';
import { EntityRelation } from '../entity/relation/relation';
import { LocHub } from '../lochub';
import { ProjectId } from '../projects/project/id/id';
import { ProjectDto } from '../projects/project/project';
import { JobId } from './job/id/id';
import { JobDto, NewJob } from './job/job';

export type JobDtoWithProject = Extended<JobDto, { project: ProjectDto }>;

export class Jobs {
  public static readonly path: string = '/api/internal/jobs';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Jobs.path, parameters);
  }

  private static getDetailUrl(id: JobId): URL {
    return Api.getUrl(`${Jobs.path}/${id}`);
  }

  private static getArchiveUrl(id: JobId): URL {
    return Api.getUrl(`/api/internal/archive/job/${id}`);
  }

  public static async getAll(): Promise<ProjectDto[]> {
    return LocHub.getAll(Jobs.path);
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<JobDto>> {
    return LocHub.getJson(Jobs.getBaseUrl({ page, ...options }));
  }

  public static async getManyWithProject(page: number, options?: Pagination): Promise<Page<JobDtoWithProject>> {
    const jobs = await Jobs.getMany(page, options);
    const result: Page<JobDtoWithProject> = {
      content: [],
      page: jobs.page,
      pageSize: jobs.pageSize,
      totalElements: jobs.totalElements,
    };
    for (const job of jobs.content) {
      const project = await Api.locHub.projects.getDto(job.projectId);
      result.content.push({ ...job, project });
    }
    return result;
  }

  public static async getManyByProject(
    projectId: ProjectId,
    page: number,
    options?: Pagination,
  ): Promise<Page<JobDto>> {
    return LocHub.getJson(
      Jobs.getBaseUrl({
        page,
        ...options,
        query: new QueryBuilder().addRelation(EntityRelation.PROJECT, projectId).build(),
      }),
    );
  }

  public static async getDto(jobId: JobId): Promise<JobDto> {
    return LocHub.getJson(Jobs.getDetailUrl(jobId));
  }

  public static async getDtoWithProject(jobId: JobId): Promise<Extended<JobDto, { project: ProjectDto }>> {
    const job = await Jobs.getDto(jobId);
    const project = await Api.locHub.projects.getDto(job.projectId);
    return {
      ...job,
      project,
    };
  }

  public static async create(job: NewJob): Promise<JobDto> {
    const result = await LocHub.postJson<NewJob, JobDto>(Jobs.getBaseUrl(), job);
    queryCache.invalidateQueries(Jobs.path);
    return result;
  }

  public static async update(jobId: JobId, project: Partial<JobDto>): Promise<JobDto> {
    const result = await LocHub.patchJson(Jobs.getDetailUrl(jobId), project);
    queryCache.invalidateQueries(Jobs.path);
    return result;
  }

  public static async archive(projectId: ProjectId): Promise<void> {
    const result = await LocHub.post(Jobs.getArchiveUrl(projectId));
    queryCache.invalidateQueries(Jobs.path);
    return result;
  }

  public static async delete(jobId: JobId): Promise<void> {
    await LocHub.delete(Jobs.getDetailUrl(jobId));
    queryCache.invalidateQueries(Jobs.path);
  }
}
