import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CancelInputI18n } from './I18n';

interface Props {
  to: string;
}

export const CancelInput: React.FC<Props> = ({ to }): ReactElement => {
  const [t] = useTranslation();
  const cancelButtonText: string = t(`${CancelInputI18n.configuration.namespace}.label`);
  return (
    <Link to={to}>
      <input type="button" id="cancel-button" className={classNames('button')} value={cancelButtonText} />
    </Link>
  );
};
