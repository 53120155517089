import React, { PropsWithChildren, ReactElement, ReactNode, createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';

import { Message, MessageType } from './Message';

const DEFAULT_DURATION = 4500;

export interface MessageProviderContextProps {
  addMessage: (content: Content, config?: Config) => void;
  success: (content: Content, config?: Config) => void;
  error: (content: Content, config?: Config) => void;
  info: (content: Content, config?: Config) => void;
  warning: (content: Content, config?: Config) => void;
  loading: (content: Content, config?: Config) => void;
}

export interface MessageInfo {
  id?: string;
  content: string | ReactNode;
  type: MessageType;
  duration?: number;
  onClose?: () => void;
}

type Content = string | ReactNode;
type Config = { duration?: number; onClose?: () => void; type?: MessageType; id?: string };

const Ctx = createContext<MessageProviderContextProps | null>(null);

function MessageProvider({ children }: PropsWithChildren<unknown>): ReactElement {
  const [t] = useTranslation();
  const [messages, setMessages] = useState<({ created: number } & MessageInfo)[]>([]);
  const copyToClipboard = useCopyToClipboard()[1];

  const addMessage = (content: Content, config: Config): void => {
    const type = config.type || 'normal';
    const duration = config.duration || DEFAULT_DURATION;
    const created = Date.now();

    const message = { content, ...config, type, duration, created };

    setMessages(old => {
      return [...old, message];
    });
    if (duration > 0) {
      setTimeout(() => removeMessage(message), duration);
    }
  };

  const removeMessage = (message: { created: number } & MessageInfo): void => {
    if (message.onClose) {
      message.onClose();
    }
    setMessages(old => {
      return old.filter(m => m.created !== message.created);
    });
  };

  const contextValue: MessageProviderContextProps = {
    addMessage: (content, config) => addMessage(content, { ...config }),
    success: (content, config) => addMessage(content, { ...config, type: 'success' }),
    error: (content, config) => addMessage(content, { ...config, type: 'error', duration: 115000 }),
    info: (content, config) => addMessage(content, { ...config, type: 'info' }),
    warning: (content, config) => addMessage(content, { ...config, type: 'warning' }),
    loading: (content, config) => addMessage(content, { ...config, type: 'loading' }),
  };

  return (
    <Ctx.Provider value={contextValue}>
      {children}
      <div className="messages">
        {messages.map((message, index) => (
          <Message
            {...message}
            key={message.created + (typeof message.content === 'string' ? message.content[0] : '')}
            onClose={(): void => removeMessage(message)}
            onCopyErrorId={
              message.type === 'error' && message.id
                ? (): void => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    copyToClipboard(message.id!);
                    removeMessage(message);
                    contextValue.success(t('notification.errorIdInClipboard'));
                  }
                : undefined
            }
          />
        ))}
      </div>
    </Ctx.Provider>
  );
}

export function useMessage(): MessageProviderContextProps {
  const context = useContext(Ctx);

  if (!context) {
    throw new Error('`useMessage` cannot be used outside of a `MessageProvider`-element');
  }
  return context;
}

export default MessageProvider;
