import { useApiError } from 'common/hooks/useApiError';
import { ValidationErrors, validateEmail } from 'common/utils/validation';
import { AppLayout } from 'components/AppLayout/AppLayout';
import { useUserDto } from 'modules/api/locHub/users/query/detail/detail';
import { UserId } from 'modules/api/locHub/users/user/id/id';
import { Field } from 'modules/form/field/field';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useBoolean } from 'react-hanger';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { getChanges } from '../../../common/utils/getChanges';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Button } from '../../../components/Button/Button';
import { ConfirmButton } from '../../../components/ConfirmButton/ConfirmButton';
import { Form } from '../../../components/Form/Form';
import { Email, Name } from '../../../components/Input';
import { Language } from '../../../components/Input/SelectFields';
import { Api } from '../../../modules/api/api';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { UserListPageCrumb } from '../UserListPage/UserListPage';

export const UserEditPageCrumb: Breadcrumb = {
  page: 'Administration.UserEditPage',
  icon: 'user-edit',
  location: '/admin/users/:userId',
};

export const UserEditPage: React.FC = (): ReactElement => {
  const apiError = useApiError();
  const history = useHistory();
  const [t] = useTranslation();
  const currentUserId = (Api.getUserDataFromCache() || { id: null }).id;
  const initialized = useBoolean(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(false);
  const [preferredLanguage, setPreferredLanguage] = useState('en');
  const userId = useParams<{ userId: UserId }>().userId;
  const user = useUserDto(userId, {
    error: {
      handler: (requestError: Error): void => {
        history.push('/admin/users');
        apiError.locHub.handle(requestError, t('Administration.UserEditPage.errorNotFound', { userId: userId }));
      },
    },
    query: {
      onSuccess: (result): void => {
        if (result) {
          setActive(result.active);
          if (!initialized.value) {
            setName(result.name);
            setEmail(result.email);
            setPreferredLanguage(result.preferredLanguage);
            initialized.setTrue();
          }
        }
      },
    },
  });

  const changes = user.success
    ? getChanges(
        {
          name,
          email,
          preferredLanguage,
        },
        user.data,
      )
    : {};

  const [error, setError] = useState<ValidationErrors | undefined>();

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, UserListPageCrumb, UserEditPageCrumb]}>
      <Box i18nKey={UserEditPageCrumb.page}>
        <Form
          i18nKey="Administration.UserEditPage"
          cancelTo="/admin/users"
          disabled={
            !active ||
            Object.keys(changes).length === 0 ||
            Field.isEmpty(name) ||
            Field.isEmpty(email) ||
            Field.isEmpty(preferredLanguage) ||
            Boolean(error)
          }
          error={error}
          onSubmit={async (): Promise<void> => {
            try {
              const newUser = await Api.locHub.users.update(userId, changes);
              history.push('/admin/users');
              if (currentUserId === newUser.id) {
                // reload the application to change the language
                window.location.reload();
              }
            } catch (e) {
              if (e.body.message === 'A user with the provided email address already exists') {
                setError({
                  errors: [{ field: 'email', codes: ['Unique.email'] }, ...(error?.errors || [])],
                });
              } else {
                setError(await e.body);
              }
            }
          }}
        >
          <Name
            i18nKey="Administration.UserEditPage.nameField"
            value={name}
            onChange={setName}
            errors={error}
            required
            disabled={!active}
          />
          <Email
            i18nKey="Administration.UserEditPage.emailField"
            value={email}
            onChange={setEmail}
            validate={(event: ChangeEvent<HTMLInputElement>): void => {
              validateEmail(event, error, setError);
            }}
            errors={error}
            required
            disabled={!active}
          />
          <Language
            i18nKey="Administration.UserEditPage.languageField"
            value={preferredLanguage}
            onChange={setPreferredLanguage}
            errors={error}
            disabled={!active}
          />
        </Form>
      </Box>
      <Box i18nKey="Administration.UserEditPageDangerZone">
        {active ? (
          <ConfirmButton
            i18nKey="Administration.UserEditPageDangerZone.disableButton"
            className="is-info"
            disabled={currentUserId === userId}
            onConfirm={async (): Promise<void> => {
              await Api.locHub.users.update(userId, { active: false });
              setActive(false);
            }}
          />
        ) : (
          <Button
            i18nKey="Administration.UserEditPageDangerZone.enableButton"
            className="is-success"
            onClick={async (): Promise<void> => {
              await Api.locHub.users.update(userId, { active: true });
              setActive(true);
            }}
          />
        )}
      </Box>
    </AppLayout>
  );
};
