import './style.scss';

import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ModalProvider } from 'react-modal-hook';
import { HashRouter as Router } from 'react-router-dom';

import { App } from './App';
import i18n from './common/language';
import MessageProvider from './components/Message/MessageProvider';

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<LoadingPage />}>
        <MessageProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </MessageProvider>
      </Suspense>
    </I18nextProvider>
  </Router>,
  document.getElementById('root'),
);
