import { Button } from 'components/Button/Button';
import { TaskStatus as TaskStatusComponent } from 'components/Task/TaskStatus/TaskStatus';
import { Job } from 'modules/api/locHub/jobs/job/job';
import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import { TaskError } from 'modules/api/locHub/tasks/task/error/error';
import { TaskId } from 'modules/api/locHub/tasks/task/id/id';
import { TaskStatus } from 'modules/api/locHub/tasks/task/status/status';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../common/language';
import { LanguageTag } from '../../../components/LanguageTag/LanguageTag';
import { TD } from '../../../components/TD/TD';
import { DropdownActions } from './DropdownActions/DropdownActions';

export interface Props extends Translatable {
  job: Job;
  taskId: TaskId;
  taskName: string;
  taskCreatedAt: Date;
  taskUpdatedAt: Date;
  taskTargetLanguage: IETFLanguageCode;
  taskStatus: TaskStatus;
  taskError: TaskError | null;
  taskIsDownloadingAssets: boolean;
  taskHasDownloadedAssets: boolean;
  taskIsReadyToBeConfirmed: boolean;
  taskIsTranslated: boolean;
  projectId: ProjectId;
  selected: boolean;
  actions: Actions;
}

export interface Actions {
  toggleSelected: (id: TaskId, withShift: boolean) => void;
  setTaskStatus: (id: TaskId, status: TaskStatus) => void;
  setTaskError: (id: TaskId, error: TaskError) => void;
}

export const TaskDetailRow: React.FC<Props> = ({
  i18nKey,
  job,
  taskId,
  taskName,
  taskCreatedAt,
  taskUpdatedAt,
  taskTargetLanguage,
  taskStatus,
  taskError,
  taskHasDownloadedAssets,
  taskIsDownloadingAssets,
  taskIsReadyToBeConfirmed,
  taskIsTranslated,
  selected,
  actions: { toggleSelected, setTaskStatus, setTaskError },
}): ReactElement => {
  const [t] = useTranslation();

  return (
    <tr
      onClick={(e): void => {
        e.stopPropagation();
        toggleSelected(taskId, e.shiftKey);
      }}
    >
      {
        <TD>
          <input
            onChange={(): void => {
              /* no-op */
            }}
            type="checkbox"
            checked={selected}
          />
        </TD>
      }
      <TD wrap>{taskName}</TD>
      <TD>
        <LanguageTag language={taskTargetLanguage} />
      </TD>
      <TD>{t('locale.date', { date: new Date(taskCreatedAt) })}</TD>
      <TD>{t('locale.fromNow', { date: new Date(taskUpdatedAt) })}</TD>
      <TD>
        <TaskStatusComponent taskStatus={taskStatus} taskError={taskError} />
      </TD>
      <TD>
        {taskHasDownloadedAssets && (
          <div className="field has-addons is-pulled-right">
            <span className="control">
              <Button to={`/tasks/${taskId}`} i18nKey="Overview.JobInfoPage.viewTaskButton">
                <span className="icon is-small">
                  <i className="fas fa-info" />
                </span>
              </Button>
            </span>
            <span className="control">
              <DropdownActions
                i18nKey={i18nKey}
                job={job}
                taskId={taskId}
                taskError={taskError}
                taskIsDownloadingAssets={taskIsDownloadingAssets}
                taskIsReadyToBeConfirmed={taskIsReadyToBeConfirmed}
                taskIsTranslated={taskIsTranslated}
                actions={{ setTaskStatus, setTaskError }}
              />
            </span>
          </div>
        )}
      </TD>
    </tr>
  );
};
