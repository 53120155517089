import React from 'react';

import { classNames } from '../../common/utils/classNames';
import { HtmlProps } from '../../common/utils/HTMLProps';
import { LanguageDto } from '../../modules/api/locHub/languages/language/language';

export const LanguageTag: React.FC<{
  language: string | LanguageDto;
  color?: 'is-info' | 'is-warning' | 'is-danger';
} & HtmlProps> = ({ language, color = 'is-info' }) => {
  let code = '';
  if (typeof language === 'string') {
    code = language;
  } else if ('code' in language) {
    code = language.code;
  }

  return (
    <span className={classNames('tag', 'is-light', color)} key={code}>
      {code}
    </span>
  );
};
