function isTruthy(value: unknown): boolean {
  return !!value;
}

export function queryString(value: string): { [name: string]: string } {
  const result: { [name: string]: string } = {};
  value
    .substr(1) // drop the leading ?
    .split('&') // split the parameters
    .filter(isTruthy) // we don't care about empty values
    .map((entry): string[] => entry.split('=')) // split key from value
    .forEach(([name, ...tail]): void => {
      result[name] = tail.join('=');
    });
  return result;
}
