import { TFunction } from 'i18next';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { useJobTasks } from 'modules/api/locHub/tasks/query/listByJob/listByJob';
import { ComputedStatus, TapiccStatus } from 'modules/api/locHub/tasks/task/status/status';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { HtmlProps } from '../../common/utils/HTMLProps';

export interface Props extends HtmlProps {
  jobId: JobId;
  i18nKey: string;
}

export function calculateStatus(tasks: Task[], t: TFunction, i18nKey: string): React.ReactElement | null {
  const numberTasks = tasks.length;
  const tasksCompleted = tasks.filter(task => task.status === ComputedStatus.CLOSED).length || 0;
  const tasksTranslated = tasks.filter(task => task.status === TapiccStatus.COMPLETED).length || 0;
  const tasksNew = tasks.filter(task => task.status === TapiccStatus.PENDING).length || 0;
  const tasksCancelled = tasks.filter(task => task.status === TapiccStatus.CANCELLED).length || 0;

  if (numberTasks === 0) {
    return null;
  }
  if (numberTasks === tasksCancelled) {
    return (
      <>
        <i className={'fa fa-circle has-text-light'} />
        <span>{t(i18nKey + '.jobStatus.cancelled')}</span>
      </>
    );
  } else if (numberTasks === tasksCompleted + tasksCancelled) {
    return (
      <>
        <i className={'fa fa-circle has-text-success'} />
        <span>{t(i18nKey + '.jobStatus.completed')}</span>
      </>
    );
  } else if (numberTasks === tasksNew + tasksCancelled) {
    return (
      <>
        <i className={'fa fa-circle has-text-info'} />
        <span>{t(i18nKey + '.jobStatus.new')}</span>
      </>
    );
  } else if (numberTasks === tasksCompleted + tasksTranslated + tasksCancelled) {
    return (
      <>
        <i className={'fa fa-circle has-text-primary'} />
        <span>{t(i18nKey + '.jobStatus.translated')}</span>
      </>
    );
  } else {
    return (
      <>
        <i className={'fa fa-circle has-text-warning'} />
        <span>{t(i18nKey + '.jobStatus.inProgress')}</span>
      </>
    );
  }
}

export const JobStatus: React.FC<Props> = ({ jobId, i18nKey }): ReactElement => {
  const [t] = useTranslation();
  const tasks = useJobTasks(jobId);
  if (!tasks.success) {
    return <></>;
  }
  return <div className={'job-status'}>{calculateStatus(tasks.data, t, i18nKey)}</div>;
};
