import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, usePaginatedLocHubQuery } from 'modules/api/locHub/entity/query/query';

import { ProjectDto } from '../../project/project';
import { Projects } from '../../projects';

export const usePaginatedProjects = (
  page: number | undefined,
  options: Pagination = {},
  configuration: QueryConfiguration<Page<ProjectDto> | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Page<ProjectDto>> => {
  configuration.query.enabled = page;
  return usePaginatedLocHubQuery(
    [Projects.path, { page, options }],
    async () => {
      if (!page) {
        throw new Error(`Unexpected missing page`);
      }
      return Api.locHub.projects.getMany(page, options);
    },
    configuration,
  );
};
