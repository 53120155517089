import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';

export interface Props {
  label: string;
  leftIcon?: string;
  rightIcon?: string;
  rightIconAction?: () => void;
  name: string;
  error?: string;
  required?: boolean;
}
export const FormField: React.FC<Props> = ({
  leftIcon,
  rightIcon,
  rightIconAction,
  name,
  label,
  children,
  error,
  required,
}): ReactElement => {
  return (
    <div className={classNames('field', required && 'is-required')}>
      <label className="label" htmlFor={`${name}-input`}>
        {label}
      </label>
      <div
        className={classNames(
          'control',
          leftIcon && 'has-icons-left',
          rightIcon && 'has-icons-right',
          error && 'is-danger',
        )}
      >
        {children}
        <span className="icon is-left">
          <i className={`fas ${leftIcon}`} />
        </span>
        {rightIconAction ? (
          <span
            style={{ pointerEvents: 'initial', cursor: 'pointer' }}
            className="icon is-right"
            onClick={rightIconAction}
          >
            <i className={`fas ${rightIcon}`} />
          </span>
        ) : (
          <span className="icon is-right">
            <i className={`fas ${rightIcon}`} />
          </span>
        )}
      </div>
      <p className="help is-danger">{error}</p>
    </div>
  );
};
