export type TaskStatus = TapiccStatus | ComputedStatus;

export enum ComputedStatus {
  /**
   * FRONTEND FINAL STATE
   * Content was uploaded back to the repository
   */
  CLOSED = 'closed',
  /**
   * Assets are being downloaded from Xillio Engine
   */
  DOWNLOAD_IN_PROGRESS = 'download-in-progress',
  /**
   * Failed to download assets from Xillio Engine
   */
  DOWNLOAD_FAILED = 'download-failed',
}

export enum TapiccStatus {
  /**
   * Initial status
   *
   * Transitions:
   * PENDING => CONFIRMED
   */
  PENDING = 'pending',
  /**
   * Translation is ready to be picked up by LSP.
   *
   * Transitions:
   * CONFIRMED => PAUSED (put the task on hold)
   * CONFIRMED => CANCELLED (cancel task, for example if duplicated)
   * CONFIRMED => IN_PROGRESS (LSP accepted the task)
   * CONFIRMED => REJECTED (LSP rejected the task)
   */
  CONFIRMED = 'confirmed',
  /**
   * LSP accepted the task
   *
   * Transitions:
   * IN_PROGRESS => COMPLETED (done)
   * IN_PROGRESS => COMPLETED_WITH_WARNINGS (done, but something went wrong)
   * IN_PROGRESS => REJECTED (LSP rejected the task)
   */
  IN_PROGRESS = 'in-progress',
  /**
   * Task is on hold
   *
   * Transitions:
   * PAUSED => CONFIRMED
   */
  PAUSED = 'paused',
  /**
   * FINAL STATE
   * Cancelled task (eg. duplicated task)
   */
  CANCELLED = 'cancelled',
  /**
   * SEMI-FINAL STATE - there will be new ACCEPTED state introduced in the future
   * Done
   */
  COMPLETED = 'completed',
  /**
   * SEMI-FINAL STATE - there will be new ACCEPTED state introduced in the future
   * Done, but something went wrong (eg. missing translation context)
   */
  COMPLETED_WITH_WARNINGS = 'completed-with-warnings',
  /**
   * FINAL STATE
   * Something went wrong (eg. broken connection)
   */
  FAILED = 'failed',
  /**
   * FINAL STATE
   * LSP rejected the task (eg. no time capacities or missing important stuff)
   */
  REJECTED = 'rejected',
}
