import { Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, useLocHubQuery } from 'modules/api/locHub/entity/query/query';

import { AssetDto } from '../../asset/asset';

export const useAssetsSortedBy = (
  options: Pagination,
  configuration: QueryConfiguration<AssetDto[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<AssetDto[]> => {
  return useLocHubQuery<AssetDto[]>(
    [Api.locHub.assets.path],
    async () => {
      return Api.locHub.assets.getAll(options);
    },
    configuration,
  );
};
