import React, { ReactElement } from 'react';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Box } from '../../../components/Box/Box';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { HomePageCrumb } from '../../Overview/HomePage/HomePage';
import { AdminPageCrumb } from '../AdminPage/AdminPage';
import { AutomationListPageCrumb } from '../AutomationListPage/AutomationListPage';
import { AutomationCreateForm } from './AutomationCreateForm';
import { AutomationCreatePageI18n } from './I18n';

export const AutomationCreatePageCrumb: Breadcrumb = {
  page: AutomationCreatePageI18n.configuration.namespace,
  icon: 'plus',
  location: '/admin/automations/new',
};

export const AutomationCreatePage: React.FC = (): ReactElement => {
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, AutomationListPageCrumb, AutomationCreatePageCrumb]}>
      <Box i18nKey={AutomationCreatePageCrumb.page}>
        <AutomationCreateForm />
      </Box>
    </AppLayout>
  );
};
