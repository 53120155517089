import { Page } from 'common/interfaces/common';
import { convertObjectToQueryString } from 'common/utils/convertObjectToQueryString';
import { queryString } from 'common/utils/queryString';
import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { Toggle } from 'components/Toggle/Toggle';
import React, { Fragment, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { useAsyncState } from '../../common/hooks/useAsyncState';

export interface Props<T> {
  getData?: (page: number) => Promise<Page<T>>;
  setPage?: (page: number) => void;
  data?: Page<T>;
  columns: string[];
  render: (row: T) => ReactElement;
  i18nKey: string;
  archiveToggleText?: string;
  setArchiveToggle?: (changeQuery: string) => void;
}

export function DataTable<T extends { id: number | string }>({
  data,
  getData,
  setPage,
  columns,
  render,
  i18nKey,
  archiveToggleText,
  setArchiveToggle,
}: PropsWithChildren<Props<T>>): ReactElement {
  const [currentPage, setCurrentPage] = useState<number>();
  const [page] = useAsyncState(async () => {
    if (!currentPage) {
      // we have no page yet
      return undefined;
    }
    if (data) {
      return data;
    }
    if (getData) {
      return getData(currentPage);
    }
    return undefined;
  }, [currentPage, data]);

  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [toggle, setToggle] = useState<boolean>(false);

  // keep the currentPage in sync with the 'page' query parameter
  useEffect((): void => {
    if (currentPage === undefined) {
      const pageNumber = parseInt(queryString(location.search).page || '1', 10);
      if (setPage) {
        setPage(pageNumber);
      }
      setCurrentPage(pageNumber);
    } else {
      history.replace(`${location.pathname}?page=${currentPage}`);
    }
  }, [currentPage, location.search, location.pathname, history, setPage]);

  let content = (
    <tbody>
      <tr>
        <td colSpan={columns.length} />
      </tr>
    </tbody>
  );

  if (page) {
    content = (
      <>
        <thead>
          <tr>
            {columns.map(
              (column): ReactElement => (
                <th key={column}>{t([`${i18nKey}.${column}`, column])}</th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {page.content.map(
            (row: T): ReactElement => (
              <Fragment key={row.id}>{render(row)}</Fragment>
            ),
          )}
        </tbody>
      </>
    );
  }

  return (
    <>
      {setArchiveToggle && (
        <Toggle
          id={`archive-toggle`}
          name={`archiveToggle`}
          texts={{ label: archiveToggleText ?? '' }}
          value={toggle}
          actions={{
            toggle: (): void => {
              setToggle(!toggle);
              if (setArchiveToggle) {
                if (!toggle) {
                  setArchiveToggle(convertObjectToQueryString({ archived: 'both' }));
                } else {
                  setArchiveToggle(convertObjectToQueryString({ archived: 'false' }));
                }
              }
            },
          }}
        />
      )}
      <div className="is-clearfix" />
      <div>
        <Table>{content}</Table>
        {currentPage && (
          <Pagination
            onChangePage={(pageNumber: number): void => {
              if (setPage) {
                setPage(pageNumber);
              }
              setCurrentPage(pageNumber);
            }}
            currentPage={currentPage}
            lastPage={page ? Math.ceil(page.totalElements / page.pageSize) : 0}
          />
        )}
      </div>
    </>
  );
}
