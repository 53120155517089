import React, { ReactElement } from 'react';

import { Controls, ControlsActions, ControlsTexts } from './Controls/Controls';
import { Options, OptionsActions, OptionsTexts } from './Options';
import { OptionActions, SelectOption } from './Options/Option';

export type DropdownActions = ControlsActions & OptionActions;

export interface DropdownTexts {
  controls: ControlsTexts;
  options: OptionsTexts;
}

export interface Props {
  readonly texts: DropdownTexts;
  readonly options: Map<string, SelectOption>;
  readonly selected: Set<string>;
  readonly filterBy: string;
  readonly actions: DropdownActions;
}

/* styles */
const scrollingStyles: React.CSSProperties = {
  maxHeight: '13em',
  overflow: 'auto',
};

const dropdownStyles: React.CSSProperties = {
  width: '100%',
};

const dropdownMenuStyles: React.CSSProperties = {
  width: '100%',
};

export const Dropdown: React.FC<Props> = ({ texts, options, selected, filterBy, actions }): ReactElement => {
  /* Actions */
  const controlsActions: ControlsActions = {
    selectAll: actions.selectAll,
    deselectAll: actions.deselectAll,
    close: actions.close,
  };
  const optionsActions: OptionsActions = {
    select: actions.select,
    deselect: actions.deselect,
  };
  return (
    <div className="dropdown is-active" style={dropdownStyles}>
      <div className="dropdown-trigger" />
      <div className="dropdown-menu" role="menu" style={dropdownMenuStyles}>
        <div className="dropdown-content is-paddingless">
          <Controls texts={texts.controls} options={options} filterBy={filterBy} actions={controlsActions} />
          <div style={scrollingStyles}>
            <Options
              texts={texts.options}
              options={options}
              selected={selected}
              filterBy={filterBy}
              actions={optionsActions}
            ></Options>
          </div>
        </div>
      </div>
    </div>
  );
};
