import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

export const NextButton: React.FC<{
  i18nKey: string;
  disabled: boolean;
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
}> = ({ i18nKey, disabled, index, setIndex }): ReactElement => {
  const [t] = useTranslation();
  return (
    <button
      className="button is-pulled-right is-success"
      disabled={disabled}
      onClick={(): void => setIndex(old => (old === index ? index + 1 : old))}
    >
      {t(`${i18nKey}.next`)}
    </button>
  );
};
