import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, usePaginatedLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { Projects } from 'modules/api/locHub/projects/projects';

import { JobDtoWithProject, Jobs } from '../../jobs';

export const usePaginatedJobsWithProject = (
  page: number | undefined,
  options: Pagination = {},
  configuration: QueryConfiguration<Page<JobDtoWithProject> | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Page<JobDtoWithProject>> => {
  configuration.query.enabled = page;
  return usePaginatedLocHubQuery(
    [Jobs.path, { page, options, with: Projects.path }],
    async () => {
      if (!page) {
        throw new Error(`Unexpected missing page`);
      }
      return Api.locHub.jobs.getManyWithProject(page, options);
    },
    configuration,
  );
};
