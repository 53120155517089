import { useOutsideClickEvent } from 'common/hooks/useMouseEvent';
import React, { useRef, useState } from 'react';

import { OptionsTexts } from '../Dropdown/Options';
import { SelectOption } from '../Dropdown/Options/Option';
import { Filter } from '../FilterInput';
import { Select, SelectActions } from '../Select';
import { SimpleInputTexts } from '../SimpleInput/SimpleInput';

export interface SingleSelectActions {
  valuesUpdated: (id: string | null) => void;
}

export interface SingleSelectTexts {
  controls: {
    clear: string;
    save: string;
  };
  options: OptionsTexts;
  filter: { placeholder: string };
  input: SimpleInputTexts;
}

interface Props {
  /* Used texts */
  readonly texts: SingleSelectTexts;
  /* Font awesome 5 icon string (eg. "fa-globe")  */
  readonly icon: string;
  /* Select input name property */
  readonly name: string;
  /* Options available to select */
  readonly options: Map<string, SelectOption>;
  /* Initially selected option */
  readonly value?: string;
  /* Validation error */
  readonly error?: string;
  /* Supported actions */
  readonly actions: SingleSelectActions;
  /* Required field */
  readonly required: boolean;
  /* Disabled field */
  readonly disabled?: boolean;
}

/* Styles */
const styles: React.CSSProperties = {
  flexGrow: 1,
  flexBasis: 0,
  width: '100%',
  height: '76px',
};

export const SingleSelect: React.FC<Props> = ({
  texts,
  icon,
  name,
  error,
  options,
  value,
  actions,
  required,
  disabled,
}) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<string | null>(value ? value : null);
  const [filter, setFilter] = useState<Filter>({ active: false, by: '' });
  if (value && selected !== value) {
    setSelected(value);
  }

  /* State modifiers */
  const deselect = (): void => {
    setSelected(null);
    actions.valuesUpdated(null);
  };
  const select = (id: string): void => {
    setSelected(id);
    actions.valuesUpdated(id);
  };
  const showFilter = (): void => {
    setFilter({ active: true, by: '' });
  };
  const hideFilter = (): void => {
    setFilter({ active: false, by: filter.by });
  };
  const updateFilter = (by: string): void => {
    setFilter({ active: filter.active, by: by });
  };
  const clearFilter = (): void => {
    setFilter({ active: filter.active, by: '' });
  };

  /* Effects */
  useOutsideClickEvent(() => {
    hideFilter();
  }, ref);

  /* Actions */
  const selectActions: SelectActions = {
    filter: updateFilter,
    clear: clearFilter,
    focus: showFilter,
    select: select,
    deselect: deselect,
    deselectAll: deselect,
    close: hideFilter,
  };

  /* Texts */
  const selectTexts = {
    dropdown: {
      controls: {
        deselectAll: texts.controls.clear,
        save: texts.controls.save,
      },
      options: texts.options,
    },
    filter: { label: texts.input.label, placeholder: texts.filter.placeholder },
    input: texts.input,
  };

  return (
    <div className="select is-multiple" style={styles} ref={ref}>
      <Select
        texts={selectTexts}
        icon={icon}
        name={name}
        options={options}
        selected={new Set(selected === null ? null : [selected])}
        error={error}
        filter={filter}
        actions={selectActions}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};
