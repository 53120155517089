export enum I18nNamespace {
  AUTOMATION_CREATE_PAGE = 'pages/Administration/AutomationCreatePage',
  AUTOMATION_EDIT_PAGE = 'pages/Administration/AutomationEditPage',
  PROJECT_CREATE_PAGE = 'pages/Overview/ProjectCreatePage',
  JOB_EDIT_PAGE = 'pages/Overview/JobEditPage',
  PROJECT_SELECT = 'components/HookForm/Common/Project',
  CMS_SELECT = 'components/HookForm/Common/Cms',
  PROJECT_NAME_INPUT = 'components/HookForm/Entity/Project/Name',
  AUTOMATION_NAME_INPUT = 'components/HookForm/Entity/Automation/Name',
  PROJECT_DESCRIPTION_INPUT = 'components/HookForm/Entity/Project/Description',
  AUTOMATION_ACTIVE_INPUT = 'components/HookForm/Entity/Automation/Active',
  AUTOMATION_PERIOD_INPUT = 'components/HookForm/Entity/Automation/Period',
  AUTOMATION_DELAY_INPUT = 'components/HookForm/Entity/Automation/Delay',
  AUTOMATION_RECURSIVE_CRAWL_INPUT = 'components/HookForm/Entity/Automation/RecursiveCrawl',
  AUTOMATION_MARK_FOR_TRANSLATION_INPUT = 'components/HookForm/Entity/Automation/MarkForTranslation',
  AUTOMATION_ACCEPT_TRANSLATION_INPUT = 'components/HookForm/Entity/Automation/AcceptTranslation',
  AUTOMATION_DESCRIPTION_INPUT = 'components/HookForm/Entity/Automation/Description',
  AUTOMATION_PATHS_INPUT = 'components/HookForm/Entity/Automation/Paths',
  SOURCE_LANGUAGE_SELECT = 'components/HookForm/Common/SourceLanguage',
  SOURCE_LANGUAGE_FILTER_SELECT = 'components/HookForm/Entity/Project/SourceLanguageFilter',
  AUTOMATION_TARGET_LANGUAGES_SELECT = 'components/HookForm/Entity/Automation/TargetLanguages',
  PROJECT_SOURCE_LANGUAGE_SELECT = 'components/HookForm/Entity/Project/SourceLanguage',
  PROJECT_TARGET_LANGUAGES_SELECT = 'components/HookForm/Entity/Project/TargetLanguages',
  TARGET_LANGUAGES_SELECT = 'components/HookForm/Common/TargetLanguages',
  JOB_NAME_INPUT = 'components/HookForm/Entity/Job/Name',
  SUBMIT_INPUT = 'components/HookForm/Input/Submit',
  RESET_INPUT = 'components/HookForm/Input/Reset',
  CANCEL_INPUT = 'components/HookForm/Input/Cancel',
}
