import { classNames } from 'common/utils/classNames';
import { TaskError } from 'modules/api/locHub/tasks/task/error/error';
import { ComputedStatus, TaskStatus as ComputedTaskStatus } from 'modules/api/locHub/tasks/task/status/status';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskStatusError } from './TaskStatusError/TaskStatusError';

interface Props {
  taskStatus: ComputedTaskStatus;
  taskError: TaskError | null;
}

export const TaskStatus: React.FC<Props> = ({ taskStatus, taskError }): ReactElement => {
  const [t] = useTranslation();
  const showError = (error: TaskError): ReactElement => {
    return (
      <TaskStatusError
        texts={{
          error: t(`Overview.JobsInfoPageTaskTable.status.error`),
          errorMessage: error.id.includes('actions.') ? t(`Overview.JobsInfoPageTaskTable.${error.id}`) : error.id,
        }}
      />
    );
  };
  const getStatusElement = (): ReactElement => {
    if (taskStatus === ComputedStatus.DOWNLOAD_IN_PROGRESS) {
      return (
        <React.Fragment>
          <i className={'fa fa-circle has-text-light'} />
          <span style={{ marginLeft: '8px' }}>
            {t(`Overview.JobsInfoPageTaskTable.status.${ComputedStatus.DOWNLOAD_IN_PROGRESS}`)}
          </span>
        </React.Fragment>
      );
    }
    if (taskError) {
      return showError(taskError);
    }
    return (
      <React.Fragment>
        <i className={classNames('fa', 'fa-circle', `has-text-${Task.getStatusColor(taskStatus)}`)} />
        <span style={{ marginLeft: '8px' }}>
          {t(`Overview.JobsInfoPageTaskTable.status.${taskStatus.toLowerCase()}`)}
        </span>
      </React.Fragment>
    );
  };
  return getStatusElement();
};
