import { QueryOperator } from '../operator/operator';

export class QueryFragment {
  public constructor(
    private readonly key: string,
    private readonly operator: QueryOperator,
    private readonly value: string,
  ) {}

  public getKey(): string {
    return this.key;
  }

  public getValue(): string {
    return this.value;
  }

  public getOperator(): QueryOperator {
    return this.operator;
  }

  public compose(): string {
    return `${this.key}${this.operator}${this.value}`;
  }
}
