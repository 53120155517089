import { SingleSelect, SingleSelectTexts } from 'components/Input/Select/SingleSelect/SingleSelect';
import { ProjectDto } from 'modules/api/locHub/projects/project/project';
import React, { ReactElement } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { ProjectSelectI18n } from './I18n';

interface Props {
  control: UseFormMethods['control'];
  errors: UseFormMethods['errors'];
  projects: ProjectDto[];
  disabled?: boolean;
}

export const ProjectSelect: React.FC<Props> = ({ control, errors, projects, disabled }): ReactElement => {
  const [t] = useTranslation();
  const namespace = ProjectSelectI18n.configuration.namespace;
  const name = 'projectId';
  const icon = 'fa-archive';

  /* Methods */
  const getErrorMessage = (filedError: FieldError): string => {
    throw new Error(`Unexpected validation error of type ${filedError.type}`);
  };
  /* Texts */
  const texts: SingleSelectTexts = {
    controls: {
      clear: t(`${namespace}.clear`),
      save: t(`${namespace}.save`),
    },
    options: { noOptions: t(`${namespace}.noOptions`) },
    filter: {
      placeholder: t(`${namespace}.filterPlaceholder`),
    },
    input: {
      label: t(`${namespace}.label`),
      placeholder: t(`${namespace}.placeholder`),
    },
  };

  const options = new Map();
  for (const project of projects) {
    options.set(project.id, { id: project.id, label: project.name });
  }
  const error = errors[name] && getErrorMessage(errors[name]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }): ReactElement => {
        return (
          <SingleSelect
            texts={texts}
            icon={icon}
            name={name}
            options={options}
            value={value}
            error={error}
            actions={{
              valuesUpdated: onChange,
            }}
            required={true}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
