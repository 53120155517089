import React, { ReactElement } from 'react';

import { Box } from '../Box/Box';
import { useWizardContext } from './Wizard';

export type StepInfo<T extends object> = {
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  gotoStep: (step: number) => void;
};

export interface Props<T extends object> {
  i18nKey: string;
  render: (props: StepInfo<T>) => ReactElement;
}

export function Step<T extends object>({ render, i18nKey }: Props<T>): ReactElement {
  const stepInfo = useWizardContext();
  return <Box i18nKey={i18nKey}>{render({ ...stepInfo })}</Box>;
}
