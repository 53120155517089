import { LocHubEntityDto } from 'modules/api/locHub/entity/entity';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, useLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { LocHubApi } from 'modules/api/locHub/lochub';
import { Task } from 'modules/api/locHub/tasks/task/task';
import { Tasks } from 'modules/api/locHub/tasks/tasks';
import { UUIDv4 } from 'modules/types/uuid/v4/v4';

export const useLocHubDtoQuery = <Data extends LocHubEntityDto>(
  api: LocHubApi,
  id: UUIDv4 | undefined,
  configuration: QueryConfiguration<Data | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Data> => {
  configuration.query.enabled = id;
  return useLocHubQuery<Data>(
    [api.path, id],
    async () => {
      if (!id) {
        throw new Error(`Unexpected missing id`);
      }
      return (await api.getDto(id)) as Data;
    },
    configuration,
  );
};

export const useLocHubActiveRecordQuery = <Data extends Task>(
  api: typeof Tasks,
  id: UUIDv4 | undefined,
  configuration: QueryConfiguration<Data | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Data> => {
  configuration.query.enabled = id;
  return useLocHubQuery<Data>(
    [api.path, id],
    async () => {
      if (!id) {
        throw new Error(`Unexpected missing id`);
      }
      return (await api.get(id)) as Data;
    },
    configuration,
  );
};
