import { CmsConfiguration, XillioEntity } from 'common/interfaces/xillio';

import { XillioConnectors } from './connectors/connectors';
import { XillioEntities } from './entities/entities';
import { XillioProviders } from './providers/providers';
import { XillioAnalyticsProviders } from './providers/sections/analytics/analytics';
import { XillioCatTmsProviders } from './providers/sections/catTms/catTms';
import { XillioComplimentaryPartnerProviders } from './providers/sections/complimentaryParnter/complimentaryPartner';
import { XillioHumanTranlsationProviders } from './providers/sections/humanTranslation/humanTranslation';

export class XillioApi {
  public static readonly analytics = XillioAnalyticsProviders;
  public static readonly catTms = XillioCatTmsProviders;
  public static readonly complimentaryPartner = XillioComplimentaryPartnerProviders;
  public static readonly connectors = XillioConnectors;
  public static readonly entities = XillioEntities;
  public static readonly providers = XillioProviders;
  public static readonly humanTranslation = XillioHumanTranlsationProviders;

  public static async getConfigurations(): Promise<CmsConfiguration[]> {
    return (await XillioApi.entities.getAll()).map((xillioEntity: XillioEntity) => {
      return {
        configurationType: xillioEntity.kind,
        id: xillioEntity.configurationId,
        name: xillioEntity.original.name.displayName ?? xillioEntity.kind,
      };
    });
  }
}
