import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { QueryResult, usePaginatedLocHubQuery } from 'modules/api/locHub/entity/query/query';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';

import { Task } from '../../task/task';

export const usePaginatedJobTasks = (
  jobId: JobId | undefined,
  page: number | undefined,
  options: Pagination = {},
  configuration: QueryConfiguration<Page<Task> | undefined, unknown> = { error: {}, query: {} },
): QueryResult<Page<Task>> => {
  configuration.query.enabled = jobId && page;
  return usePaginatedLocHubQuery(
    [Api.locHub.tasks.path, { page, by: { key: Api.locHub.tasks.getManyByJob.name, id: jobId } }],
    async () => {
      if (!page) {
        throw new Error(`Unexpected missing page`);
      }
      if (!jobId) {
        throw new Error(`Unexpected missing jobId`);
      }
      return Api.locHub.tasks.getManyByJob(jobId, page, options);
    },
    configuration,
  );
};
