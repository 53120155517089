import { Modal } from 'components/Modal/Modal';
import { Job } from 'modules/api/locHub/jobs/job/job';
import { TaskError } from 'modules/api/locHub/tasks/task/error/error';
import { TaskId } from 'modules/api/locHub/tasks/task/id/id';
import { ComputedStatus, TapiccStatus, TaskStatus } from 'modules/api/locHub/tasks/task/status/status';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { Dispatch, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';

import { Translatable } from '../../../../common/language';
import { classNames } from '../../../../common/utils/classNames';
import { HtmlProps } from '../../../../common/utils/HTMLProps';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { deleteTasksAndInputs, filterTasksByPhase, markAllForTranslation, publishAllToSource } from '../utillities';

export interface Props extends Translatable {
  job: Job;
  tasks: Task[];
  selection: TaskId[];
  actions: Actions;
}

export interface Actions {
  setTaskStatuses: (ids: TaskId[], status: TaskStatus) => void;
  setTaskError: (errors: Record<TaskId, TaskError>) => void;
  setSelection: (ids: TaskId[]) => void;
}

export const Actions: React.FC<Props> = ({
  i18nKey,
  job,
  tasks,
  selection,
  actions: { setTaskStatuses, setTaskError, setSelection },
}): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();

  /* Actions */
  const onClickTranslations = async (): Promise<void> => {
    const selectedTasks = filterTasksByPhase(tasks, TapiccStatus.CONFIRMED, selection);
    const [success, failures] = await markAllForTranslation(selectedTasks);
    const errors: Record<TaskId, TaskError> = {};
    for (const failure of failures) {
      errors[failure] = { id: 'actions.markForTranslation.error', parameters: {} };
    }
    setTaskStatuses(success, TapiccStatus.CONFIRMED);
    setTaskError(errors);
    setSelection(failures);

    if (failures.length === 0) {
      message.success(t(`${i18nKey}.actions.markForTranslation.success`, { count: success.length }));
    } else {
      message.error(t(`${i18nKey}.actions.markForTranslation.failure`, { count: failures.length }));
    }
  };

  const onClickCMS = async (): Promise<void> => {
    const selectedTasks = tasks.filter(task => selection.includes(task.id) && !task.error && task.isTranslated());
    const [success, failures] = await publishAllToSource(selectedTasks);
    const errors: Record<TaskId, TaskError> = {};
    for (const failure of failures) {
      errors[failure.id] = {
        id: 'actions.publishToSource.error',
        parameters: { error: failure.error },
      };
    }
    setTaskStatuses(success, ComputedStatus.CLOSED);
    setTaskError(errors);
    setSelection(failures.map(failure => failure.id));
    if (failures.length === 0) {
      message.success(
        t(`${i18nKey}.actions.publishToSource.success`, {
          count: success.length,
        }),
      );
    } else {
      message.error(
        t(`${i18nKey}.actions.publishToSource.failure`, {
          count: failures.length,
        }),
      );
    }
  };

  const onClickDelete = async (): Promise<void> => {
    const selectedTasks = tasks.filter(task => selection.includes(task.id) && task.isDeleteable());
    try {
      await deleteTasksAndInputs(selectedTasks);
      message.success(
        t(`${i18nKey}.actions.deleteTasks.success`, {
          count: selectedTasks.length,
        }),
      );
    } catch (e) {
      message.error(
        t(`${i18nKey}.actions.deleteTasks.failure`, {
          count: selectedTasks.length,
        }),
      );
    }
  };

  const [showModal, hideModal] = useModal(() => {
    const selectedTasks = tasks.filter(task => selection.includes(task.id) && task.isDeleteable());
    return (
      <Modal close={hideModal}>
        <article className="panel is-danger">
          <p className="panel-heading has-text-centered">{t(`${i18nKey}.actions.deleteTasks.confirmation`)}</p>
          <div className="panel-block">
            <div className="columns has-text-weight-bold" style={{ width: '100%' }}>
              <div className="column is-half has-text-centered">{t(`${i18nKey}.actions.deleteTasks.name`)}</div>
              <div className="column is-half has-text-centered">{t(`${i18nKey}.actions.deleteTasks.type`)}</div>
            </div>
          </div>
          {selectedTasks.map(task => {
            return (
              <div className="panel-block" key={task.id}>
                <div className="columns" style={{ width: '100%' }}>
                  <div className="column is-half has-text-centered">{task.name}</div>
                  <div className="column is-half has-text-centered">{task.type}</div>
                </div>
              </div>
            );
          })}
          <div className="panel-block" style={{ justifyContent: 'flex-end' }}>
            <div className="field is-grouped">
              <p className="control">
                <button
                  className="button is-danger"
                  onClick={(): void => {
                    onClickDelete();
                    hideModal();
                  }}
                >
                  {t(`${i18nKey}.actions.deleteTasks.title`)}
                </button>
              </p>
              <p className="control">
                <button className="button" onClick={hideModal}>
                  {t(`${i18nKey}.actions.deleteTasks.cancel`)}
                </button>
              </p>
            </div>
          </div>
        </article>
      </Modal>
    );
  }, [selection]);

  const publishedTasksCount = filterTasksByPhase(tasks, TapiccStatus.CONFIRMED, selection).length;
  const cmsTaskCount = tasks.filter(task => selection.includes(task.id) && !task.error && task.isTranslated()).length;
  const deleteTaskCount = tasks.filter(task => selection.includes(task.id) && task.isDeleteable()).length;

  return (
    <div className="actions is-pulled-right">
      <div className="field has-addons is-pulled-right">
        <ActionButton
          i18nKey={`${i18nKey}.actions.markForTranslation`}
          icon="fa-language"
          count={publishedTasksCount}
          onClick={onClickTranslations}
        />
        {job.mayUploadTasks() && (
          <ActionButton
            i18nKey={`${i18nKey}.actions.publishToSource`}
            icon="fa-file-export"
            count={cmsTaskCount}
            onClick={onClickCMS}
          />
        )}
        {job.mayDeleteTasks() && (
          <ActionButton
            i18nKey={`${i18nKey}.actions.deleteTasks`}
            className="is-danger is-light"
            icon="fa-trash"
            count={deleteTaskCount}
            onClick={showModal}
          />
        )}
      </div>
    </div>
  );
};

const ActionButton: React.FC<Translatable &
  HtmlProps & { icon: string; count: number; onClick: Dispatch<unknown> }> = ({
  i18nKey,
  className,
  id,
  icon,
  count,
  onClick,
}): ReactElement => {
  const [t] = useTranslation();
  return (
    <p className="control" id={id}>
      <button
        className={classNames('button', className)}
        disabled={!count}
        onClick={onClick}
        title={t(`${i18nKey}.description`, { count })}
      >
        <span className="icon">
          <i className={`fas ${icon}`} />
        </span>
        <span>
          {t(`${i18nKey}.title`)}
          {count ? ` (${count})` : ''}
        </span>
      </button>
    </p>
  );
};
