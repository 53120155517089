import { Api } from 'modules/api/api';
import { ISODateWithTimezone } from 'modules/types/date/iso/iso';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import { Colors } from 'modules/ui/colors/colors';

import { LocHubEntity } from '../../entity/entity';
import { InputType } from '../../inputs/input/type/type';
import { JobId } from '../../jobs/job/id/id';
import { TaskError } from './error/error';
import { TaskId } from './id/id';
import { ComputedStatus, TapiccStatus, TaskStatus } from './status/status';
import { TaskType } from './type/type';

export class Task {
  public readonly id: TaskId;
  public readonly createdAt: Date;
  public readonly updatedAt: Date;
  public readonly archivedAt: Date | null;
  public readonly archived: boolean;
  public readonly type: TaskType;
  public status: TaskStatus;
  public readonly name: string;
  public readonly description: string;
  public readonly note: string;
  public readonly assignedTo: string | null;
  public readonly submittedAt: Date | null;
  public readonly dueAt: Date | null;
  public readonly deliveredAt: Date | null;
  public readonly sourceLanguage: IETFLanguageCode;
  public readonly targetLanguage: IETFLanguageCode;
  public readonly jobId: JobId;
  public nextStatuses: TaskStatus[];
  public error: TaskError | null;

  public constructor(task: TaskDto, error: TaskError | null, status: TaskStatus, nextStatuses: TaskStatus[]) {
    this.id = task.id;
    this.createdAt = new Date(task.createdAt);
    this.updatedAt = new Date(task.updatedAt);
    this.archivedAt = task.archivedAt ? new Date(task.archivedAt) : null;
    this.archived = task.archived;
    this.type = task.type;
    this.status = status;
    this.name = task.name;
    this.description = task.description || '';
    this.note = task.note || '';
    this.assignedTo = task.assignedTo || null;
    this.submittedAt = task.submittedAt ? new Date(task.submittedAt) : null;
    this.dueAt = task.dueAt ? new Date(task.dueAt) : null;
    this.deliveredAt = task.deliveredAt ? new Date(task.deliveredAt) : null;
    this.sourceLanguage = task.sourceLanguage;
    this.targetLanguage = task.targetLanguage;
    this.jobId = task.jobId;
    this.nextStatuses = nextStatuses;
    this.error = error;
  }

  public isNew(): boolean {
    return this.status === TapiccStatus.PENDING;
  }
  public isDownloadingAssets(): boolean {
    return this.status === ComputedStatus.DOWNLOAD_IN_PROGRESS;
  }
  public hasDownloadedAssets(): boolean {
    return this.status !== ComputedStatus.DOWNLOAD_IN_PROGRESS && this.status !== ComputedStatus.DOWNLOAD_FAILED;
  }
  public isConfirmed(): boolean {
    return this.status === TapiccStatus.CONFIRMED;
  }
  public isTranslated(): boolean {
    return this.status === TapiccStatus.COMPLETED || this.status === TapiccStatus.COMPLETED_WITH_WARNINGS;
  }
  public isInTerminalState(): boolean {
    return this.isTranslated() || this.isClosed();
  }
  public isClosed(): boolean {
    return this.status === ComputedStatus.CLOSED;
  }
  public isReadyToBeUploaded(): boolean {
    return this.status === TapiccStatus.CONFIRMED && this.nextStatuses.includes(TapiccStatus.IN_PROGRESS);
  }
  public isReadyToBeConfirmed(): boolean {
    return this.status === TapiccStatus.PENDING && this.nextStatuses.includes(TapiccStatus.CONFIRMED);
  }
  public isReadyForTransitionTo(status: TaskStatus): boolean {
    return this.nextStatuses.includes(status);
  }
  public isDeleteable(): boolean {
    return this.status === TapiccStatus.PENDING || this.status === ComputedStatus.DOWNLOAD_FAILED;
  }

  public static getStatusColor(status: TaskStatus): Colors {
    switch (status) {
      case ComputedStatus.DOWNLOAD_IN_PROGRESS:
        return Colors.LIGHT;
      case ComputedStatus.DOWNLOAD_FAILED:
        return Colors.DANGER;
      case TapiccStatus.PENDING:
        return Colors.INFO;
      case TapiccStatus.CONFIRMED:
        return Colors.WARNING;
      case TapiccStatus.IN_PROGRESS:
        return Colors.WARNING;
      case TapiccStatus.COMPLETED_WITH_WARNINGS:
        return Colors.PRIMARY;
      case TapiccStatus.COMPLETED:
        return Colors.PRIMARY;
      case ComputedStatus.CLOSED:
        return Colors.SUCCESS;
      case TapiccStatus.PAUSED:
        return Colors.LIGHT;
      case TapiccStatus.CANCELLED:
        return Colors.LIGHT;
      case TapiccStatus.REJECTED:
        return Colors.DANGER;
      default:
        return Colors.DANGER;
    }
  }

  public static byTime(a: Task, b: Task): number {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  public static async hasAllSourceInputsDelivered(taskId: TaskId): Promise<boolean> {
    const inputs = await Api.locHub.inputs.getAllByTask(taskId);
    const sourceInputs = inputs.filter(input => input.inputAs === InputType.SOURCE);
    if (!sourceInputs.length) {
      throw new Error(`Task ${taskId} have no inputs.`);
    }
    for (const sourceInput of sourceInputs) {
      if (!sourceInput.deliverableIds) {
        return false;
      }
    }
    return true;
  }
}

export interface TaskDto extends LocHubEntity {
  type: TaskType;
  status: TapiccStatus;
  name: string;
  description?: string;
  note?: string;
  assignedTo?: string;
  submittedAt?: ISODateWithTimezone;
  dueAt?: ISODateWithTimezone;
  deliveredAt?: ISODateWithTimezone;
  sourceLanguage: IETFLanguageCode;
  targetLanguage: IETFLanguageCode;
  jobId: JobId;
}

export interface NewTask {
  type: TaskType;
  status: TapiccStatus;
  name: string;
  description?: string;
  note?: string;
  assignedTo?: string;
  dueAt?: ISODateWithTimezone;
  sourceLanguage: IETFLanguageCode;
  targetLanguage: IETFLanguageCode;
  jobId: JobId;
}
