import { convertObjectToQueryString } from 'common/utils/convertObjectToQueryString';
import { AppLayout } from 'components/AppLayout/AppLayout';
import { JobAutomationTag } from 'components/JobAutomationTag/JobAutomationTag';
import { JobDto } from 'modules/api/locHub/jobs/job/job';
import { usePaginatedJobsWithProject } from 'modules/api/locHub/jobs/query/paginatedList/paginatedList';
import { useLanguages } from 'modules/api/locHub/languages/query/list/list';
import { ProjectDto } from 'modules/api/locHub/projects/project/project';
import { useJobTasksDto } from 'modules/api/locHub/tasks/query/listByJob/listByJob';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Button } from '../../../components/Button/Button';
import { CreateButton } from '../../../components/Button/Buttons';
import { DataTable } from '../../../components/DataTable/DataTable';
import { JobStatus } from '../../../components/JobStatus/JobStatus';
import { LanguageTag } from '../../../components/LanguageTag/LanguageTag';
import { TD } from '../../../components/TD/TD';
import { LanguageDto } from '../../../modules/api/locHub/languages/language/language';
import { HomePageCrumb } from '../HomePage/HomePage';

export const JobsListPageCrumb: Breadcrumb = {
  page: 'Overview.JobsListPage',
  icon: 'list',
  location: '/jobs',
};

export const JobsListPage: React.FC = (): ReactElement => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const languages = useLanguages();
  const [t] = useTranslation();
  const [archiveToggle, setArchiveToggle] = useState<string>(convertObjectToQueryString({ archived: 'false' }));
  const jobs = usePaginatedJobsWithProject(page, { query: archiveToggle });
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, JobsListPageCrumb]}>
      <div className="tile is-child box">
        <CreateButton id={'create-jobs-button'} i18nKey="Overview.JobsListPage.createButton" to="/jobs/new" />

        <DataTable
          i18nKey={JobsListPageCrumb.page}
          data={jobs.success ? jobs.data : undefined}
          setPage={setPage}
          columns={[
            'project',
            'jobTitle',
            'assets',
            'jobState',
            'sourceLanguage',
            'targetLanguages',
            'createdBy',
            'createdAt',
            'updatedAt',
            '',
          ]}
          setArchiveToggle={setArchiveToggle}
          archiveToggleText={t(`Overview.JobsListPage.toggleButton.text`)}
          render={(job): ReactElement => (
            <JobsListPageRow {...job} languages={languages.success ? languages.data : []} />
          )}
        />
      </div>
    </AppLayout>
  );
};

const JobsListPageRow: React.FC<JobDto & { project: ProjectDto } & { languages: LanguageDto[] }> = ({
  id,
  automationId,
  name,
  defaultSourceLanguage,
  defaultTargetLanguages,
  createdAt,
  updatedAt,
  project,
  archived,
}): ReactElement => {
  const [t] = useTranslation();
  const tasks = useJobTasksDto(id);

  // Styles
  const targetStyle: React.CSSProperties = {
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
  };

  return (
    <tr key={id}>
      <TD wrap>{project.name}</TD>
      <TD wrap>
        {name}&nbsp;
        {archived ? <span className="tag is-light is-info">{t('Overview.JobsListPage.archived.text')}</span> : ''}
      </TD>
      <TD>{tasks.success ? tasks.data.length : ''}</TD>
      <TD>
        <JobStatus i18nKey={'Overview.JobsListPage'} jobId={id} />
      </TD>
      <TD>{defaultSourceLanguage && <LanguageTag language={defaultSourceLanguage} />}</TD>
      <TD>
        <div style={targetStyle}>
          {defaultTargetLanguages?.map(
            (language): ReactElement => (
              <LanguageTag language={language} key={language} />
            ),
          )}
        </div>
      </TD>
      <TD>
        <JobAutomationTag automationId={automationId} />
      </TD>
      <TD>{t('locale.date', { date: new Date(createdAt) })}</TD>
      <TD>{t('locale.fromNow', { date: new Date(updatedAt) })}</TD>
      <TD>
        <div className="field is-pulled-right">
          <span className="control">
            <Button to={`/jobs/${id}`} i18nKey="Overview.JobsListPage.viewButton">
              <span className="icon is-small">
                <i className="fas fa-info" />
              </span>
            </Button>
          </span>
          <span className="control">
            <Button to={`/jobs/${id}/edit`} i18nKey="Overview.JobsListPage.editButton">
              <span className="icon is-small">
                <i className="fas fa-edit" />
              </span>
            </Button>
          </span>
        </div>
      </TD>
    </tr>
  );
};
