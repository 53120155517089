import { Button } from 'components/Button/Button';
import { Email } from 'components/Input';
import { LandingLayout } from 'components/LandingLayout/LandingLayout';
import { LoadingButton } from 'components/LoadingButton/LoadingButton';
import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Api } from '../../../modules/api/api';

const Confirm: React.FC<{ email: string }> = ({ email }): ReactElement => {
  const [t] = useTranslation('');
  return (
    <LandingLayout id="recovery">
      <h3 className="title">{t('Authentication.ForgotPasswordPageConfirmation.title')}</h3>
      <Trans i18nKey="Authentication.ForgotPasswordPageConfirmation.description" children={{ email }} />
      <Button to="/home" i18nKey="Authentication.LoginPage.backLoginPage" />
    </LandingLayout>
  );
};

export const ForgotPasswordPage: React.FC = (): ReactElement => {
  const [t] = useTranslation('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  if (success) {
    return <Confirm email={email} />;
  }

  return (
    <LandingLayout id="recovery">
      <h3 className="title">{t('Authentication.ForgotPasswordPage.title')}</h3>
      <p>{t('Authentication.ForgotPasswordPage.description')}</p>

      <form
        className="recovery-form"
        onSubmit={async (event): Promise<void> => {
          event.preventDefault();
          setLoading(true);
          try {
            await Api.forgotPassword(email);
            setSuccess(true);
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Email value={email} onChange={setEmail} required i18nKey="Authentication.ForgotPasswordPage.emailField" />

        <div className="field is-grouped-wide controls">
          <div className="control">
            <LoadingButton
              loading={loading}
              className="is-success"
              i18nKey="Authentication.ForgotPasswordPage.resetButton"
            />
          </div>
          <div className="control">
            <Button to="/login" i18nKey="Authentication.ForgotPasswordPage.cancelButton" />
          </div>
        </div>
      </form>
    </LandingLayout>
  );
};
