import { LoadingButton } from 'components/LoadingButton/LoadingButton';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface Props {
  i18nKey: string;
  loading: boolean;
  cancelTo: string;
  disabled?: boolean;
}

export const ActionOrCancelButton: React.FC<Props> = ({ loading, i18nKey, cancelTo, disabled }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className={'buttons is-pulled-right'}>
      <LoadingButton
        i18nKey={i18nKey}
        loading={loading}
        className="is-success"
        disabled={disabled}
        id={'create-button'}
      />
      <Link className="button" to={cancelTo} id={'cancel-button'}>
        {t(`${i18nKey}.cancel`)}
      </Link>
    </div>
  );
};
