import { CmsConfiguration, XillioEntity } from 'common/interfaces/xillio';
import { Chip } from 'components/Chips/Chip/Chip';
import { Chips } from 'components/Chips/Chips';
import { FileBrowser } from 'components/FileBrowser/FileBrowser';
import { FormField } from 'components/HookForm/Field/Field';
import { Modal } from 'components/Modal/Modal';
import { WizardStep } from 'components/WizardStep/WizardStep';
import React, { ReactElement, useState } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ValidateResult } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';

import { AutomationPathInputI18n } from './I18n';

interface Props {
  cmsConfiguration?: CmsConfiguration;
  control: UseFormMethods['control'];
}

export const AutomationPathsInput: React.FC<Props> = ({ control, cmsConfiguration }): ReactElement => {
  const [t] = useTranslation();
  const namespace = AutomationPathInputI18n.configuration.namespace;
  const name = 'automationPaths';
  const [entities, setEntities] = useState<XillioEntity[]>([]);
  if (!cmsConfiguration) {
    return (
      <FormField label={t(`${namespace}.label`)} name={'automationPaths'} required={true}>
        <button className="button is-fullwidth is-static" disabled>
          {t(`${namespace}.placeholder`)}
        </button>
      </FormField>
    );
  }
  /* Methods */
  const isDefault = (values: XillioEntity[]): boolean => {
    const defaultValues: XillioEntity[] = control.defaultValuesRef.current[name] ?? [];
    if (values.length !== defaultValues.length) {
      return false;
    }
    const currentPaths = new Set(values.map(item => item.path));
    const defaultPaths = new Set(defaultValues.map(item => item.path));
    for (const path of currentPaths) {
      if (!defaultPaths.has(path)) {
        return false;
      }
    }
    return true;
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (data): ValidateResult => {
          return Array.isArray(data) && data.length > 0;
        },
      }}
      render={({ onChange, value }): ReactElement => {
        const setSelection = (newValues: XillioEntity[]): void => {
          if (isDefault(newValues)) {
            onChange(control.defaultValuesRef.current[name] ?? []);
          } else {
            onChange(newValues);
          }
        };
        if (value && JSON.stringify(value) !== JSON.stringify(entities)) {
          setEntities(value);
        }
        return (
          <FormField label={t(`${namespace}.label`)} name={'automationPaths'} required={true}>
            <ModalButton
              cmsConfiguration={cmsConfiguration}
              entities={entities}
              setSelection={setSelection}
            ></ModalButton>
            <div style={{ paddingTop: '15px' }}>
              <Chips>
                {entities.map((entity: XillioEntity) => {
                  return (
                    <Chip
                      key={entity.path}
                      id={entity.path}
                      label={entity.path}
                      actions={{
                        delete: (): void => {
                          setSelection(
                            entities.filter(item => {
                              return entity.path !== item.path;
                            }),
                          );
                        },
                      }}
                    />
                  );
                })}
              </Chips>
            </div>
          </FormField>
        );
      }}
    />
  );
};

export const ModalButton: React.FC<{
  entities: XillioEntity[];
  setSelection: (value: XillioEntity[]) => void;
  cmsConfiguration: CmsConfiguration;
}> = ({ entities, setSelection, cmsConfiguration }): ReactElement => {
  const [t] = useTranslation();
  const namespace = AutomationPathInputI18n.configuration.namespace;
  const [showModal, hideModal] = useModal(
    () => (
      <Modal close={hideModal}>
        <WizardStep i18nKey="Overview.TaskCreatePage.entityBrowser">
          <FileBrowser
            i18nKey="Overview.TaskCreatePage.entityBrowser"
            selection={entities}
            setSelection={setSelection}
            preSelectedConfiguration={cmsConfiguration}
          />
          <hr />
          <button className="button is-pulled-right is-success" onClick={hideModal}>
            {t(`${namespace}.save`)}
          </button>
        </WizardStep>
      </Modal>
    ),
    [entities, cmsConfiguration],
  );
  return (
    <button
      className="button is-fullwidth"
      onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        showModal();
      }}
    >
      {t(`${namespace}.placeholder`)}
    </button>
  );
};
