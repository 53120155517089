import React, { ReactElement } from 'react';

import { XillioEntity } from '../../common/interfaces/xillio';
import { isFolder } from './utilities';

export const FileIcon: React.FC<{ entity: XillioEntity }> = ({ entity }): ReactElement => {
  const mimeType = entity.original.mimeType?.type;
  const extension = entity.original.file?.extension || entity.original.file?.rawExtension;
  let icon = 'file';

  if (isFolder(entity)) {
    icon = 'folder';
  } else if (extension && isAllowedExtension(extension)) {
    icon = extension;
  } else if (mimeType) {
    icon = fromMimeType(mimeType);
  }

  return (
    <i className={'fb-icon fb-' + icon}>
      <img src={`${process.env.PUBLIC_URL}/icons/${icon}.png`} alt={icon} />
    </i>
  );
};

// Checks if the given extension has an image-file that is matching.
function isAllowedExtension(extension: string): boolean {
  const allowed = 'ai,css,csv,dbf,doc,dwg,exe,file,fla,html,iso,javascript,jpg,json,pdf,png,ppt,psd,rtf,svg,txt,xls,xml,zip'.split(
    ',',
  );
  return allowed.includes(extension);
}

function fromMimeType(mimeType: string): string {
  const result = mimeType.match(/^(text|image|application)\/(.*)$/);
  if (result !== null) {
    if (isAllowedExtension(result[2])) {
      return result[2];
    }
    switch (result[2]) {
      case 'jpeg':
        return 'jpg';
      case 'docx':
        return 'doc';
      // We can add more aliases here if we want to show more icons based on mimetypes.
    }
  }
  // fallback to a normal 'file'
  return 'file';
}
