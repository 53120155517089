import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubListAllQuery } from 'modules/api/locHub/entity/query/listAll/listAll';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { ProjectDto } from '../../project/project';

export const useProjects = (
  configuration: QueryConfiguration<ProjectDto[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<ProjectDto[]> => {
  return useLocHubListAllQuery(Api.locHub.projects, configuration);
};
