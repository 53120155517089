import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  isDefaultTapicc: boolean;
}

export const DefaultTapiccTag: React.FC<Props> = ({ isDefaultTapicc }): ReactElement => {
  const [t] = useTranslation();
  return (
    <span style={{ marginRight: '4px' }}>
      {isDefaultTapicc && <span className="tag is-light is-info">{t('Overview.ProjectListPage.defaultTapicc')}</span>}
    </span>
  );
};
