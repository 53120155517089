import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const AutomationDescriptionInputI18n = {
  configuration: { namespace: I18nNamespace.AUTOMATION_DESCRIPTION_INPUT, labelKey: 'label' },
  translations: {
    label: dictionary.automationDescription,
    placeholder: dictionary.automationDescription,
    validationMaxLengthError: dictionary.validationMaxLengthError,
    validationRequiredError: dictionary.validationRequiredError,
  },
};
