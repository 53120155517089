import React, { ReactElement } from 'react';

import { Button, LinkProps } from './Button';

export const CreateButton: React.FC<LinkProps> = ({ i18nKey, to, id }): ReactElement => (
  <Button i18nKey={i18nKey} className="is-success is-pulled-right" to={to} id={id}>
    <span className="icon">
      <i className="fas fa-plus" />
    </span>
  </Button>
);
