import { classNames } from 'common/utils/classNames';
import { Colors } from 'modules/ui/colors/colors';
import React, { ReactElement } from 'react';

import { AutomationStatusError } from './AutomationStatusError/AutomationStatusError';

interface Props {
  errorMessage?: string;
  createdAt: Date;
  lastRunTimestamp: Date;
  texts: {
    success: string;
    error: string;
    pending: string;
  };
}

export const AutomationStatus: React.FC<Props> = ({
  texts,
  errorMessage,
  createdAt,
  lastRunTimestamp,
}): ReactElement => {
  const getStatusElement = (): ReactElement => {
    if (errorMessage) {
      return (
        <>
          <AutomationStatusError
            texts={{
              error: texts.error,
              errorMessage,
            }}
          />
        </>
      );
    }
    if (lastRunTimestamp.getTime() < createdAt.getTime() + 100) {
      return (
        <React.Fragment>
          <i className={classNames('fa', 'fa-circle', `has-text-${Colors.LIGHT}`)} />
          <span style={{ marginLeft: '8px' }}>{texts.pending}</span>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <i className={classNames('fa', 'fa-circle', `has-text-${Colors.SUCCESS}`)} />
        <span style={{ marginLeft: '8px' }}>{texts.success}</span>
      </React.Fragment>
    );
  };
  return getStatusElement();
};
