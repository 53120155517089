import { AppLayout } from 'components/AppLayout/AppLayout';
import { Breadcrumb } from 'components/Breadcrumbs/Breadcrumbs';
import { Button } from 'components/Button/Button';
import { DataTable } from 'components/DataTable/DataTable';
import { AdminTabs } from 'components/Tabs/Tabs';
import { usePaginatedUsers } from 'modules/api/locHub/users/query/paginatedList/paginatedList';
import { AdminPageCrumb } from 'pages/Administration/AdminPage/AdminPage';
import { HomePageCrumb } from 'pages/Overview/HomePage/HomePage';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateButton } from '../../../components/Button/Buttons';
import { TD } from '../../../components/TD/TD';
import { Api } from '../../../modules/api/api';
import { UserDto } from '../../../modules/api/locHub/users/user/user';

export const UserListPageCrumb: Breadcrumb = {
  page: 'Administration.UserListPage',
  icon: 'users',
  location: '/admin/users',
};

export const UserListPage: React.FC = (): ReactElement => {
  const [page, setPage] = useState<number | undefined>(undefined);
  const [t] = useTranslation();
  const user = Api.getUserDataFromCache() as UserDto;
  const users = usePaginatedUsers(page);
  return (
    <AppLayout breadcrumbs={[HomePageCrumb, AdminPageCrumb, UserListPageCrumb]}>
      <AdminTabs>
        <CreateButton
          id={'create-user-button'}
          i18nKey="Administration.UserListPage.createButton"
          to="/admin/users/new"
        />
        <DataTable
          i18nKey={UserListPageCrumb.page}
          data={users.success ? users.data : undefined}
          setPage={setPage}
          columns={['name', 'username', 'email', '']}
          render={({ id, name, username, email, active }): ReactElement => (
            <tr>
              <TD wrap>
                {name}
                <div className="tags is-pulled-right">
                  {user.id === id && <span className="tag is-info">{t('Administration.UserListPage.you')}</span>}
                  {!active && <span className="tag is-warning">{t('Administration.UserListPage.disabled')}</span>}
                </div>
              </TD>
              <TD wrap>{username}</TD>
              <TD>{email}</TD>
              <TD>
                <div className="buttons is-small is-pulled-right has-addons">
                  <Button to={`/admin/users/${id}`} i18nKey="Administration.UserListPage.editButton">
                    <span className="icon is-small">
                      <i className="fas fa-edit" />
                    </span>
                  </Button>
                </div>
              </TD>
            </tr>
          )}
        />
      </AdminTabs>
    </AppLayout>
  );
};
