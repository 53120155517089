import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const LoadingPage: React.FC<{}> = (): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className="pageloader is-active">
      <span className="title">{t('Common.LoadingPage.title')}</span>
    </div>
  );
};
