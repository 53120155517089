import { dictionary } from 'common/language/dictionary';
import { I18nNamespace } from 'common/language/namespace';

export const CmsSelectI18n = {
  configuration: { namespace: I18nNamespace.CMS_SELECT, labelKey: 'label' },
  translations: {
    label: dictionary.cms,
    placeholder: dictionary.selectCms,
    noOptions: dictionary.noOptions,
    filterPlaceholder: dictionary.filter,
    clear: dictionary.clear,
    save: dictionary.save,
  },
};
