import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { classNames } from '../../common/utils/classNames';

export interface Breadcrumb {
  page: string;
  location: string;
  icon: string | ReactElement;
  text?: string;
}

export interface Props {
  breadcrumbs: Breadcrumb[];
}

export const BreadCrumbs: React.FC<Props> = ({ breadcrumbs }): ReactElement => {
  const [t] = useTranslation();
  return (
    <nav className="breadcrumb is-large" aria-label="breadcrumbs">
      <ul>
        {breadcrumbs.map(
          (crumb, i): ReactElement => (
            <li key={i} className={classNames(i + 1 === breadcrumbs.length ? 'is-active' : 'is-hidden-mobile')}>
              <Link to={crumb.location}>
                <span className="icon">
                  {typeof crumb.icon === 'string' ? <i className={`fas fa-${crumb.icon}`} /> : crumb.icon}
                </span>
                <span>{crumb.text || t(`${crumb.page}.title`)}</span>
              </Link>
            </li>
          ),
        )}
      </ul>
    </nav>
  );
};
