import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../common/utils/classNames';
import { Future } from '../../common/utils/Future';

export interface Props {
  i18nKey: string;
  className?: string;
  timeout?: number;
  disabled?: boolean;
  loading?: boolean;
  onConfirm: () => Future<void>;
}

export const ConfirmButton: React.FC<Props> = ({ i18nKey, className, disabled, onConfirm, loading }): ReactElement => {
  const [t] = useTranslation();
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (confirm) {
      // see: ConfirmButton.scss#.is-animating
      const timeout = setTimeout(() => setConfirm(false), 5000);
      return (): void => clearTimeout(timeout);
    }
  }, [confirm]);

  return (
    <button
      className={classNames('button', 'confirm', confirm && 'is-light is-animating', className)}
      data-testid={i18nKey}
      disabled={disabled}
      onClick={async (): Promise<void> => {
        if (!confirm) {
          setConfirm(true);
        } else {
          setConfirm(false);
          await onConfirm();
        }
      }}
    >
      {confirm ? (
        <>
          <span className="icon is-small">
            <i className="fas fa-times" />
          </span>
          <span>{t(i18nKey + '.confirm')}</span>
        </>
      ) : (
        <>
          {loading && <i className="fas fa-spin fa-spinner" style={{ marginRight: '8px' }} />}
          {t(i18nKey + '.text')}
        </>
      )}
    </button>
  );
};
