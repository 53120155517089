import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';

import { TestIds } from '../../testIds';
import { SelectOption, matchesFilter } from '../Options/Option';

export interface ControlsTexts {
  selectAll?: string;
  deselectAll: string;
  save: string;
}

export interface ControlsActions {
  readonly selectAll?: (visibleIds: string[]) => void;
  readonly deselectAll: (visibleIds: string[]) => void;
  readonly close: () => void;
}

export interface Props {
  readonly texts: ControlsTexts;
  readonly options: Map<string, SelectOption>;
  readonly filterBy: string;
  readonly actions: ControlsActions;
}
export const Controls: React.FC<Props> = ({ texts, options, filterBy, actions }): ReactElement => {
  const visibleOptions: string[] = [];
  for (const option of options.values()) {
    const match: boolean = matchesFilter(option.label, filterBy);
    if (!match) {
      continue;
    }
    visibleOptions.push(option.id);
  }
  const isSelectAllActionVisible = actions.selectAll && texts.selectAll;
  return (
    <React.Fragment>
      <div className="columns is-marginless">
        {isSelectAllActionVisible && (
          <div
            className="column is-one-third is-paddigless"
            style={{ padding: '0 0 0 0', borderRight: '1px solid #ededed' }}
          >
            <a
              href="!#"
              key="select-all"
              data-testid={TestIds.SELECT_ALL_BUTTON}
              onClick={(event: React.MouseEvent): void => {
                event.preventDefault();
                if (actions.selectAll) {
                  actions.selectAll(visibleOptions);
                }
              }}
              className="dropdown-item has-text-centered"
            >
              <span className="icon">
                <i className="fas fa-check" />
              </span>
              {texts.selectAll}
            </a>
          </div>
        )}
        <div
          className={classNames(
            'column',
            'is-paddingless',
            isSelectAllActionVisible ? 'column is-one-third' : 'column is-one-half',
          )}
          style={{ padding: '0 0 0 0', borderRight: '1px solid #ededed' }}
        >
          <a
            href="!#"
            key="deselect-all"
            data-testid={TestIds.DESELECT_ALL_BUTTON}
            onClick={(event: React.MouseEvent): void => {
              event.preventDefault();
              actions.deselectAll(visibleOptions);
            }}
            className="dropdown-item has-text-centered"
          >
            <span className="icon">
              <i className="fas fa-times" />
            </span>
            {texts.deselectAll}
          </a>
        </div>
        <div
          className={classNames(
            'column',
            'is-paddingless',
            isSelectAllActionVisible ? 'column is-one-third' : 'column is-one-half',
          )}
          style={{ padding: '0 0 0 0' }}
        >
          <a
            href="!#"
            key="close"
            onClick={(event: React.MouseEvent): void => {
              event.preventDefault();
              actions.close();
            }}
            className="dropdown-item has-text-centered"
          >
            <span className="icon">
              <i className="fas fa-save" />
            </span>
            {texts.save}
          </a>
        </div>
      </div>
      <hr className="dropdown-divider is-paddingless is-marginless" />
    </React.Fragment>
  );
};
