import { FormField } from 'components/HookForm/Field/Field';
import { EntityName } from 'modules/api/locHub/entity/name/name';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';

interface Props {
  entity: EntityName;
  value?: string;
  maxLength: number;
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  texts: {
    label: string;
    placeholder: string;
    validationMaxLengthError: string;
  };
}

export const DescriptionInput: React.FC<Props> = ({
  entity,
  value,
  maxLength,
  register,
  errors,
  texts,
}): ReactElement => {
  const name = `${entity}Description`;
  const icon = 'fa-comment';
  const required = false;
  /* Methods */
  const getErrorMessage = (filedError: FieldError): string => {
    if (filedError.type === 'maxLength') {
      return texts.validationMaxLengthError;
    }
    throw new Error(`Unexpected validation error of type ${filedError.type}`);
  };
  const error = errors[name] && getErrorMessage(errors[name]);
  return (
    <FormField label={texts.label} name={name} error={error} leftIcon={icon} required={required}>
      <input
        className="input"
        type="text"
        placeholder={texts.placeholder}
        id={`${entity}-description-input`}
        name={name}
        ref={register({ required, maxLength })}
        maxLength={maxLength}
        value={value}
      />
    </FormField>
  );
};
