import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { LocHub } from '../lochub';
import { ProjectId } from './project/id/id';
import { NewProject, ProjectDto } from './project/project';

export class Projects {
  public static readonly path: string = '/api/internal/projects';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Projects.path, parameters);
  }

  private static getDetailUrl(id: ProjectId): URL {
    return Api.getUrl(`${Projects.path}/${id}`);
  }

  private static getArchiveUrl(id: ProjectId): URL {
    return Api.getUrl(`/api/internal/archive/project/${id}`);
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<ProjectDto>> {
    return LocHub.getJson(Projects.getBaseUrl({ page, ...options }));
  }

  public static async getAll(): Promise<ProjectDto[]> {
    return LocHub.getAll(Projects.path);
  }

  public static async getDto(projectId: ProjectId): Promise<ProjectDto> {
    return LocHub.getJson(Projects.getDetailUrl(projectId));
  }

  public static async create(project: NewProject): Promise<ProjectDto> {
    const result = await LocHub.postJson<NewProject, ProjectDto>(Projects.getBaseUrl(), project);
    queryCache.invalidateQueries(Projects.path);
    return result;
  }

  public static async update(projectId: ProjectId, project: Partial<ProjectDto>): Promise<ProjectDto> {
    const result = await LocHub.patchJson(Projects.getDetailUrl(projectId), project);
    queryCache.invalidateQueries(Projects.path);
    return result;
  }

  public static async archive(projectId: ProjectId): Promise<void> {
    await LocHub.post(Projects.getArchiveUrl(projectId));
    queryCache.invalidateQueries(Projects.path);
  }

  public static async delete(projectId: ProjectId): Promise<void> {
    await LocHub.delete(Projects.getDetailUrl(projectId));
    queryCache.invalidateQueries(Projects.path);
  }
}
