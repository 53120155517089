import { classNames } from 'common/utils/classNames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Future } from '../../common/utils/Future';

export interface Props {
  id?: string;
  i18nKey: string;
  disabled?: boolean;
  className?: string;
}

export interface LinkProps extends Props {
  to: string;
}

export interface ButtonProps extends Props {
  onClick?: () => Future<void>;
}

export const Button: React.FC<LinkProps | ButtonProps> = (props): ReactElement => {
  const [t] = useTranslation();

  const contents = props.children ? (
    <>
      {props.children} <span>{t(`${props.i18nKey}.text`)}</span>
    </>
  ) : (
    t(`${props.i18nKey}.text`)
  );

  if ('to' in props) {
    return (
      <Link
        id={props.id}
        className={classNames('button', props.className)}
        to={props.to}
        aria-disabled={!!props.disabled}
        data-testid={props.i18nKey}
      >
        {contents}
      </Link>
    );
  } else {
    return (
      <button
        id={props.id}
        className={classNames('button', props.className)}
        onClick={props.onClick}
        disabled={props.disabled}
        data-testid={props.i18nKey}
      >
        {contents}
      </button>
    );
  }
};
