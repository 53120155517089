import { useOutsideClickEvent } from 'common/hooks/useMouseEvent';
import { Chip, ChipActions } from 'components/Chips/Chip/Chip';
import { Chips } from 'components/Chips/Chips';
import React, { useRef, useState } from 'react';

import { OptionsTexts } from '../Dropdown/Options';
import { SelectOption, getSelectedOptions } from '../Dropdown/Options/Option';
import { Filter } from '../FilterInput';
import { Select, SelectActions } from '../Select';
import { SimpleInputTexts } from '../SimpleInput/SimpleInput';

export interface MultipleSelectActions {
  valuesUpdated: (ids: Set<string>) => void;
}

export interface MultipleSelectTexts {
  controls: {
    selectAll: string;
    deselectAll: string;
    save: string;
  };
  options: OptionsTexts;
  filter: { placeholder: string };
  input: SimpleInputTexts;
}

interface Props {
  /* Used texts */
  readonly texts: MultipleSelectTexts;
  /* Font awesome 5 icon string (eg. "fa-globe")  */
  readonly icon: string;
  /* Select input name property */
  readonly name: string;
  /* Options available to select */
  readonly options: Map<string, SelectOption>;
  /* Initially selected options */
  readonly values?: Set<string>;
  /* Supported actions */
  readonly actions: MultipleSelectActions;
  /* Required field */
  readonly required: boolean;
}

/* Styles */
const styles: React.CSSProperties = {
  flexGrow: 1,
  flexBasis: 0,
  width: '100%',
};

export const MultipleSelect: React.FC<Props> = ({ texts, icon, name, options, values, actions, required }) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<Set<string>>(values ? new Set(values) : new Set());
  const [filter, setFilter] = useState<Filter>({ active: false, by: '' });
  if (values && JSON.stringify(Array.from(values)) !== JSON.stringify(Array.from(selected))) {
    setSelected(values);
  }
  /* State modifiers */
  const deselect = (id: string): void => {
    selected.delete(id);
    setSelected(selected);
    actions.valuesUpdated(selected);
  };
  const select = (id: string): void => {
    selected.add(id);
    setSelected(selected);
    actions.valuesUpdated(selected);
  };
  const selectAll = (visibleIds: string[]): void => {
    for (const id of visibleIds) {
      selected.add(id);
    }
    setSelected(selected);
    actions.valuesUpdated(selected);
  };
  const deselectAll = (visibleIds: string[]): void => {
    for (const id of visibleIds) {
      selected.delete(id);
    }
    setSelected(selected);
    actions.valuesUpdated(selected);
  };
  const showFilter = (): void => {
    setFilter({ active: true, by: '' });
  };
  const hideFilter = (): void => {
    setFilter({ active: false, by: filter.by });
  };
  const updateFilter = (by: string): void => {
    setFilter({ active: filter.active, by: by });
  };
  const clearFilter = (): void => {
    setFilter({ active: filter.active, by: '' });
  };

  /* Effects */
  useOutsideClickEvent(() => {
    hideFilter();
  }, ref);

  /* Actions */
  const chipActions: ChipActions = {
    delete: deselect,
  };
  const selectActions: SelectActions = {
    filter: updateFilter,
    clear: clearFilter,
    focus: showFilter,
    select: select,
    deselect: deselect,
    selectAll: selectAll,
    deselectAll: deselectAll,
    close: hideFilter,
  };

  /* Texts */
  const selectTexts = {
    dropdown: {
      controls: {
        selectAll: texts.controls.selectAll,
        deselectAll: texts.controls.deselectAll,
        save: texts.controls.save,
      },
      options: texts.options,
    },
    filter: { label: texts.input.label, placeholder: texts.filter.placeholder },
    input: texts.input,
  };

  return (
    <div className="select is-multiple" style={styles} ref={ref}>
      <Select
        icon={icon}
        name={name}
        options={options}
        selected={selected}
        filter={filter}
        actions={selectActions}
        texts={selectTexts}
        required={required}
      />
      <Chips>
        {getSelectedOptions(options, selected).map((item: SelectOption) => {
          return <Chip key={item.id} id={item.id} label={item.label} actions={chipActions} />;
        })}
      </Chips>
    </div>
  );
};
