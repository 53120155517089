import { TextLink } from 'components/Link/TextLink';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  i18nKey: string;
  value: string | number;
  link?: string;
}

export const Metric: React.FC<Props> = ({ i18nKey, value, link }): ReactElement => {
  const [t] = useTranslation();

  return (
    <div className="tile is-parent metric">
      <div className="tile is-child box has-text-centered">
        {link ? (
          <TextLink href={link}>
            <h3 className="title is-5 is-spaced">{t(i18nKey)}</h3>
            <p className="subtitle is-5 has-text-weight-semibold has-text-info">
              <span>{value}</span>
            </p>
          </TextLink>
        ) : (
          <React.Fragment>
            <h3 className="title is-5 is-spaced">{t(i18nKey)}</h3>
            <p className="subtitle is-5 has-text-weight-semibold has-text-info">
              <span>{value}</span>
            </p>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
