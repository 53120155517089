import { convertObjectToQueryString } from 'common/utils/convertObjectToQueryString';
import { DefaultTapiccTag } from 'components/DefaultTapiccTag/DefaultTapiccTag';
import { ProjectAutomationTag } from 'components/ProjectAutomationTag/ProjectAutomationTag';
import { usePaginatedProjects } from 'modules/api/locHub/projects/query/paginatedList/paginatedList';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Button } from '../../../components/Button/Button';
import { CreateButton } from '../../../components/Button/Buttons';
import { DataTable } from '../../../components/DataTable/DataTable';
import { TD } from '../../../components/TD/TD';
import { HomePageCrumb } from '../HomePage/HomePage';

export const ProjectListPageCrumb: Breadcrumb = {
  page: 'Overview.ProjectListPage',
  icon: 'archive',
  location: '/projects',
};

export const ProjectListPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const [page, setPage] = useState<number | undefined>(undefined);
  const [archiveToggle, setArchiveToggle] = useState<string>(convertObjectToQueryString({ archived: 'false' }));
  const projects = usePaginatedProjects(page, { query: archiveToggle });

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, ProjectListPageCrumb]}>
      <div className="tile is-child box">
        <CreateButton id={'create-project-button'} i18nKey="Overview.ProjectListPage.createButton" to="/projects/new" />
        <DataTable
          i18nKey={ProjectListPageCrumb.page}
          data={projects.success ? projects.data : undefined}
          setPage={setPage}
          columns={['name', 'features', 'createdAt', 'updatedAt', '']}
          setArchiveToggle={setArchiveToggle}
          archiveToggleText={t(`Overview.ProjectListPage.toggleButton.text`)}
          render={({ id, name, createdAt, updatedAt, archived, isDefaultTapicc }): ReactElement => (
            <tr key={id}>
              <TD wrap>
                {name}
                &nbsp;
                {archived && (
                  <span className="tag is-light is-info">{t('Overview.ProjectListPage.archived.text')}</span>
                )}
              </TD>
              <TD>
                <DefaultTapiccTag isDefaultTapicc={isDefaultTapicc} />
                <ProjectAutomationTag projectId={id} />
              </TD>
              <TD>{t('locale.date', { date: new Date(createdAt) })}</TD>
              <TD>{t('locale.fromNow', { date: new Date(updatedAt) })}</TD>
              <TD>
                <div className="buttons is-small is-pulled-right has-addons">
                  <Button to={`/projects/${id}`} i18nKey="Overview.ProjectListPage.editButton">
                    <span className="icon is-small">
                      <i className="fas fa-edit" />
                    </span>
                  </Button>
                </div>
              </TD>
            </tr>
          )}
        />
      </div>
    </AppLayout>
  );
};
