export enum InputType {
  BITEXT = 'bitext',
  SOURCE = 'source',
  INSTRUCTIONS = 'instructions',
  GLOSSARY = 'glossary',
  TM = 'tm',
  REFERENCE = 'reference',
  ZIP = 'zip',
  REPORT = 'report',
}
