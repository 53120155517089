// Iterate over the list of promises, and collecting all the return values into a list.
// All errors will not be inside of the result
export async function collectCalls<T>(promises: Promise<T>[]): Promise<T[]> {
  const result: T[] = [];

  await promises.reduce(async (p, next) => {
    await p;
    return next
      .then(e => {
        result.push(e);
      })
      .catch(error => {
        // ignore
      });
  }, Promise.resolve());
  return result;
}

// Iterate over the list of promises, and collecting all the return values into a list.
export async function collectCallsWithErrors<T>(promises: Promise<T>[]): Promise<[T[], unknown[]]> {
  const result: T[] = [];
  const errors: unknown[] = [];

  await promises.reduce(async (p, next) => {
    await p;
    return next
      .then(e => {
        result.push(e);
      })
      .catch(error => {
        errors.push(error);
      });
  }, Promise.resolve());
  return [result, errors];
}
