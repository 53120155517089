import { convertObjectToQueryString } from 'common/utils/queryObjectToString';
import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { AssetState } from 'modules/api/locHub/assets/asset/state/state';
import { useAssetsSortedBy } from 'modules/api/locHub/assets/query/sortedList/sortedList';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { useJobDto } from 'modules/api/locHub/jobs/query/detail/detail';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { AssetsTable } from '../../../../components/AssetsTable/AssetsTable';
import Wizard from '../../../../components/Wizard/Wizard';
import { WizardStep } from '../../../../components/WizardStep/WizardStep';
import { LanguageInfo } from '../TaskCreatePage';
import { ConfirmStep } from './ConfirmStep';
import { createLanguageInfo } from './utilities';

export const ExistingAssetWizard: React.FC = (): ReactElement => {
  const jobId = useParams<{ jobId: JobId }>().jobId;
  const job = useJobDto(jobId);
  const [index, setIndex] = useState(0);
  const [selection, setSelection] = useState<AssetDto[]>([]);
  const [languageInfo, setLanguageInfo] = useState<LanguageInfo<AssetDto>[]>([]);
  const options = {
    sort: 'name',
    query: convertObjectToQueryString({ projectId: job.success ? job.data.projectId : undefined }),
  };
  const assets = useAssetsSortedBy(options);

  useEffect(() => {
    // Only show the next steps if the selection contains assets
    setIndex(selection.length > 0 ? 1 : 0);

    // Update the language info based on the added and removed assets
    const existingEntities = languageInfo.filter(l => selection.find(e => e.id === l.data.id) !== undefined);
    const newAssets = selection.filter(e => languageInfo.find(l => e.id === l.data.id) === undefined);
    const info = newAssets.map(a => createLanguageInfo(a, job.success ? job.data : undefined));

    setLanguageInfo([...existingEntities, ...info]);
  }, [selection]);

  const setSingleLanguageInfo = (info: LanguageInfo<AssetDto>): void => {
    setLanguageInfo(old => {
      const oldIndex = old.findIndex(o => o.data.id === info.data.id);
      return old.map((o, i) => {
        if (oldIndex !== i) return o;
        return {
          ...o,
          sourceLanguage: info.sourceLanguage,
          task: info.task,
        };
      });
    });
  };
  if (!assets.success || !job.success) {
    return <LoadingPage />;
  }
  return (
    <Wizard index={index} onIndexChange={setIndex}>
      <WizardStep i18nKey={'Overview.TaskCreatePage.existingAssetStep'}>
        <AssetsTable
          i18nKey="Overview.TaskCreatePage.existingAssetStep.assetTable"
          assets={assets.data.filter((asset: AssetDto) => {
            return asset.projectId === job.data.projectId && asset.state === AssetState.COMPLETE;
          })}
          value={selection}
          onChange={setSelection}
        />
      </WizardStep>
      <ConfirmStep
        i18nKey="Overview.TaskCreatePage.summary"
        jobId={jobId}
        languageInfo={languageInfo}
        setLanguageInfo={setSingleLanguageInfo}
      />
    </Wizard>
  );
};
