import { UUIDv4 } from 'modules/types/uuid/v4/v4';

import { EntityRelation } from '../../relation/relation';
import { QueryFragment } from './fragment/fragment';
import { QueryOperator } from './operator/operator';

export class QueryBuilder<Entity> {
  private readonly operations: (() => QueryFragment)[] = [];

  public addBoolean(key: Extract<keyof Entity, string>, value: boolean | 'both'): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.EQUAL, `${value}`);
    });
    return this;
  }

  public addEqual(key: Extract<keyof Entity, string>, value: string | number): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.EQUAL, `${value}`);
    });
    return this;
  }

  public addNotEqual(key: Extract<keyof Entity, string>, value: string | number): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.NOT_EQUAL, `${value}`);
    });
    return this;
  }

  public addStartsWith(key: Extract<keyof Entity, string>, value: string): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.LIKE, `${value}%`);
    });
    return this;
  }

  public addEndsWith(key: Extract<keyof Entity, string>, value: string): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.LIKE, `%${value}`);
    });
    return this;
  }

  public addLike(key: Extract<keyof Entity, string>, value: string): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.LIKE, `%${value}%`);
    });
    return this;
  }

  public addNewerThan(key: Extract<keyof Entity, string>, value: Date): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.GREATER_THAN, value.toISOString());
    });
    return this;
  }

  public addOlderThan(key: Extract<keyof Entity, string>, value: Date): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.LOWER_THAN, value.toISOString());
    });
    return this;
  }

  public addRelation(key: EntityRelation, id: UUIDv4): QueryBuilder<Entity> {
    this.operations.push(() => {
      return new QueryFragment(key, QueryOperator.EQUAL, id);
    });
    return this;
  }

  public build(): string {
    const queries: Map<string, string> = new Map();
    for (const getFragment of this.operations) {
      const queryFragment = getFragment();
      const key = queryFragment.getKey();
      if (queries.has(key)) {
        throw new Error(`Duplicated query key: ${key}`);
      }
      queries.set(key, queryFragment.compose());
    }
    return Array.from(queries.values()).join(',');
  }
}
