import { useApiError } from 'common/hooks/useApiError';
import { Api } from 'modules/api/api';
import { Job } from 'modules/api/locHub/jobs/job/job';
import { TaskError } from 'modules/api/locHub/tasks/task/error/error';
import { TaskId } from 'modules/api/locHub/tasks/task/id/id';
import { ComputedStatus, TapiccStatus, TaskStatus } from 'modules/api/locHub/tasks/task/status/status';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../../common/language';
import { getTranslator } from '../../../../common/utils/environment';
import { HtmlProps } from '../../../../common/utils/HTMLProps';
import { Dropdown, DropdownDivider, DropdownOption } from '../../../../components/Dropdown/Dropdown';
import { useMessage } from '../../../../components/Message/MessageProvider';

export interface Props extends Translatable, HtmlProps {
  job: Job;
  taskId: TaskId;
  taskError: TaskError | null;
  taskIsDownloadingAssets: boolean;
  taskIsReadyToBeConfirmed: boolean;
  taskIsTranslated: boolean;
  actions: Actions;
}

export interface Actions {
  setTaskStatus: (id: TaskId, status: TaskStatus, nextStatuses: TaskStatus[]) => void;
  setTaskError: (id: TaskId, error: TaskError) => void;
}

export const DropdownActions: React.FC<Props> = ({
  i18nKey,
  className,
  id,
  job,
  taskId,
  taskError,
  taskIsDownloadingAssets,
  taskIsReadyToBeConfirmed,
  taskIsTranslated,
  actions: { setTaskStatus, setTaskError },
}): ReactElement | null => {
  const apiError = useApiError();
  const [t] = useTranslation();
  const message = useMessage();
  if (taskIsDownloadingAssets || taskError) {
    return null;
  }
  const phaseActions: ReactElement[] = [];

  /* Actions */
  const onMarkForTranslation = async (): Promise<void> => {
    try {
      await Api.locHub.tasks.setStatus(taskId, TapiccStatus.CONFIRMED);
      setTaskStatus(taskId, TapiccStatus.CONFIRMED, [TapiccStatus.IN_PROGRESS]);
      message.success(t(`${i18nKey}.actions.markForTranslation.success`, { count: 1 }));
    } catch (e) {
      setTaskError(taskId, { id: 'actions.markForTranslation.error', parameters: {} });
      message.error(t(`${i18nKey}.actions.markForTranslation.failure`, { count: 1 }));
    }
  };

  const onUploadToSource = async (): Promise<void> => {
    try {
      const response = await Api.locHub.tasks.upload(taskId);
      if (Object.keys(response.errors).length) {
        throw Object.values(response.errors)[0];
      }
      setTaskStatus(taskId, ComputedStatus.CLOSED, []);
      message.success(t(`${i18nKey}.actions.publishToSource.success`, { count: 1 }));
    } catch (e) {
      setTaskError(taskId, { id: 'actions.publishToSource.error', parameters: {} });
      apiError.locHub.handle(e, t(`${i18nKey}.actions.publishToSource.failure`, { count: 1 }));
    }
  };

  if (taskIsReadyToBeConfirmed) {
    phaseActions.push(
      <DropdownOption
        i18nKey={`${i18nKey}.markForTranslation`}
        i18nArgs={{
          translator: getTranslator(),
          context: getTranslator() && 'known',
        }}
        key={TapiccStatus.PENDING}
        onClick={onMarkForTranslation}
      />,
    );
  }
  if (job.mayUploadTasks() && taskIsTranslated) {
    phaseActions.push(
      <DropdownOption
        i18nKey={`${i18nKey}.publishToSource`}
        key={TapiccStatus.COMPLETED}
        icon="cloud-upload-alt"
        onClick={onUploadToSource}
      />,
    );
  }

  return (
    <Dropdown direction="right" className={className} id={id}>
      {phaseActions}
      {phaseActions.length > 0 && <DropdownDivider />}
      <DropdownOption
        i18nKey={`${i18nKey}.downloadOriginalAsset`}
        icon="file-download"
        onClick={async (): Promise<void> => {
          try {
            await Api.locHub.tasks.downloadInputs(taskId);
          } catch (error) {
            apiError.locHub.handle(t('Overview.JobInfoPage.downloadError'));
          }
        }}
      />
    </Dropdown>
  );
};
