import { Api } from 'modules/api/api';
import { QueryConfiguration } from 'modules/api/locHub/entity/query/configuration/configuration';
import { useLocHubListAllQuery } from 'modules/api/locHub/entity/query/listAll/listAll';
import { QueryResult } from 'modules/api/locHub/entity/query/query';

import { LanguageDto } from '../../language/language';

export const useLanguages = (
  configuration: QueryConfiguration<LanguageDto[] | undefined, unknown> = { error: {}, query: {} },
): QueryResult<LanguageDto[]> => {
  return useLocHubListAllQuery(Api.locHub.languages, configuration);
};
