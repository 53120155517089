import { CmsConfiguration } from 'common/interfaces/xillio';
import { Api } from 'modules/api/api';
import { XillioAssetDto } from 'modules/api/locHub/assets/asset/asset';
import { InputType } from 'modules/api/locHub/inputs/input/type/type';
import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { JobDto } from 'modules/api/locHub/jobs/job/job';
import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import { TapiccStatus } from 'modules/api/locHub/tasks/task/status/status';
import { TaskType } from 'modules/api/locHub/tasks/task/type/type';
import { Dispatch, SetStateAction } from 'react';

import { isError } from '../../../../common/interfaces/common';
import { XillioEntity } from '../../../../common/interfaces/xillio';
import { getEntityId } from '../../../../components/FileBrowser/utilities';
import { LanguageDto } from '../../../../modules/api/locHub/languages/language/language';
import { LanguageInfo } from '../TaskCreatePage';

export function createLanguageInfo(
  e: XillioEntity,
  languages: Pick<LanguageDto, 'code'>[],
  job: undefined | Pick<JobDto, 'defaultSourceLanguage' | 'defaultTargetLanguages'>,
): LanguageInfo<XillioEntity> {
  return {
    data: e,
    sourceLanguage: {
      code:
        (languages.map(l => l.code).includes(e.original.language?.tag || '')
          ? e.original.language?.tag
          : job?.defaultSourceLanguage) || '',
      cms: e.original.language?.tag,
      default: job?.defaultSourceLanguage || '',
    },
    targetLanguageCodes: job?.defaultTargetLanguages || [],
    task: {
      status: undefined,
      error: undefined,
    },
  };
}

export type AssetCache = { [id: string]: AssetCacheEntry };
type AssetCacheEntry = Omit<XillioAssetDto, 'createdAt' | 'updatedAt'>;

export async function submit(
  projectId: undefined | ProjectId,
  jobId: undefined | JobId,
  languageInfo: LanguageInfo<XillioEntity>[],
  setLanguageInfo: Dispatch<LanguageInfo<XillioEntity>>,
  assets: AssetCache,
  setAssets: Dispatch<SetStateAction<AssetCache>>,
): Promise<void> {
  if (!projectId) {
    return Promise.reject('noProjectID');
  }
  if (!jobId) {
    return Promise.reject('noJobID');
  }
  const configurations = new Map<string, CmsConfiguration>();
  for (const configuration of await Api.locHub.xillioApi.getConfigurations()) {
    configurations.set(configuration.id, configuration);
  }

  const todo = languageInfo.filter(info => info.task.status === undefined || info.task.status === 'error');
  await Promise.all(
    todo.map(async info => {
      setLanguageInfo({
        ...info,
        task: {
          status: 'pending',
          error: undefined,
        },
      });

      const id = getEntityId(info.data);
      let asset: AssetCacheEntry;
      if (!(id in assets)) {
        const dummyAsset = info.data;
        if (!configurations.has(dummyAsset.configurationId)) {
          throw new Error(`Configuration ${dummyAsset.configurationId} not found.`);
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const configuration = configurations.get(dummyAsset.configurationId)!;
        if (!configuration.configurationType) {
          throw new Error(`Configuration type for configuration ${dummyAsset.configurationId} not found.`);
        }
        try {
          asset = await Api.locHub.assets.createFromXillioEngine({
            configurationId: configuration.id,
            configurationType: configuration.configurationType,
            contentUpdatedAt: info.data.original.modified?.date,
            language: info.sourceLanguage.code,
            path: dummyAsset.path,
            projectId: projectId,
          });
          setAssets(old => ({ ...old, [id]: asset }));
        } catch (e) {
          setLanguageInfo({ ...info, task: { status: 'error', error: { key: 'assetCreationError' } } });
          return;
        }
      } else {
        asset = assets[id];
      }
      try {
        for (const targetLanguage of info.targetLanguageCodes) {
          const task = await Api.locHub.tasks.create({
            jobId,
            name: asset.name,
            sourceLanguage: info.sourceLanguage.code,
            targetLanguage: targetLanguage,
            status: TapiccStatus.PENDING,
            type: TaskType.TRANSLATION,
          });
          await Api.locHub.inputs.create({
            assetId: asset.id,
            inputAs: InputType.SOURCE,
            taskId: task.id,
          });
        }
        setLanguageInfo({ ...info, task: { status: 'success', error: undefined } });
      } catch (e) {
        if (isError(e)) {
          setLanguageInfo({ ...info, task: { status: 'error', error: { key: e.error } } });
        } else {
          setLanguageInfo({ ...info, task: { status: 'error', error: { key: e.message } } });
        }
      }
    }),
  );
}
