import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { useJobDto } from 'modules/api/locHub/jobs/query/detail/detail';
import { useLanguages } from 'modules/api/locHub/languages/query/list/list';
import { LoadingPage } from 'pages/Common/LoadingPage/LoadingPage';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { XillioEntity } from '../../../../common/interfaces/xillio';
import { FileBrowser } from '../../../../components/FileBrowser/FileBrowser';
import { equal } from '../../../../components/FileBrowser/utilities';
import Wizard from '../../../../components/Wizard/Wizard';
import { WizardStep } from '../../../../components/WizardStep/WizardStep';
import { NextButton } from '../NextButton';
import { LanguageInfo } from '../TaskCreatePage';
import { ConfirmStep } from './ConfirmStep';
import { createLanguageInfo } from './utilities';

/* eslint-disable @typescript-eslint/no-non-null-assertion,react-hooks/exhaustive-deps */

export const CMSWizard: React.FC = (): ReactElement => {
  const jobId = useParams<{ jobId: JobId }>().jobId;
  const job = useJobDto(jobId);
  const languages = useLanguages();
  const [index, setIndex] = useState(0);
  const [entities, setEntities] = useState<XillioEntity[]>([]);
  const [languageInfo, setLanguageInfo] = useState<LanguageInfo<XillioEntity>[]>([]);

  useEffect(() => {
    const existingEntities = languageInfo.filter(l => entities.find(e => equal(e, l.data)) !== undefined);
    const newEntities = entities.filter(e => languageInfo.find(l => equal(e, l.data)) === undefined);
    const info = newEntities.map(e =>
      createLanguageInfo(e, languages.success ? languages.data : [], job.success ? job.data : undefined),
    );
    setLanguageInfo([...existingEntities, ...info]);
  }, [entities]);

  const setSingleLanguageInfo = (info: LanguageInfo<XillioEntity>): void => {
    setLanguageInfo(old => {
      const oldIndex = old.findIndex(o => equal(o.data, info.data));
      return old.map((o, i) => {
        if (oldIndex !== i) return o;
        return {
          ...o,
          sourceLanguage: info.sourceLanguage,
          task: info.task,
        };
      });
    });
  };

  if (!job.success || !languages.success) {
    return <LoadingPage />;
  }

  return (
    <Wizard startIndex={index} onIndexChange={setIndex}>
      <WizardStep i18nKey="Overview.TaskCreatePage.entityBrowser">
        <FileBrowser i18nKey="Overview.TaskCreatePage.entityBrowser" selection={entities} setSelection={setEntities} />
        <hr />
        <NextButton i18nKey="Overview.TaskCreatePage" disabled={entities.length === 0} index={0} setIndex={setIndex} />
      </WizardStep>
      <ConfirmStep
        i18nKey="Overview.TaskCreatePage.summary"
        jobId={jobId}
        projectId={job.data.projectId}
        languages={languages.data.map(l => l.code)}
        languageInfo={languageInfo}
        setLanguageInfo={setSingleLanguageInfo}
      />
    </Wizard>
  );
};
