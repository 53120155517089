import { CustomStatus } from 'components/CustomStatus/CustomStatus';
import { Colors } from 'modules/ui/colors/colors';
import React, { ReactElement } from 'react';

interface Props {
  texts: {
    error: string;
    errorMessage: string | undefined;
  };
}

export const TaskStatusError: React.FC<Props> = ({ texts }): ReactElement | null => {
  if (!texts.errorMessage) {
    return null;
  }

  const getStatus = (version: 'desktop' | 'mobile'): ReactElement => {
    return (
      <span className={version === 'mobile' ? 'is-hidden-tablet' : 'is-hidden-mobile'}>
        <CustomStatus color={Colors.DANGER}>
          <span
            className={`has-tooltip-${version === 'mobile' ? 'right' : 'left'} has-tooltip-danger`}
            data-tooltip={texts.errorMessage}
          >
            {texts.error}
          </span>
        </CustomStatus>
      </span>
    );
  };

  return (
    <React.Fragment>
      {getStatus('mobile')}
      {getStatus('desktop')}
    </React.Fragment>
  );
};
