import { XillioEntity } from 'common/interfaces/xillio';
import { Api } from 'modules/api/api';

import { LocHub } from '../../lochub';
import { ConfigurationId } from './id/id';

export class XillioEntities {
  public static readonly path: string = '/api/internal/xillio/entities';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(XillioEntities.path, parameters);
  }

  private static getDetailUrl(configurationId: ConfigurationId, path: string, scope: string): URL {
    return Api.getUrl(`${XillioEntities.path}/${configurationId}${path}`, {
      scope,
    });
  }

  public static async get(
    configurationId: ConfigurationId,
    path = '',
    scope = 'path_children_entity',
  ): Promise<XillioEntity[]> {
    return (
      (await LocHub.getJson<Record<string, XillioEntity[]>>(XillioEntities.getDetailUrl(configurationId, path, scope)))[
        scope
      ] ?? []
    );
  }

  public static async getAll(): Promise<XillioEntity[]> {
    return (
      (await LocHub.getJson<Record<string, XillioEntity[]>>(XillioEntities.getBaseUrl()))['path_children_entity'] ?? []
    );
  }
}
