import { useApiError } from 'common/hooks/useApiError';
import { Page } from 'common/interfaces/common';
import { Extended } from 'common/types/extended/extended';
import { Box } from 'components/Box/Box';
import { Api } from 'modules/api/api';
import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import { InputDto } from 'modules/api/locHub/inputs/input/input';
import { useTask } from 'modules/api/locHub/tasks/query/detail/detail';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useAsyncState } from '../../../common/hooks/useAsyncState';
import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { HomePageCrumb } from '../HomePage/HomePage';
import { JobsListPageCrumb } from '../JobsListPage/JobsListPage';
import { DataTable } from './DataTable';
import { InputDetailRow } from './InputDetailRow';
import { Metadata } from './Metadata/Metadata';

export const TaskInfoPageCrumb: Breadcrumb = {
  page: 'Overview.TaskInfoPage',
  icon: 'info',
  location: '/jobs',
};

export const TaskInfoPage: React.FC = (): ReactElement | null => {
  const apiError = useApiError();
  const history = useHistory();
  const [t] = useTranslation();
  const taskId = useParams<{ taskId: string }>().taskId;
  const [inputs] = useAsyncState(async () => {
    try {
      const taskInputs = await Api.locHub.inputs.getManyByTask(taskId, currentPage, { limit: 50 });
      const inputsWithAssets: Extended<InputDto, { asset: AssetDto }>[] = [];
      for (const input of taskInputs.content) {
        const asset = await Api.locHub.assets.getDto(input.assetId);
        inputsWithAssets.push({
          ...input,
          asset,
        });
      }
      return { ...taskInputs, content: inputsWithAssets };
    } catch (error) {
      history.push('/jobs');
      apiError.locHub.handle(error, t('Overview.TaskInfoPage.errorNotFound', { taskId: taskId }));
    }
  }, [taskId]);
  const task = useTask(taskId, {
    error: {
      handler: (error: Error): void => {
        history.push('/jobs');
        apiError.locHub.handle(error, t('Overview.TaskInfoPage.errorNotFound'));
      },
    },
    query: {},
  });
  const [currentPage, setCurrentPage] = useState(1);
  if (!task.success || !inputs) {
    return null;
  }
  const pageInfo: Page<InputDto[]> = {
    content: [],
    page: currentPage,
    pageSize: 50,
    totalElements: inputs.totalElements,
  };

  return (
    <AppLayout breadcrumbs={[HomePageCrumb, JobsListPageCrumb, TaskInfoPageCrumb]} className="jobs-info">
      {task ? (
        <>
          <Metadata i18nKey={TaskInfoPageCrumb.page} task={task.data} />
          <Box i18nKey="Overview.TasksInfoPageInputTable">
            <DataTable
              i18nKey="Overview.TasksInfoPageInputTable"
              columns={['name', 'type', 'sourceLanguage', 'targetLanguage', 'delivered', '']}
              pageInfo={pageInfo}
              setPage={setCurrentPage}
            >
              {inputs.content.map(input => (
                <InputDetailRow
                  key={input.id}
                  i18nKey="Overview.TasksInfoPageInputTable"
                  asset={input.asset}
                  input={input}
                  task={task.data}
                />
              ))}
            </DataTable>
          </Box>
        </>
      ) : (
        <i className="fas fa-spinner" />
      )}
    </AppLayout>
  );
};
