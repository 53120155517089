import { AssetDto } from 'modules/api/locHub/assets/asset/asset';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageTag } from '../LanguageTag/LanguageTag';
import { Table } from '../Table/Table';
import { TD } from '../TD/TD';

export interface Props {
  i18nKey: string;
  assets?: AssetDto[];
  value: AssetDto[];
  onChange?: (data: AssetDto[]) => void;
}

export const AssetsTable: React.FC<Props> = ({ i18nKey, onChange, value, assets }): ReactElement => {
  const [t] = useTranslation();
  const columns = ['name', 'description', 'sourceLanguage', 'createdAt'];

  const setSelection = (data: AssetDto[]): void => onChange && onChange(data);
  const toggleSelection = (asset: AssetDto, check: boolean): void => {
    if (check) {
      setSelection([...value, asset]);
    } else {
      setSelection(value.filter(({ id }) => id !== asset.id));
    }
  };

  let content = (
    <tbody>
      <tr>
        <td className={'has-text-centered'} colSpan={columns.length}>
          <i className="fas fa-spin fa-spinner fa-3x" />
        </td>
      </tr>
    </tbody>
  );

  if (assets) {
    content = (
      <>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={value.length === assets?.length}
                onChange={(e): void => {
                  if (e.target.checked) {
                    setSelection(assets);
                  } else {
                    setSelection([]);
                  }
                }}
              />
            </th>
            {columns.map(
              (column): ReactElement => (
                <th className="asset-table-headers" key={column}>
                  {t([`${i18nKey}.${column}`, column])}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {assets.map(
            (asset: AssetDto): ReactElement => (
              <tr key={asset.id} onClick={(): void => toggleSelection(asset, !value.find(({ id }) => id === asset.id))}>
                <td>
                  <input
                    type="checkbox"
                    checked={!!value.find(({ id }) => id === asset.id)}
                    onChange={(e): void => toggleSelection(asset, e.target.checked)}
                  />
                </td>
                <TD wrap>{asset.name}</TD>
                <TD wrap>{asset.description}</TD>
                <TD>
                  <LanguageTag language={asset.language} />
                </TD>
                <TD>{t('locale.date', { date: new Date(asset.createdAt) })}</TD>
              </tr>
            ),
          )}
        </tbody>
      </>
    );
  }

  return (
    <>
      <div className="is-clearfix" />
      <div className="asset-table table-container">
        <Table>{content}</Table>
      </div>
    </>
  );
};
