// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyPage: Page<any> = {
  content: [],
  page: 1,
  pageSize: 0,
  totalElements: 0,
};

export interface Page<T> {
  content: T[];
  page: number;
  pageSize: number;
  totalElements: number;
}
export interface QueryString {
  archived?: 'both' | 'true' | 'false';
  projectId?: string;
}

export interface Pagination {
  limit?: number;
  sort?: string;
  direction?: 'ASC' | 'DESC';
  query?: string;
}

export interface ValidationError {
  errors: {
    codes: string[];
    field: string;
  }[];
}

export interface ApiError {
  status: number;
  error: string;
  message: string;
  path: string;
  timestamp: string;
}

export function isError(object: object): object is ApiError {
  return 'error' in object;
}

export function isValidationError(error: unknown): error is ValidationError {
  return typeof error === 'object' && error != null && 'errors' in error;
}
