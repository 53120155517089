import { useApiError } from 'common/hooks/useApiError';
import { CmsConfiguration, XillioEntity } from 'common/interfaces/xillio';
import { Columns } from 'components/Columns/Columns';
import { AutomationAcceptTranslation } from 'components/HookForm/Entity/Automation/AcceptTranslation/AcceptTranslation';
import { AutomationDelayInput } from 'components/HookForm/Entity/Automation/Delay/Delay';
import { AutomationDescriptionInput } from 'components/HookForm/Entity/Automation/Description/Description';
import { AutomationMarkForTranslation } from 'components/HookForm/Entity/Automation/MarkForTranslation/MarkForTranslation';
import { AutomationNameInput } from 'components/HookForm/Entity/Automation/Name/Name';
import { AutomationPathsInput } from 'components/HookForm/Entity/Automation/Paths/Paths';
import { AutomationPeriodInput } from 'components/HookForm/Entity/Automation/Period/Period';
import { AutomationRecursiveCrawl } from 'components/HookForm/Entity/Automation/RecursiveCrawl/RecursiveCrawl';
import { AutomationSourceLanguageSelect } from 'components/HookForm/Entity/Automation/SourceLanguage/SourceLanguage';
import { AutomationTargetLanguagesSelect } from 'components/HookForm/Entity/Automation/TargetLanguages/TargetLanguages';
import { CmsSelect } from 'components/HookForm/Entity/Common/Cms/Cms';
import { ProjectSelect } from 'components/HookForm/Entity/Common/Project/Project';
import { CancelInput } from 'components/HookForm/Input/Cancel/Cancel';
import { ResetInput } from 'components/HookForm/Input/Reset/Reset';
import { SubmitInput } from 'components/HookForm/Input/Submit/Submit';
import { useMessage } from 'components/Message/MessageProvider';
import { Api } from 'modules/api/api';
import { AutomationDto } from 'modules/api/locHub/automations/automation/automation';
import { LanguageDto } from 'modules/api/locHub/languages/language/language';
import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import { ProjectDto } from 'modules/api/locHub/projects/project/project';
import { IETFLanguageCode } from 'modules/types/language/ietf/ietf';
import React, { FormEvent, ReactElement, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';

import { AutomationEditPageI18n } from './I18n';

interface Form {
  projectId: ProjectId;
  cmsId: string;
  automationName: string;
  automationDescription: string;
  automationPaths: XillioEntity[];
  automationPeriod: string;
  automationDelay: string;
  automationSourceLanguage: Set<IETFLanguageCode>;
  automationSourceLanguagesFilter: Set<IETFLanguageCode>;
  automationTargetLanguages: Set<IETFLanguageCode>;
  automationAcceptTranslation: boolean;
  automationMarkForTranslation: boolean;
  automationRecursiveCrawl: boolean;
}

interface Props {
  automation: AutomationDto;
  projects: ProjectDto[];
  languages: LanguageDto[];
  cmsConfigurations: CmsConfiguration[];
}

export const AutomationEditForm: React.FC<Props> = ({
  automation,
  projects,
  languages,
  cmsConfigurations,
}): ReactElement => {
  const [t] = useTranslation();
  const message = useMessage();
  const apiError = useApiError();
  const { watch, register, control, handleSubmit, reset, errors, formState } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      projectId: automation.projectId,
      cmsId: automation.engineConfigurationId,
      automationName: automation.name,
      automationDescription: automation.description ?? '',
      automationPeriod: automation.period.toString(),
      automationDelay: automation.delay.toString(),
      automationSourceLanguage: new Set(automation.sourceLanguageFilter),
      automationTargetLanguages: new Set(automation.targetLanguages),
      automationSourceLanguagesFilter: new Set(automation.sourceLanguageFilter),
      automationMarkForTranslation: automation.autoMarkForTranslation,
      automationAcceptTranslation: automation.autoAcceptTranslation,
      automationRecursiveCrawl: automation.recursiveCrawl,
      automationPaths: automation.paths.map(path => {
        return {
          path,
          configurationId: automation.engineConfigurationId,
        };
      }),
    },
  });
  const sourceLanguage = watch('automationSourceLanguage');
  const targetLanguages = watch('automationTargetLanguages');
  const cmsId = watch('cmsId');
  useEffect(() => {
    if (formState.dirtyFields.cmsId) {
      control.setValue('automationPaths', [], {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsId]);
  const cms = cmsConfigurations.find(configuration => {
    return configuration.id === cmsId;
  });
  const translationScopeSupport = cms
    ? Api.locHub.xillioApi.connectors.supportsTranslationScope(cms.configurationType)
    : false;
  /* Actions */
  const submitAction: SubmitHandler<Form> = async (data: UnpackNestedValue<Form>): Promise<void> => {
    try {
      await Api.locHub.automations.update(automation.id, {
        active: true,
        projectId: data.projectId,
        engineConfigurationId: data.cmsId,
        name: data.automationName,
        description: data.automationDescription,
        autoAcceptTranslation: data.automationAcceptTranslation,
        autoMarkForTranslation: data.automationMarkForTranslation,
        delay: Number.parseInt(data.automationDelay, 10),
        period: Number.parseInt(data.automationPeriod, 10),
        paths: data.automationPaths.map(item => {
          return item.path;
        }),
        recursiveCrawl: data.automationRecursiveCrawl,
        sourceLanguageFilter: translationScopeSupport
          ? Array.from(data.automationSourceLanguagesFilter)
          : Array.from(data.automationSourceLanguage),
        targetLanguages: Array.from(data.automationTargetLanguages),
      });
      reset(data);
      message.success(t(`${AutomationEditPageI18n.configuration.namespace}.automationUpdateSuccess`));
    } catch (error) {
      apiError.locHub.handle(error, t(`${AutomationEditPageI18n.configuration.namespace}.automationUpdateFailure`));
    }
  };
  const resetAction = (event: FormEvent): void => {
    event.preventDefault();
    reset();
  };
  return (
    <form onSubmit={handleSubmit(submitAction)} onReset={resetAction}>
      <Columns>
        <ProjectSelect control={control} errors={errors} projects={projects} disabled={true} />
        <CmsSelect control={control} errors={errors} configurations={cmsConfigurations} disabled={true} />
        <AutomationNameInput register={register} errors={errors} />
        <AutomationDescriptionInput register={register} errors={errors} />
        <AutomationPeriodInput register={register} errors={errors} />
        <AutomationDelayInput register={register} errors={errors} />
        <AutomationMarkForTranslation control={control} />
        <AutomationAcceptTranslation control={control} />
        <AutomationPathsInput control={control} cmsConfiguration={cms} />
        <AutomationRecursiveCrawl control={control} />
        <AutomationSourceLanguageSelect
          control={control}
          errors={errors}
          languages={languages}
          invalidOptions={!translationScopeSupport ? targetLanguages : new Set()}
          translationScopeSupport={translationScopeSupport}
        />
        <AutomationTargetLanguagesSelect
          control={control}
          errors={errors}
          languages={languages}
          invalidOptions={sourceLanguage && !translationScopeSupport ? sourceLanguage : new Set()}
        />
      </Columns>
      <div className={'buttons is-pulled-right'}>
        <SubmitInput loading={formState.isSubmitting} disabled={!formState.isValid || !formState.isDirty} />
        <ResetInput disabled={formState.isSubmitting} />
        <CancelInput to={'/admin/automations'} />
      </div>
    </form>
  );
};
