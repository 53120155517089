import { Button } from 'components/Button/Button';
import { Password } from 'components/Input';
import { LandingLayout } from 'components/LandingLayout/LandingLayout';
import { LoadingButton } from 'components/LoadingButton/LoadingButton';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Api } from '../../../modules/api/api';

const Confirm: React.FC = (): ReactElement => {
  const [t] = useTranslation('');
  return (
    <LandingLayout id="recovery">
      <h3 className="title">{t('Authentication.ChangePasswordPageConfirmation.title')}</h3>
      <p>{t('Authentication.ChangePasswordPageConfirmation.description')}</p>
      <Link to="/login">{t('Authentication.ChangePasswordPageConfirmation.loginLink.text')}</Link>
    </LandingLayout>
  );
};

export const ChangePasswordPage: React.FC = (): ReactElement => {
  const [t] = useTranslation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();
  const { token, userId } = useParams();

  if (success) {
    return <Confirm />;
  }

  return (
    <LandingLayout id="recovery">
      <h3 className="title">{t('Authentication.ChangePasswordPage.title')}</h3>
      <p>{t('Authentication.ChangePasswordPage.description')}</p>

      <form
        className="reset-form"
        onSubmit={async (event): Promise<void> => {
          event.preventDefault();
          if (password !== confirmPassword) {
            setError('passwordMatchError');
            return;
          }
          setLoading(true);
          setError(undefined);
          try {
            await Api.changePassword(userId || '', token || '', password);
            setSuccess(true);
          } catch (e) {
            console.error(e);
            setError('changeFailedError');
          } finally {
            setLoading(false);
          }
        }}
      >
        <Password value={password} onChange={setPassword} i18nKey="Authentication.ChangePasswordPage.passwordField" />
        <Password
          name="confirm"
          value={confirmPassword}
          onChange={setConfirmPassword}
          i18nKey="Authentication.ChangePasswordPage.confirmField"
          validate={(event): void => {
            if (event.target.value !== password) {
              event.target.setCustomValidity(t('Authentication.ChangePasswordPage.passwordMatchError'));
            } else {
              event.target.setCustomValidity('');
            }
          }}
        />

        {error && <p className="help is-danger">{t([`Authentication.ChangePasswordPage.${error}`])}</p>}

        <div className="field is-grouped-wide controls">
          <div className="control">
            <LoadingButton
              loading={loading}
              className="is-success"
              i18nKey="Authentication.ChangePasswordPage.confirmButton"
            />
          </div>
          <div className="control">
            <Button to="/login" i18nKey="Authentication.ChangePasswordPage.cancelButton" />
          </div>
        </div>
      </form>
    </LandingLayout>
  );
};
