import { FormField } from 'components/HookForm/Field/Field';
import { Toggle } from 'components/Toggle/Toggle';
import React, { ReactElement } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';

interface Props {
  id: string;
  name: string;
  label: string;
  description?: string;
  control: UseFormMethods['control'];
}

export const SwitchInput: React.FC<Props> = ({ id, name, label, description, control }): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }): ReactElement => {
        return (
          <FormField label={label} name={name}>
            <Toggle
              id={id}
              name={name}
              texts={{ label: description ?? '' }}
              value={value}
              actions={{
                toggle: onChange,
              }}
            />
          </FormField>
        );
      }}
    />
  );
};
