import { HttpError } from './error/error';
import { LocHub } from './locHub/lochub';
import { UserDto } from './locHub/users/user/user';

interface Token {
  accessToken: string;
}

let userData: UserDto | undefined;
let tokenData: Token | undefined;

export class Api {
  public static readonly locHub = LocHub;

  public static getUrl(path: string, parameters: Record<string, string | number | undefined> = {}): URL {
    const url = new URL(path, window.location.origin);
    for (const [name, value] of Object.entries(parameters)) {
      if (value) {
        url.searchParams.append(name, typeof value === 'string' ? value : value.toString());
      }
    }
    return url;
  }

  public static async getJson<Response>(url: URL, headers: Record<string, string> = {}): Promise<Response> {
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers,
    });
    if (response.status !== 200) {
      throw new HttpError(response, await response.json());
    } else {
      return Api.getJsonResponse(response);
    }
  }

  public static getUserDataFromCache(): UserDto | false {
    if (userData) {
      return userData;
    }
    if (!tokenData) {
      const storedTokenData = localStorage.getItem('token');
      if (storedTokenData) {
        tokenData = JSON.parse(storedTokenData);
      } else {
        // we have no token in cache
        return false;
      }
    }
    // we have no user data in cache
    throw Api.getUserData();
  }

  public static getTokenDetail(): Token {
    if (!tokenData) {
      // we have no token in cache
      throw new Error('User is not logged in');
    } else {
      return tokenData;
    }
  }

  public static setTokenData(token: Token, user: UserDto): void {
    tokenData = token;
    userData = user;
  }

  public static async getUserData(): Promise<UserDto> {
    const response = await fetch('api/internal/user', {
      headers: {
        Authorization: `Bearer ${this.getTokenDetail().accessToken}`,
      },
    });
    if (response.status === 200) {
      const result = await response.json();
      if (result) {
        userData = result;
        return result;
      }
    } else {
      // the token is not valid, drop it
      this.logout();
    }
    throw new Error('Could not parse user data');
  }

  public static async login(username: string, password: string): Promise<UserDto> {
    const response = await fetch('/oauth/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `client_id=app&grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(
        password,
      )}`,
    });
    const body = await response.json();
    if (body.access_token) {
      tokenData = {
        accessToken: body.access_token,
      };
      localStorage.setItem('token', JSON.stringify(tokenData));
    } else {
      throw new Error(`Failed to get token: ${JSON.stringify(body)}`);
    }
    return await Api.getUserData();
  }

  public static logout(): void {
    tokenData = undefined;
    userData = undefined;
    localStorage.clear();
  }

  public static async forgotPassword(email: string): Promise<void> {
    await fetch('/api/internal/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  }

  public static async changePassword(userId: string, token: string, password: string): Promise<void> {
    const result = await fetch('/api/internal/change-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, token, password }),
    });
    if (result.status !== 204) {
      throw new Error(`Failed to reset password: Code ${result.status} ${result.body && result.body.toString()}`);
    }
  }

  public static async getHttpError(response: Response): Promise<HttpError<unknown>> {
    try {
      const body = await response.json();
      return new HttpError(response, body);
    } catch (error) {
      return new HttpError(response, null);
    }
  }

  public static async getResponse(response: Response): Promise<Response> {
    if (!response.ok) {
      throw await Api.getHttpError(response);
    } else {
      return response;
    }
  }

  public static async getJsonResponse<Body>(response: Response): Promise<Body> {
    return (await Api.getResponse(response)).json();
  }

  public static async getBlobResponse(response: Response): Promise<Blob> {
    return (await Api.getResponse(response)).blob();
  }

  public static async downloadFile(url: URL, fileName: string, headers: Headers): Promise<void> {
    headers.append('content-type', 'application/octet-stream');
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers,
    });
    const blob = await Api.getBlobResponse(response);
    const anchor = window.document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
