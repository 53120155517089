import { queryCache } from 'react-query';

import { Page, Pagination } from '../../../../common/interfaces/common';
import { Api } from '../../api';
import { LocHub } from '../lochub';
import { UserId } from './user/id/id';
import { NewUser, UserDto } from './user/user';

export class Users {
  public static readonly path: string = 'api/internal/users';

  private static getBaseUrl(parameters: Record<string, string | number | undefined>): URL {
    return Api.getUrl(Users.path, parameters);
  }
  private static getDetailUrl(id: UserId): URL {
    return Api.getUrl(`${Users.path}/${id}`);
  }

  public static async getAll(): Promise<UserDto[]> {
    return LocHub.getAll(Users.path);
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<UserDto>> {
    return LocHub.getJson(Users.getBaseUrl({ page, ...options }));
  }

  public static async getDto(userId: UserId): Promise<UserDto> {
    return LocHub.getJson(Users.getDetailUrl(userId));
  }

  public static async create(user: NewUser): Promise<UserDto> {
    const result = await LocHub.postJson<NewUser, UserDto>(Users.getBaseUrl({}), user);
    queryCache.invalidateQueries(Users.path);
    return result;
  }

  public static async update(userId: UserId, user: Partial<UserDto>): Promise<UserDto> {
    const result = LocHub.patchJson(Users.getDetailUrl(userId), user);
    queryCache.invalidateQueries(Users.path);
    return result;
  }

  public static async delete(userId: UserId): Promise<void> {
    await LocHub.delete(Users.getDetailUrl(userId));
    queryCache.invalidateQueries(Users.path);
  }
}
