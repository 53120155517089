import { JobId } from 'modules/api/locHub/jobs/job/id/id';
import { ProjectId } from 'modules/api/locHub/projects/project/id/id';
import React, { Dispatch, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { XillioEntity } from '../../../../common/interfaces/xillio';
import { Translatable } from '../../../../common/language';
import { SourceLanguageDropdown } from '../../../../components/EntityOverview/SourceLanguageDropdown';
import { getEntityId, getEntityName } from '../../../../components/FileBrowser/utilities';
import { LanguageTag } from '../../../../components/LanguageTag/LanguageTag';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { AssetOverviewTable } from '../../../../components/Task/TaskSummary/AssetOverviewTable';
import { DistinctSourceLanguages } from '../../../../components/Task/TaskSummary/DistinctSourceLanguages';
import { DistinctTargetLanguages } from '../../../../components/Task/TaskSummary/DistinctTargetLanguages';
import { ToBeCreatedTasks } from '../../../../components/Task/TaskSummary/ToBeCreatedTasks';
import { TotalAssets } from '../../../../components/Task/TaskSummary/TotalAssets';
import Wizard from '../../../../components/Wizard/Wizard';
import { SubmitButton } from '../SubmitButton';
import { LanguageInfo } from '../TaskCreatePage';
import { AssetCache, submit } from './utilities';

export const ConfirmStep: React.FC<Translatable & {
  jobId: JobId;
  projectId: ProjectId;
  languages: string[];
  languageInfo: LanguageInfo<XillioEntity>[];
  setLanguageInfo: Dispatch<LanguageInfo<XillioEntity>>;
}> = ({ i18nKey, jobId, projectId, languages, languageInfo, setLanguageInfo }): ReactElement => {
  const [t] = useTranslation();
  const history = useHistory();
  const message = useMessage();
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<AssetCache>({});

  const onSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      const countTasks = languageInfo.reduce((acc, info) => acc + info.targetLanguageCodes.length, 0);
      await submit(projectId, jobId, languageInfo, setLanguageInfo, assets, setAssets);
      setLoading(false);
      message.success(t(`${i18nKey}.success`, { count: countTasks }));
      history.push('/jobs/' + jobId);
    } catch (e) {
      setLoading(false);
      message.error(t([`${i18nKey}.${e}`, `${i18nKey}.unknown`]));
    }
  };

  return (
    <Wizard.Step
      i18nKey={`${i18nKey}`}
      render={(): ReactElement => (
        <>
          <div className="asset-overview">
            <AssetOverviewTable
              i18nKey={i18nKey}
              languageInfo={languageInfo}
              render={(info, index): ReactElement => (
                <AssetOverviewRow
                  key={getEntityId(info.data)}
                  i18nKey={i18nKey}
                  languages={languages}
                  languageInfo={info}
                  setLanguageInfo={setLanguageInfo}
                  index={index}
                />
              )}
            />
          </div>
          <hr />
          <div className="general-overview">
            <TotalAssets i18nKey={i18nKey} languageInfo={languageInfo} />
            <ToBeCreatedTasks i18nKey={i18nKey} languageInfo={languageInfo} />
            <DistinctSourceLanguages i18nKey={i18nKey} languageInfo={languageInfo} />
            <DistinctTargetLanguages i18nKey={i18nKey} languageInfo={languageInfo} />
          </div>
          <hr />
          <SubmitButton i18nKey={i18nKey} loading={loading} disabled={loading} onClick={onSubmit} />
        </>
      )}
    />
  );
};

const AssetOverviewRow: React.FC<Translatable & {
  languages: string[];
  languageInfo: LanguageInfo<XillioEntity>;
  setLanguageInfo: Dispatch<LanguageInfo<XillioEntity>>;
  index?: number;
}> = ({ i18nKey, languages, languageInfo, setLanguageInfo, index }) => {
  const targetLanguages = languageInfo.targetLanguageCodes;
  targetLanguages.sort();

  return (
    <tr className="asset-info">
      <td>{getEntityName(languageInfo.data)}</td>
      <td style={{ overflow: 'initial' }}>
        <SourceLanguageDropdown
          i18nKey={i18nKey}
          languageInfo={languageInfo}
          languages={languages}
          value={languageInfo.sourceLanguage.code}
          onChange={(newLanguage): void =>
            setLanguageInfo({
              data: languageInfo.data,
              sourceLanguage: {
                ...languageInfo.sourceLanguage,
                code: newLanguage || languageInfo.sourceLanguage.default,
              },
              targetLanguageCodes: languageInfo.targetLanguageCodes,
              task: {
                status: undefined,
                error: undefined,
              },
            })
          }
          className={index === 0 ? 'is-down' : undefined}
        />
      </td>
      <td>
        {targetLanguages.map(lang => (
          <LanguageTag language={lang} key={lang} />
        ))}
      </td>
    </tr>
  );
};
