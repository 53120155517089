import { EntityName } from 'modules/api/locHub/entity/name/name';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NameInput } from '../../Common/Name/Name';
import { JobNameInputI18n } from './I18n';

interface Props {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  value?: string;
}

export const JobNameInput: React.FC<Props> = ({ value, register, errors }): ReactElement => {
  const maxLength = 256;
  const [t] = useTranslation();
  const namespace = JobNameInputI18n.configuration.namespace;
  const label = t(`${namespace}.label`);
  const placeholder = t(`${namespace}.placeholder`);
  const validationMaxLengthError = t(`${namespace}.validationMaxLengthError`, { maxLength });
  const validationRequiredError = t(`${namespace}.validationRequiredError`);
  return (
    <NameInput
      entity={EntityName.JOB}
      value={value}
      maxLength={maxLength}
      register={register}
      errors={errors}
      texts={{
        label,
        placeholder,
        validationMaxLengthError,
        validationRequiredError,
      }}
    />
  );
};
