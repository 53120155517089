export interface SelectOption {
  readonly id: string;
  readonly label: string;
}

export function getSelectedLabels(options: Map<string, SelectOption>, selected: Set<string>): string[] {
  return Array.from(selected)
    .map((id: string) => {
      const option: SelectOption | undefined = options.get(id);
      if (!option) {
        return null;
      }
      return option.label;
    })
    .filter(Boolean) as string[];
}

export function getSelectedOptions(options: Map<string, SelectOption>, selected: Set<string>): SelectOption[] {
  return Array.from(selected)
    .map((id: string) => {
      const option: SelectOption | undefined = options.get(id);
      if (!option) {
        return null;
      }
      return option;
    })
    .filter(Boolean) as SelectOption[];
}

export function normalize(text: string): string {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function matchesFilter(label: string, filterBy: string): boolean {
  return normalize(label).includes(normalize(filterBy));
}
