import React, { ReactElement, useEffect, useRef } from 'react';

import { Field } from '../Field/Field';
import { TestIds } from '../testIds';

export interface FilterInputActions {
  readonly filter: (by: string) => void;
  readonly clear: () => void;
}

export interface FilterInputTexts {
  label: string;
  placeholder: string;
}

export interface Props {
  readonly texts: FilterInputTexts;
  readonly icon: string;
  readonly name: string;
  readonly value: string;
  readonly actions: FilterInputActions;
}

export const FilterInput: React.FC<Props> = ({ texts, icon, name, value, actions }): ReactElement => {
  const ref: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement>(null);

  const filter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    actions.filter(event.target.value);
  };

  /* Effects */
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }, []);

  const rightIcon: string = value.length ? 'fa-times' : 'fa-search';
  const rightIconAction: (() => void) | undefined = value.length ? actions.clear : undefined;
  return (
    <Field
      texts={{ label: texts.label }}
      leftIcon={icon}
      rightIcon={rightIcon}
      rightIconAction={rightIconAction}
      required={true}
      name={name}
    >
      <input
        type="text"
        className="input"
        id={`${name}-input`}
        name={name}
        placeholder={texts.placeholder}
        value={value}
        required={true}
        ref={ref}
        data-testid={TestIds.FILTER_INPUT}
        autoComplete="off"
        onChange={filter}
      />
    </Field>
  );
};
