import { Page, Pagination } from 'common/interfaces/common';
import { Api } from 'modules/api/api';
import { queryCache } from 'react-query';

import { BulkResponse } from '../bulkResponse/bulkResponse';
import { QueryBuilder } from '../entity/query/builder/builder';
import { EntityRelation } from '../entity/relation/relation';
import { InputType } from '../inputs/input/type/type';
import { JobId } from '../jobs/job/id/id';
import { LocHub } from '../lochub';
import { TaskId } from './task/id/id';
import { TaskStatusFactory } from './task/status/factory/factory';
import { TapiccStatus, TaskStatus } from './task/status/status';
import { NewTask, Task, TaskDto } from './task/task';

export class Tasks {
  public static readonly path: string = '/api/internal/tasks';
  private static getBaseUrl(parameters: Record<string, string | number | undefined> = {}): URL {
    return Api.getUrl(Tasks.path, parameters);
  }

  private static getDetailUrl(id: TaskId): URL {
    return Api.getUrl(`${Tasks.path}/${id}`);
  }

  private static getCustomUrl(path: string): URL {
    return Api.getUrl(`${Tasks.path}/${path}`);
  }

  public static async getActiveRecord(task: TaskDto): Promise<Task> {
    const nextStatuses = await Tasks.getNextStatuses(task.id);
    const status = await TaskStatusFactory.create(task);
    return new Task(task, status.error, status.computed, nextStatuses);
  }

  public static async getMany(page: number, options?: Pagination): Promise<Page<Task>> {
    const result: Page<TaskDto> = await LocHub.getJson(Tasks.getBaseUrl({ page, ...options }));
    const tasks: Task[] = [];
    await Promise.all(
      result.content.map(async (task: TaskDto) => {
        tasks.push(await Tasks.getActiveRecord(task));
      }),
    );
    return {
      ...result,
      content: tasks,
    };
  }

  public static async getAll(): Promise<Task[]> {
    const result: TaskDto[] = await Api.locHub.getAll(Tasks.path);
    const tasks: Task[] = [];
    await Promise.all(
      result.map(async (task: TaskDto) => {
        tasks.push(await Tasks.getActiveRecord(task));
      }),
    );
    return tasks;
  }

  public static async getManyByJob(jobId: JobId, page: number, options?: Pagination): Promise<Page<Task>> {
    const result: Page<TaskDto> = await LocHub.getJson(
      Tasks.getBaseUrl({
        page,
        ...options,
        query: new QueryBuilder().addRelation(EntityRelation.JOB, jobId).build(),
      }),
    );
    const tasks: Task[] = [];
    await Promise.all(
      result.content.map(async (task: TaskDto) => {
        tasks.push(await Tasks.getActiveRecord(task));
      }),
    );
    return {
      ...result,
      content: tasks.sort((a, b) => {
        const aIndex = result.content.findIndex(item => item.id === a.id);
        const bIndex = result.content.findIndex(item => item.id === b.id);
        return aIndex - bIndex;
      }),
    };
  }

  public static async getAllByJob(jobId: JobId): Promise<Task[]> {
    const result: TaskDto[] = await Tasks.getAllDtoByJob(jobId);
    const tasks: Task[] = [];
    await Promise.all(
      result.map(async (task: TaskDto) => {
        tasks.push(await Tasks.getActiveRecord(task));
      }),
    );
    return tasks;
  }

  public static async getAllDtoByJob(jobId: JobId): Promise<TaskDto[]> {
    return Api.locHub.getAll(Tasks.path, {
      query: new QueryBuilder().addRelation(EntityRelation.JOB, jobId).build(),
    });
  }

  public static async getDto(taskId: TaskId): Promise<TaskDto> {
    return LocHub.getJson(Tasks.getDetailUrl(taskId));
  }

  public static async get(taskId: TaskId): Promise<Task> {
    const task: TaskDto = await Tasks.getDto(taskId);
    return Tasks.getActiveRecord(task);
  }

  public static getNextStatuses(taskId: TaskId): Promise<TaskStatus[]> {
    return LocHub.getJson(Tasks.getCustomUrl(`${taskId}/nextStatuses`));
  }

  public static async downloadInputs(taskId: TaskId, zipName = 'Inputs'): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.responseType = 'blob';
      request.open('GET', `/api/tapicc/1.0.0-beta/tasks/${taskId}/downloadinputs`);
      request.setRequestHeader('Content-Type', 'application/octet-stream');
      request.setRequestHeader('Authorization', `Bearer ${Api.getTokenDetail().accessToken}`);
      request.onload = function(): void {
        if (this.status !== 200) {
          reject();
          return;
        }
        const blob = this.response;
        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `${zipName}.zip`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        resolve();
      };
      request.onerror = function(): void {
        reject(this.responseText);
      };
      request.send();
    });
  }

  public static async create(task: NewTask): Promise<Task> {
    const result = await LocHub.postJson<NewTask, Task>(Tasks.getBaseUrl(), task);
    queryCache.invalidateQueries(Tasks.path);
    return result;
  }

  public static async update(taskId: TaskId, task: Partial<TaskDto>): Promise<TaskDto> {
    const result = await LocHub.patchJson(Tasks.getDetailUrl(taskId), task);
    queryCache.invalidateQueries(Tasks.path);
    return result;
  }

  public static async upload(taskId: TaskId): Promise<{ errors: Record<string, unknown> }> {
    const deliverables = await Api.locHub.deliverables.getAllByTask(taskId);
    const sourceDeliverables = deliverables.filter(deliverable => deliverable.deliverableAs === InputType.SOURCE);
    const deliverableIds = sourceDeliverables.map(deliverable => deliverable.id);
    if (!deliverableIds.length) {
      throw new Error('Nothing to be delivered.');
    }
    const result = Api.locHub.deliverables.deliverMany(deliverableIds);
    queryCache.invalidateQueries(Tasks.path);
    return result;
  }

  public static async setStatus(taskId: TaskId, status: TapiccStatus): Promise<TaskDto> {
    const result = await Tasks.update(taskId, { status });
    queryCache.invalidateQueries(Tasks.path);
    return result;
  }

  public static async setStatuses(data: { taskId: TaskId; status: TapiccStatus }[]): Promise<BulkResponse<Task>> {
    const createBody = (id: TaskId, status: TapiccStatus): { [key: string]: { status: TapiccStatus } } => ({
      [id]: { status: status },
    });
    const body = data.reduce(
      (accumulator, { taskId, status: phase }) => Object.assign(accumulator, createBody(taskId, phase)),
      {},
    );
    const result = await LocHub.postJson<{ body: unknown }, BulkResponse<Task>>(Tasks.getCustomUrl('bulk/patch'), {
      body,
    });
    queryCache.invalidateQueries(Tasks.path);
    return result;
  }

  public static async delete(taskId: TaskId): Promise<void> {
    await LocHub.delete(Tasks.getDetailUrl(taskId));
  }
}
